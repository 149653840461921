import * as Yup from "yup";
import { validateFileSizeRestriction } from "../utils/ValidationUtils";

const MAX_FILE_SIZE = 2 ** 22; // 4MB

export const transferStatus = [
  ["Aberto", "Aberto"],
  ["Pago", "Pago"],
  ["Cancelado", "Cancelado"],
];

export const transferType = [
  ["Doador - Projeto", "Doador - Projeto"],
  ["Phi - Projeto", "Phi - Projeto"],
];

export const receiptStatus = [
  ["Aberto", "Aberto"],
  ["Enviado", "Enviado"],
  ["Aprovado", "Aprovado"],
];

export const projectsTransferFormPages = {
  DATA: {
    label: "DADOS DO REPASSE",
    order: 1,
  },
};

export const projectTransferForm = {
  DATA: [
    // Transfer info

    {
      title: "Data da solicitação",
      fieldType: "input",
      inputType: "date",
      name: "solicitation_date",
      groups: [1, 2, 3],
      validationSchema: Yup.date(),
      columns: "1/2",
    },
    {
      title: "Status do repasse",
      name: "status",
      fieldType: "select",
      options: transferStatus.map(([l, v]) => ({ label: l, value: v })),
      groups: [1, 2, 3],
      validationSchema: Yup.string().required("Campo obrigatório"),
      columns: "2/3",
    },
    {
      title: "Valor do repasse",
      fieldType: "input",
      name: "value",
      inputType: "number",
      groups: [1, 2, 3],
      validationSchema: Yup.number()
        .required("Campo obrigatório")
        .moreThan(-1, "Apenas números positivos"),
      columns: "3/4",
    },
    {
      title: "Número da parcela",
      fieldType: "input",
      name: "parcel",
      inputType: "number",
      groups: [1, 2, 3],
      validationSchema: Yup.number()
        .required("Campo obrigatório")
        .moreThan(-1, "Apenas números positivos"),
      columns: "1/2",
    },
    {
      title: "Tipo Repasse",
      fieldType: "select",
      name: "type",
      options: transferType.map(([l, v]) => ({ label: l, value: v })),
      groups: [1, 2, 3],
      validationSchema: Yup.string().required("Campo obrigatório"),
      columns: "2/3",
    },
    {
      title: "Data do depósito",
      fieldType: "input",
      inputType: "date",
      name: "transfer_date",
      groups: [1, 2, 3],
      validationSchema: Yup.date().min(
        Yup.ref("solicitation_date"),
        "Data do depósito não pode vir antes da data de solicitação"
      ),
      columns: "3/4",
    },
    // Receipt info
    {
      title: "Status do recibo",
      name: "receipt_status",
      fieldType: "select",
      options: receiptStatus.map(([l, v]) => ({ label: l, value: v })),
      columns: "1/4",
    },
    {
      title: "Arquivo da nota fiscal",
      name: "transfer_receipt_file",
      fieldType: "file",
      validationSchema: Yup.mixed().test(
        "fileSize",
        "Aceito apenas arquivos menores que 4 MB",
        (value) =>
          value == null ||
          value.content.size === undefined ||
          validateFileSizeRestriction(MAX_FILE_SIZE)
      ),
      columns: "1/4",
    },

    {
      title: "Observações",
      fieldType: "textarea",
      name: "observations",
      validationSchema: Yup.string().max(
        1000,
        "Limite de 1000 caracteres atingido"
      ),
      columns: "1/4",
    },
  ],
};
