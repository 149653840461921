import { useCallback, useMemo, useState } from "react";
import { infoLevels } from "../../../constants";
import { Loading } from "../../Loading";
import { post } from "../../../utils/APIutils";
import { Toast, useToast } from "../../Toast";
import { ReactSVG } from "react-svg";
import { Select } from "..";
import InsertNewModal from "./InsertNewModal";

import styles from "./styles.module.scss";

export default function APIOptionsSelect({
  title,
  name,
  id,
  value,
  setValue,
  onLoadOptions,
  placeholder,
  label,
  optionsSource,
  alt,
  editable,
  context,
  apiOptions,
  reloadOptions,
  addNew,
  addNewFieds,
  getSaveUrl,
  error,
}) {
  const selectProps = {
    name,
    id,
    value,
    setValue,
    placeholder,
    label,
    alt,
    editable,
    context,
    error,
  };

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [toast, setToast] = useToast();
  const addNewElement = addNew || false;
  const options = useMemo(() => {
    return apiOptions?.[name];
  }, [apiOptions, name]);

  const handleSaveNewItem = useCallback(
    (formData) => {
      return post(getSaveUrl(context), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        setToast({
          show: true,
          infoLevel: infoLevels.SUCCESS,
          children: "Novo item adicionado com sucesso",
        });
        return response.data.id;
      });
    },
    [context.projectId, setToast]
  );

  const handleNewItem = useCallback(
    (formData) => {
      setLoading(true);
      return handleSaveNewItem(formData)
        .then(() => reloadOptions?.(name))
        .catch((error) => {
          console.log(error);
          setToast({
            show: true,
            infoLevel: infoLevels.ERROR,
            children: JSON.stringify(error.response.status),
          });
        })
        .finally(() => setLoading(false));
    },
    [handleSaveNewItem, setToast]
  );

  return !apiOptions?.[name] ? (
    <Loading />
  ) : (
    <>
      {addNewElement ? (
        <div>
          <table>
            <tr>
              <td className={styles.rowControl}>
                Clique no + para criar uma opção nova
              </td>
              <th>
                <button
                  className={styles.addButton}
                  type="button"
                  onClick={() => {
                    editable ? setShowModal(true) : setShowModal(false);
                  }}
                >
                  <ReactSVG
                    className={styles.deleteIcon}
                    src="/icons/add_circle_24px.svg"
                  />
                </button>
              </th>
            </tr>
            <tr>
              <td colspan="2">
                <Select options={options} {...selectProps} />
              </td>
            </tr>
          </table>
          <InsertNewModal
            title={title}
            context={context}
            show={showModal}
            editable={editable}
            fields={addNewFieds}
            setShow={(value) => setShowModal(value)}
            onSave={(values) => handleNewItem(values)}
          />
          <Toast {...toast} />

          <Toast loading={loading} show={loading} />
        </div>
      ) : (
        <Select options={options} {...selectProps} />
      )}
    </>
  );
}
