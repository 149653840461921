import { useEffect, useState } from "react"
import { get } from "../../../utils/APIutils";
import { Loading } from "../../Loading";

export default function ProjectName({projectId, append}){
  const [projectName, setProjectName] = useState(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if(String(Number(projectId)) !== 'NaN' && projectName === null){
      setLoading(true);
      get(`projects/${projectId}`)
      .then((response) => setProjectName(response.data.name))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
    }
  }, [projectId]);

  const nameString = projectName !== null ? projectName : String(Number(projectId)) !== 'NaN' ? "" : "Novo projeto";

  return (
    <>
      <h2>
        {`${nameString}${append ?? ""}`}
      </h2>
      {loading &&
        <Loading/>
      }
    </>
  )
}