import { useState, useEffect } from "react";
import { Input } from "..";
import { getFieldStandardValue } from "../../FormPage/Question";
import styles from "./styles.module.scss";

export default function Switch({
  name,
  id,
  label,
  value,
  setValue,
  alt,
  editable,
  standardActiveValue,
  standardInactiveValue,
  context,
}) {
  const activeValue =
    standardActiveValue !== undefined ? standardActiveValue : true;
  const inactiveValue =
    standardInactiveValue !== undefined ? standardInactiveValue : false;

  const [switchValue, setSwitchValue] = useState();

  const checked = switchValue === activeValue;
  const altActive = (checked === alt.value);


  useEffect(()=> {
    if (!!value) setSwitchValue(activeValue)
  }, [activeValue, value])

  return (
    <div
      className={`inputContainer ${styles.inputContainer}`}
    >
      {label && (
        <div className={styles.label}>
          <label>{label}</label>
        </div>
      )}
      <div className={styles.inputLabelWrapper}>
        <input
          id={id}
          tabIndex={editable ? undefined : -1}
          type="checkbox"
          value={switchValue}
          checked={checked}
          onChange={(evt)=>setSwitchValue(evt.target.value)}
          readOnly={editable === false ? true : undefined}
        />
        <label htmlFor={id}>
          <button
            tabIndex={editable ? undefined : -1}
            type="button"
            className={styles.switchWrapper}
            onClick={(evt) => {
              if (editable !== false) {
                const newSwitchValue = checked ? inactiveValue : activeValue;
                setSwitchValue(newSwitchValue);
                setValue(getFieldStandardValue(alt.field.fieldType))
              }
            }}
          >
            <span>Não</span>
            <div
              className={`${styles.switchTrack} ${
                checked ? styles.active : ""
              }`}
            >
              <div
                className={`${styles.switchThumb} ${
                  checked ? styles.active : ""
                }`}
              />
            </div>
            <span>Sim</span>
          </button>
        </label>
      </div>

      {altActive && (
        <Input
          {...alt.field}
          value={value}
          setValue={setValue}
          editable={editable}
        />
      )}
    </div>
  );
}