import { useState, useEffect, useMemo } from "react";
import { Redirect } from "react-router";
import { ReactSVG } from "react-svg";
import { infoLevels } from "../../../../constants";
import { formStatus, isAnyStatus } from "../../../../constants/options";
import {
  projectReportForm,
  projectsReportFormPages,
} from "../../../../constants/projectReportForm";
import { get, patch, post, put, getFile } from "../../../../utils/APIutils";
import { isUserPhi } from "../../../../utils/permissionUtils";
import { filterFormFieldsByUserGroup } from "../../../../utils/useForm";
import { FormPage } from "../../../FormPage";
import { Toast, useToast } from "../../../Toast";

import styles from "./styles.module.scss";

export default function ProjectReportsForm({ projectId, reportId, userData }) {
  const [filteredForm, setFilteredForm] = useState(() =>
    filterFormFieldsByUserGroup(userData, projectReportForm)
  );
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useToast();
  const [pptxToast, setPptxToast] = useToast();
  const [reload, setReload] = useState(false);
  const [redirect, setRedirect] = useState("");

  const loadReportData = () => {
    setLoading(true);
    get(`projects/${projectId}/reports/${reportId}`)
      .then((response) => response.data)
      .then((reportData) => setReportData(reportData))
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response.status),
        });
      })
      .finally(() => setLoading(false));
  };

  const submitForAnalysis = (reportId) => {
    return patch(`projects/${projectId}/reports/${reportId}`);
  };

  const handleSaveUpdate = (formData) => {
    return put(`projects/${projectId}/reports/${reportId}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      setToast({
        show: true,
        infoLevel: infoLevels.SUCCESS,
        children: "Alteração feita com sucesso",
      });
      return response.data.id;
    });
  };

  const handleSave = (formData) => {
    setLoading(true);
    return handleSaveUpdate(formData)
      .then((responseReportId) => {
        if (reportId === undefined) {
          return setRedirect(
            `/projects/${projectId}/reports/${responseReportId}`
          );
        }
        return loadReportData();
      })
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response.status),
        });
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (formData) => {
    setLoading(true);
    return handleSaveUpdate(formData)
      .then((responseReportId) => submitForAnalysis(responseReportId))
      .then((response) => {
        if (reportId === undefined) {
          return setRedirect(
            `/projects/${projectId}/reports/${response.data.id}`
          );
        }
        return loadReportData();
      })
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response.status),
        });
      })
      .finally(() => setLoading(false));
  };

  const loadPresentationFile = (reportId, reportData) => {
    setLoading(true);
    return getFile(`/generate-report/${reportId}`)
      .then((response) => {
        //date_range = f"{st_date.day:02d}-{st_date.month:02d}-{st_date.year:04d}_{end_date.day:02d}-{end_date.month:02d}-{end_date.year:04d}
        const filename = `Relatório_${reportData.project}_${reportData.begin_date}_${reportData.end_date}.pptx`;
        const file = new File([response.data], filename, {
          type: "text/octet-stream",
        });
        const url = URL.createObjectURL(file);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = filename;
        anchor.click();
      })
      .catch((error) => {
        setPptxToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: `${JSON.stringify(
            error.response.status
          )} - Ocorreu um erro inesperado ao produzir o arquivo`,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (reportId && projectId && (reportData === null || reload)) {
      loadReportData();
      setReload(false);
    }
  }, [projectId, reportId, reload]);

  const editable = useMemo(() => {
    const isNew = !reportId;
    const isLoaded = reportData !== null;

    return Boolean(
      projectId &&
        (isNew ||
          (isLoaded &&
            (isUserPhi(userData) ||
              isAnyStatus(
                reportData?.form_status,
                formStatus["Desatualizado"],
                formStatus["Editável"],
                formStatus["Incompleto"]
              ))))
    );
  }, [projectId, reportData, reportId, userData]);

  const showDownloadButton = useMemo(() => {
    return Boolean(
      projectId &&
        reportId &&
        reportData !== null &&
        isUserPhi(userData) &&
        !isAnyStatus(
          reportData?.form_status,
          formStatus["Desatualizado"],
          formStatus["Editável"],
          formStatus["Incompleto"]
        ) &&
        editable
    );
  }, [editable, projectId, reportData, reportId, userData]);

  useEffect(() => {
    setFilteredForm(filterFormFieldsByUserGroup(userData, projectReportForm));
  }, [userData]);

  return (
    <div className={styles.formPageWrapper}>
      {showDownloadButton && (
        <button
          className={styles.downloadReportButton}
          onClick={() => loadPresentationFile(reportId, reportData)}
        >
          <span>Download como pptx</span>
          <ReactSVG src="/icons/download_24px.svg" />
        </button>
      )}

      {(!reportId || (reportId && reportData)) !== null && (
        <FormPage
          name="projectReportForm"
          form={filteredForm}
          initialState={reportData}
          pages={projectsReportFormPages}
          editable={editable !== false}
          reload={reload}
          setReload={setReload}
          onSubmit={handleSubmit}
          onSave={handleSave}
          context={{ projectId }}
          userData={userData}
        />
      )}

      {redirect && <Redirect to={redirect} />}

      <Toast {...toast} />

      <Toast {...pptxToast} />

      <Toast loading={loading} show={loading} />
    </div>
  );
}
