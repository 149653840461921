const UserGroups = {
  superAdmin: 1,
  phiManager: 2,
  phiAnalyst: 3,
  orgManager: 4,
  orgMember: 5,
  noOrg: 6,
  phiCoordinator: 7,
};

export default UserGroups;
