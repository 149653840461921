import * as Yup from "yup";

export const remanagementStatus = [
  ["Aberto", "Aberto"],
  ["Negado", "Negado"],
  ["Cancelado", "Cancelado"],
  ["Aprovado", "Aprovado"],
];

export const projectsRemanagementFormPages = {
  DATA: {
    label: "DADOS DO REMANEJAMENTO",
    order: 1,
  },
};

export const projectRemanagementForm = {
  DATA: [
    
    {
      title: "Data da solicitação",
      fieldType: "input",
      inputType: "date",
      name: "solicitation_date",
      validationSchema: Yup.date(),
      columns: "1/4"
    },
    {
      title: "Status do remanejamento",
      name: "remanagement_status",
      fieldType: "select",
      options: remanagementStatus.map(([l, v]) => ({ label: l, value: v })),
      groups: [1, 2, 3],
      columns: "1/4"
    },
    {
      title: "Item original",
      fieldType: "apiselect",
      name: "previous_investment_item",
      optionsSource: (context) =>
        `options/projectinvestments/${context?.projectId}`,
      columns: "1/4"
    },
    {
      title: "Novo item",
      fieldType: "apiselect",
      name: "new_investment_item",
      addNew: true,
      getSaveUrl: (context) => `projects/${context.projectId}/addNewItem`,
      optionsSource: (context) =>
        `options/projectinvestments/${context?.projectId}`,
      validationSchema: Yup.string().notOneOf(
        [Yup.ref("previous_investment_item")],
        "Novo item não pode ser igual ao original"
      ),
      addNewFieds: [
        {
          title: "Tipo de investimento",
          name: "investment_type",
          fieldType: "select",
          options: [
            [
              "Lei de Incentivo - Captação Phi",
              "Lei de Incentivo - Captação Phi",
            ],
            ["Materiais e Equipamentos", "Materiais e Equipamentos"],
            ["Transporte e Alimentação", "Transporte e Alimentação"],
            ["Recursos Humanos", "Recursos Humanos"],
            ["Outros", "Outros"],
          ].map(([k, v]) => ({ label: k, value: v })),
        },
        {
          title: "Descrição do item",
          name: "item_description",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().max(100, "Limite de 100 caracteres atingido"),
        },
        {
          title: "Preço unitário",
          name: "unit_price",
          fieldType: "input",
          inputType: "number",
          validationSchema: Yup.number()
            .moreThan(-1, "Apenas números positivos")
            .nullable(),
        },
        {
          title: "Unidades",
          name: "amount",
          fieldType: "input",
          inputType: "number",
          validationSchema: Yup.number()
            .moreThan(-1, "Apenas números positivos")
            .nullable(),
        },
      ],
      columns: "1/4"
    },
    {
      title: "Valor remanejado",
      fieldType: "input",
      inputType: "number",
      name: "remanaged_budget",
      validationSchema: Yup.number().moreThan(-1, "Apenas números positivos"),
      columns: "1/4"
    },
    {
      title: "Justificativa para o remanejamento",
      fieldType: "textarea",
      name: "justification",
      validationSchema: Yup.string()
        .max(1000, "Limite de 1000 caracteres atingido")
        .required("Campo Obrigatório"),
      columns: "1/4"
    },
    {
      title: "Observações PHI",
      fieldType: "textarea",
      name: "phi_observations",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "1/4"
    },
  ],
};
