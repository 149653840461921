import { useEffect, useState } from "react"
import { get } from "../../../utils/APIutils";
import { Loading } from "../../Loading";

export default function OrgName({orgId}){
  const [orgName, setOrgName] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (String(Number(orgId)) === 'NaN'){
      setOrgName("Nova organização");
      return;
    }
    if(orgId && orgName === null){
      setLoading(true);
      get(`orgs/${orgId}`)
      .then((response) => response.data)
      .then((orgData) => setOrgName(orgData.org_name))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
    }
  }, [orgId])

  return (
    <>
      <h2>
        {orgName}
      </h2>
      {loading &&
        <Loading/>
      }
    </>
  )
}