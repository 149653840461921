import { useState } from "react";
import { ReactSVG } from "react-svg";
import { Popover, ArrowContainer } from "react-tiny-popover";

import styles from "./styles.module.scss";

export default function UseTooltip({ text }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["top", "bottom", "left", "right"]} // preferred positions by priority
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"#0000003A"}
          arrowSize={10}
          className={styles.ArrowContainer}
          arrowClassName="popover-arrow"
        >
          <div className={styles.TooltipPopover}>{text}</div>
        </ArrowContainer>
      )}
      onClickOutside={() => setIsPopoverOpen(false)}
    >
      <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        <ReactSVG src="/icons/help_outline_24px.svg" />
      </div>
    </Popover>
  );
}
