import { Button } from "../../Forms";
import { ModalLayout } from "../../Modal";
import { put } from "../../../utils/APIutils"
import { useState } from "react";

import styles from './styles.module.scss';
import { Question } from "../../FormPage/Question";
import { Form, Formik } from "formik";
import { Toast } from "../../Toast";

export default function EditPermissionModal({title, show, setShow, email, group, reloadData, options}){
  //const [newGroup, setNewGroup] = useState(group);
  const [loading, setLoading] = useState(false);
  
  const handleEdit = (values) => {
    setLoading(true);
    return put("permissions/user_group/change", {
      email,
      group : values.group
    })
    .then(() => reloadData())
    .finally(() =>{
      setShow(false);
      setLoading(false);
    })
    /*TODO. Este tratamento ta ruinzão, editar
    then(async (response) => {
      if(response.status !== 201){
        const errorMessage = response.data;
        setError(Object.entries(errorMessage).map(([key, value]) => `${key}: ${value}`).join("\n"));
      }
    })
    .catch((err) => {
      setError(err);
    })*/
  }

  return(
    <>
      <Formik
        initialValues={{group}}
        onSubmit={(values) => {
          handleEdit(values);
          setShow(false);
        }}
        enableReinitialize
      >
        {(formikProps) => 
          <ModalLayout
            title={title}
            show={show}
            setShow={(newShow) => {
              setShow(newShow);
              formikProps.resetForm();
            }}
          >
            <Form>
              <div className={styles.questions}>
                {options && 
                  <Question
                    name="group"
                    id="group"
                    value={formikProps.values.group}
                    setValue={(value) => formikProps.setFieldValue("group", value)}
                    placeholder={"Selecione aqui"}
                    fieldType="select"
                    options={options}
                    text="Escolha a permissão"
                    editable={!!options}
                  />
                }
              </div>
              <div className={styles.footer}>
                <Button
                  styleString="filled"
                  type="submit"
                >
                  Aplicar
                </Button>
              </div>
            </Form>
          </ModalLayout>
        }
      </Formik>
      <Toast show={loading} loading={loading}/>
    </>
  )
}