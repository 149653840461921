import { useEffect, useState } from 'react';
import { clearToken, get, post } from '../../../../utils/APIutils';
import styles from './styles.module.scss';

const LOGOUT_ANCHOR_ID = "logoutAnchor";

export default function UserHandle({username}){
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    get("notifications")
    .then((response) => response.data)
    .then((notifications) => setNotifications(notifications))
    .catch((error) => console.log(error));
  }, [])

  const handleLogout = () => {
    post("auth/logout")
    .then((response) => {
      clearToken();
      const anchor = document.createElement("a", {href: "/"});
      anchor.setAttribute("href", "/");
      anchor.click();
    })
    .catch((error) => {
      console.log(error);
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.notifications}>
        <span>
          {notifications !== null && notifications.length}
        </span>
      </div>
      <div className={styles.profilePic}>
        {/* <img src="" alt="placeholder"/> */}
      </div>
      <span className={styles.username}>
        {username}
      </span>
      <div className={styles.logoutMenu}>
        <ol>
          <li>
            <a>
              <button onClick={handleLogout}>
                Logout
              </button>
            </a>
          </li>
        </ol>
      </div>
    </div>
  )
}