import { UserHandle } from './index';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import styles from './styles.module.scss';
import { Loading } from '../../Loading';

export default function Topbar({userData, loadingUserData}){
  const location = useLocation();
  const atLogin = location.pathname === "/login";
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <p> filantropia inteligente </p>
        {loadingUserData ? (
          <Loading/>
        ) : (
          userData ? (
            <UserHandle username={userData?.username}/>
          ) : (
            <Link to="/login" className={atLogin ? styles.active : ""}>
              <ReactSVG src="/icons/account_circle_24px.svg"/>
              <span> Login </span>
            </Link>
          )
        )}
      </div>
    </div>
  )
}