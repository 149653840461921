import Error from '../Input/Error';

import styles from "./styles.module.scss";

export default function Radio({
  name,
  value,
  setValue,
  label,
  options,
  editable,
  context,
  error,
  isBoolean,
}) {
  if (isBoolean) {
    options = [
      {
        label: "Sim",
        value: true,
      },
      {
        label: "Não",
        value: false,
      },
    ];
  }
  return (
    <div className={`inputContainer ${styles.inputContainer}`}>
      {label && (
        <div className={styles.label}>
          <label>{label}</label>
        </div>
      )}
      <ul>
        {options.map((option) => (
          <li key={option.value}>
            <input
              id={`${name} - ${option.value}`}
              tabIndex={editable ? undefined : -1}
              name={name}
              type="radio"
              value={option.value}
              checked={
                String(value) === String(option.value) ? true : undefined
              }
              onChange={!!editable ? (evt) => setValue(option.value) : () => {}}
              readOnly={editable === false ? true : undefined}
            />
            <label htmlFor={`${name} - ${option.value}`}>{option.label}</label>
          </li>
        ))}
      </ul>
      {error && <Error error={error} />}
    </div>
  );
}
