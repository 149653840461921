import { useState, useEffect } from "react";
import { get } from "../../utils/APIutils";
import { Redirect } from "react-router-dom";
import { EditPermissionModal } from "./EditPermissionModal/";

import styles from "./styles.module.scss";
import PaginationControls from "../Pagination/PaginationControls/Pagination";
import { getInitialFormValues } from "../../utils/useForm";
import { FilterForm } from "../Filtering/FilterForm";

const filtersForm = [
  {
    name: "first_name__icontains",
    title: "Nome",
    fieldType: "input",
    inputType: "search",
  },
  {
    name: "email__icontains",
    title: "E-mail",
    fieldType: "input",
    inputType: "search",
  },
  {
    name: "spg_user__related_org__org_name__icontains",
    title: "Organização",
    fieldType: "input",
    inputType: "search",
  },
];

const initialFilters = getInitialFormValues({ DATA: filtersForm });

export default function PermissionsPage() {
  //TODO fazer o texto do modal com interpolação de string para dizer "o usuário X tem a permissão Y, você deseja alterar?"
  const [users, setUsers] = useState(null);
  const [possibleGroups, setPossibleGroups] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [userGroup, setUserGroup] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);

  const loadUsers = () => {
    const notBlankFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => !!value)
    );
    get("permissions/users", { params: { page, ...notBlankFilters } })
      .then((response) => {
        setUsers(response.data?.items);
        setPageCount(response.data?.pageCount);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadUsers();
  }, [page, filters]);

  useEffect(() => {
    if (users === null) {
      get("permissions/possible_groups", {})
        .then((response) => response.data)
        .then((responseObject) => {
          setPossibleGroups(responseObject);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const [redirect, setRedirect] = useState(false);

  return redirect ? (
    <Redirect to="/login" />
  ) : (
    <>
      <div className={styles.wrapper}>
        <div className={styles.listControls}>
          <PaginationControls
            currentPage={page}
            totalPages={pageCount}
            setPage={setPage}
          />

          <FilterForm
            form={filtersForm}
            filters={filters}
            setFilters={(values) => setFilters(values)}
          />
        </div>
        <div className={styles.tableWrapper}>
          <table>
            <thead>
              <tr>
                <th> Nome </th>
                <th> E-mail </th>
                <th> Organização </th>
                <th> Grupo </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {users?.map((user, index) => {
                const { name, email, org, group } = user;
                return (
                  <tr key={email}>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>{org}</td>
                    <td>{group}</td>
                    <td>
                      <div className={styles.actions}>
                        <button
                          className={styles}
                          onClick={() => {
                            setUserEmail(email);
                            setUserGroup(group);
                            setShowModal(true);
                          }}
                        >
                          Editar
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <EditPermissionModal
          title={"Editando permissões"}
          show={showModal}
          email={userEmail}
          group={userGroup}
          reloadData={loadUsers}
          setShow={(value) => {
            setShowModal(value);
          }}
          options={possibleGroups}
        />
      </div>
    </>
  );
}
