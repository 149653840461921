import styles from "./styles.module.scss";
import Error from "./Error";

export default function Input({
  name,
  inputType,
  id,
  value,
  setValue,
  onBlur,
  error,
  placeholder,
  label,
  editable,
  context,
}) {
  return (
    <>
    {label && (
      <div className={styles.label}>
        <label>{label}</label>
      </div>
    )}
    <div
      className={`inputContainer ${styles.inputContainer}`}
      style={{ display: inputType === "hidden" ? "none" : "flex" }}
    >
      <input
        tabIndex={editable ? undefined : -1}
        type={inputType}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(evt) => setValue(evt.target.value)}
        onBlur={onBlur}
        readOnly={editable === false ? true : undefined}
      />
      {error && <Error error={error} />}
    </div>
    </>
  );
}
