import { useMemo, useState } from "react";
import {
  fileToValue,
  useFileContentUrl,
  hasImageExtension,
  hasPdfExtension,
} from "../../../utils/fileUtils";
import Error from "../Input/Error";
import styles from "./styles.module.scss";
import { ReactSVG } from "react-svg";

export default function FileInput({
  name,
  multiple,
  value,
  setValue,
  onBlur,
  error,
  placeholder,
  label,
  editable,
}) {
  const handleChange = (evt) => {
    const newValueItem = fileToValue(evt.target.files[0]);
    if (multiple) {
      setValue([...value, newValueItem]);
    } else {
      setValue(newValueItem);
    }
  };

  const idString = `${name}-internal`;

  return (
    <div className={`inputContainer ${styles.inputContainer}`}>
      {label && (
        <div className={styles.label}>
          <label>{label}</label>
        </div>
      )}

      <input
        type="file"
        className={styles.innerFileInput}
        id={idString}
        name={name}
        tabIndex={-1}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        readOnly={editable === false ? true : undefined}
      />

      {multiple ? (
        <FileTable
          inputId={idString}
          files={value}
          editable={editable}
          removeFile={(fileIndex) => {
            document.getElementById(idString).value = "";
            setValue(value.filter((item, index) => index !== fileIndex));
          }}
        />
      ) : (
        <FileBlock
          inputId={idString}
          file={value}
          editable={editable}
          removeFile={() => {
            document.getElementById(idString).value = "";
            setValue("");
          }}
        />
      )}

      {error && <Error error={error} />}
    </div>
  );
}

function FileTableRow({ file, removeFile, editable, index }) {
  const fileSource = useFileContentUrl(file.content);
  return (
    <tr>
      <td>
        <img
          className={styles.imagePreview}
          id={`img-${file.file_name}`}
          src={fileSource}
          alt={file.file_name}
        />
      </td>
      <td>
        <span> {file.file_name} </span>
      </td>
      <td>
        {editable && (
          <button
            className={styles.removeButton}
            type="button"
            onClick={() => removeFile(index)}
          >
            Remover
          </button>
        )}
        {fileSource && (
          <FileActions fileName={file.file_name} fileUrl={fileSource} />
        )}
      </td>
    </tr>
  );
}

function FileTable({ inputId, files, removeFile, editable }) {
  return (
    <table className={styles.fileTable}>
      <thead>
        <tr>
          <th></th>
          <th>Arquivo</th>
          <th>{editable && <SelectFileButton inputId={inputId} />}</th>
        </tr>
      </thead>
      <tbody>
        {files?.map?.((file, index) => {
          return (
            <FileTableRow
              editable={editable}
              file={file}
              index={index}
              removeFile={removeFile}
            />
          );
        })}
      </tbody>
    </table>
  );
}

function FileBlock({ inputId, file, removeFile, editable }) {
  const fileSource = useFileContentUrl(file?.content);
  return (
    <>
      <table className={styles.fileTable}>
        <thead>
          <tr>
            <th colSpan={2}>Arquivo</th>
            <th>{editable && <SelectFileButton inputId={inputId} />}</th>
          </tr>
        </thead>
        <tbody>
          {file ? (
            <tr>
              <td>
                <img
                  className={styles.imagePreview}
                  id={`img-${file.file_name}`}
                  src={fileSource}
                  alt={file.file_name}
                />
              </td>
              <td>
                <span> {file.file_name} </span>
              </td>
              <td>
                {editable && (
                  <button
                    className={styles.removeButton}
                    type="button"
                    onClick={() => removeFile()}
                  >
                    Remover
                  </button>
                )}
                {fileSource && (
                  <FileActions fileName={file.file_name} fileUrl={fileSource} />
                )}
              </td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </>
  );
}

function SelectFileButton({ inputId }) {
  return (
    <label tabIndex={-1} htmlFor={inputId}>
      <button
        tabIndex={0}
        className={styles.selectFileButton}
        type="button"
        onClick={() => document.getElementById(inputId).click()}
      >
        Selecionar
      </button>
    </label>
  );
}

export function FileActions({ fileName, fileUrl, short }) {
  const isImage = useMemo(() => {
    return hasImageExtension(fileName);
  }, [fileName]);
  const isPdf = useMemo(() => {
    return hasPdfExtension(fileName);
  }, [fileName]);

  const hasOpenBehavior = isImage || isPdf;

  return (
    <div className={styles.fileActions}>
      {hasOpenBehavior && (
        <a href={fileUrl} target="#" className={styles.fileAnchor}>
          <button
            type="button"
            tabIndex={0}
            className={styles.downloadFileButton}
          >
            {short ? (
              <ReactSVG src={`/icons/open_in_new_24px.svg`} />
            ) : (
              <span>{"Abrir"}</span>
            )}
          </button>
        </a>
      )}
      <a href={fileUrl} download target="#" className={styles.fileAnchor}>
        <button
          type="button"
          tabIndex={0}
          className={styles.downloadFileButton}
        >
          {short ? (
            <ReactSVG src={`/icons/download_24px.svg`} />
          ) : (
            <span>{"Baixar"}</span>
          )}
        </button>
      </a>
    </div>
  );
}
