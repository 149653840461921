import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFormStatusLabel } from '../../../../constants/options';
import { get } from '../../../../utils/APIutils';
import { getInitialFormValues } from '../../../../utils/useForm';
import { FilterForm } from '../../../Filtering/FilterForm';
import { Button } from '../../../Forms';
import { PaginationControls } from '../../../Pagination';
import { Toast } from '../../../Toast';
import { transferStatus, receiptStatus } from '../../../../constants/projectTransferForm'

import styles from './styles.module.scss';

const filtersForm = [
  {
    name: "solicitation_date__gte",
    title: "Data de Solicitação",
    fieldType: "input",
    inputType: "date"
  },
  {
    name: "transfer_date__gte",
    title: "Data de Depósito",
    fieldType: "input",
    inputType: "date"
  },
  {
    name: "receipt_sent_date__gte",
    title: "Data de Envio do Recibo",
    fieldType: "input",
    inputType: "date"
  },
  {
    name: "status__icontains",
    title: "Status do Repasse",
    fieldType: "select",
    options: transferStatus.map(([l, v]) => ({label: l, value : v}))
  },
  {
    name: "receipt_status__icontains",
    title: "Status do Recibo",
    fieldType: "select",
    options: receiptStatus.map(([l, v]) => ({label: l, value : v}))
  }
];

const initialFilters = getInitialFormValues({DATA: filtersForm});

function formatDate(dateString){
  if (dateString == ''){
    return '---'
  }
  const [year, month, day] = dateString.split("-").map(item => Number(item));
  return `${day}/${month}/${year}`;
}

export default function ProjectTransfersList({projectId, userData}){
  const [transfersList, setTransfersList] = useState(null);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);

  const loadProjectTransfers = () => {
    const notBlankFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => !!value)
    );
    setLoading(true);
    get(`projects/${projectId}/transfers`, {params: {page, ...notBlankFilters}})
    .then((response) => response.data)
    .then((data) => {
      setTransfersList(data.items);
      setPageCount(data.pageCount);
    })
    .catch((error) => console.log(error))
    .finally(() => setLoading(false));
  };

  useEffect(() => {
    if(projectId && (transfersList === null || reload)){
      loadProjectTransfers();
      setReload(false);
    }
  }, [projectId, reload])

  useEffect(() => {
    if(projectId){
      loadProjectTransfers();
    }
  }, [page, filters])
  
  return (
    <>
      <div className={styles.newButtonWrapper}>
        <Link tabIndex={-1} to={`/projects/${projectId}/transfers/new`}>
          <Button styleString="filled">
            Novo
          </Button>
        </Link>
      </div>
      
      <div className={styles.listControls}>
        <PaginationControls
          currentPage={page}
          totalPages={pageCount}
          setPage={setPage}
        />
      
        <FilterForm
          form={filtersForm}
          filters={filters}
          setFilters={(values) => setFilters(values)}
        />
      </div>

      <div className={styles.wrapper}>
        <Toast show={loading} loading={loading}/>

        <section className={styles.transfersListSection}>
          <table>
            <thead>
              <tr>
                <th> Data de Solicitação </th>
                <th> Data de Depósito </th>
                <th> Data de Envio do Recibo </th>
                <th> Status do Repasse </th>
                <th> Status do Recibo </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {transfersList?.length > 0 && transfersList.map((transfer) =>
                <tr>
                  <td>{formatDate(transfer.solicitation_date)}</td>
                  <td>{formatDate(transfer.transfer_date)}</td>
                  <td>{formatDate(transfer.receipt_sent_date)}</td>
                  <td>{transfer.status}</td>
                  <td>{transfer.receipt_status}</td>
                  <td>
                    <div className={styles.transferOptions}>
                      <Link tabIndex={-1} to={`/projects/${projectId}/transfers/${transfer.id}`}>
                        {[1, 2, 3].includes(userData.groups[0])
                        ?<button> Visualizar </button>
                        :<button> Adicionar Recibo </button>}
                      </Link>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>
      </div>
    </>
  )
}