import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { routes } from "../../../constants";

import styles from './styles.module.scss';

export default function Sidebar({userGroup}){
  const [hovering, setHovering] = useState(false);
  const [hoveringOption, setHoveringOption] = useState(null);
  const location = useLocation();

  const filteredRoutes = userGroup === null ? (
    []
  ) : userGroup.length > 0 ? (
    routes.filter((route) => route.permissions.includes(userGroup?.[0]))
  ) : (
    routes.filter((route) => route.permissions.includes(6))
  );
  
  return (
    <div
      className={styles.wrapper}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onFocus={() => setHovering(true)}
      onBlur={() => setHovering(false)}
    >
      <div className={styles.navbarIcons}>
        <ul>
          <li>
            <img
              src="/Logo-low.png"
              alt="Instituto Phi - filantropia inteligente"
            />
          </li>
          {filteredRoutes.map((route) =>
            <li
              className={hoveringOption === route.label ? styles.active : ""}
              onMouseEnter={() => setHoveringOption(route.label)}
              onMouseLeave={() => setHoveringOption(null)}
              onFocus={() => setHoveringOption(route.label)}
              onBlur={() => setHoveringOption(null)}
              key={route.label}
            >
              <div 
                className={`${styles.selectedMarker} ${
                  ((route.path === "/" && location.pathname === route.path) ||
                  (route.path !== "/" && location.pathname.includes(route.path)))
                  ? styles.active
                  : ""
                }`}
              />
              <Link to={route.path}>
                <ReactSVG src={`/icons/${route.logo}`}/>
              </Link>
            </li>
          )}
        </ul>
      </div>

      {filteredRoutes.length > 0 &&
        <nav className={`${styles.navbar} ${hovering ? styles.active : ""}`}>
          <ul>
            <li></li>
            {filteredRoutes.map((route) =>
              <li
                className={hoveringOption === route.label ? styles.active : ""}
                onMouseEnter={() => setHoveringOption(route.label)}
                onMouseLeave={() => setHoveringOption(null)}
                key={route.label}
              >
                <Link tabIndex={-1} to={route.path}>
                  <span>{route.label}</span>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      }
    </div>
  )
}