import { Form, Formik } from "formik";
import { useEffect, useState, useMemo } from "react";
import { Button } from "../..";
import {
  getInitialFormValues,
  getValidationValue,
} from "../../../../utils/useForm";
import { Question } from "../../../FormPage/Question";
import { ModalLayout } from "../../../Modal";
import * as Yup from "yup";

import styles from "./styles.module.scss";

export default function TableInsertModal({
  title,
  show,
  setShow,
  tableFields,
  tableName,
  data,
  formValues,
  writeRow,
  editingRowKey,
  onLoadOptions,
  context,
  apiOptions,
  reloadOptions,
}) {
  const [standardInitialValues, setStandardInitialValues] = useState(() =>
    data?.[editingRowKey]
      ? data?.[editingRowKey]
      : getInitialFormValues({ FIELDS: tableFields })
  );

  const validationSchema = useMemo(
    () => Yup.object().shape(getValidationValue({ FIELDS: tableFields })),
    [tableFields]
  );

  const tableRowCount = formValues[tableName]?.length && 0;

  const indexAwareContext = useMemo(
    () => ({
      ...context,
      index: editingRowKey ?? tableRowCount,
    }),
    [context, editingRowKey, tableRowCount]
  );

  useEffect(() => {
    setStandardInitialValues(
      data?.[editingRowKey]
        ? data?.[editingRowKey]
        : getInitialFormValues({ FIELDS: tableFields })
    );
  }, [data, editingRowKey, tableFields]);

  return (
    <ModalLayout title={title} show={show} setShow={setShow}>
      <Formik
        enableReinitialize
        initialValues={standardInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formikProps) => {
          writeRow(values);
          setShow(false);
          formikProps.resetForm();
        }}
      >
        {({ values, setFieldValue, ...props }) => {
          const formValuesWithUpdatedItem = {
            ...formValues,
            [tableName]:
              editingRowKey !== undefined
                ? formValues[tableName]?.map((originalRow, rowKey) =>
                    rowKey === editingRowKey ? values : originalRow
                  )
                : [...formValues[tableName], values],
          };
          return (
            <>
              <div className={styles.questions}>
                <Form id={`modalTableForm_${tableName}`}>
                  {tableFields?.map((question) => (
                    <Question
                      key={question.name}
                      value={values[question.name]}
                      editable={question.editable ?? true}
                      context={indexAwareContext}
                      onLoadOptions={(options) =>
                        onLoadOptions(question.name, options)
                      }
                      apiOptions={apiOptions}
                      reloadOptions={reloadOptions}
                      error={props.errors[question.name]}
                      formValues={formValuesWithUpdatedItem}
                      {...question}
                    />
                  ))}
                </Form>
              </div>
              <div className={styles.footer}>
                <Button
                  styleString="filled"
                  type="button"
                  form="modalTableForm"
                  disabled={
                    Object.entries(props.errors).length !== 0 ? true : false
                  }
                  onClick={() => props.submitForm()}
                >
                  Aplicar
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
    </ModalLayout>
  );
}
