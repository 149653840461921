import { Route, Switch } from 'react-router';
import { isUserInGroups } from '../../../utils/permissionUtils';
import { ConditionsEnforcer } from '../../Auth/ConditionsEnforcer';
import { ProjectTransfersForm } from './ProjectTransfersForm';
import { ProjectTransfersList } from './ProjectTransfersList';

export default function ProjectTransfers({userData}){
  return (
    <Switch>
      <Route path="/projects/:projectId/transfers/new"
        render={(props) =>
          <ProjectTransfersForm
            projectId={props.match.params.projectId}
            transferId={undefined}
            userData={userData}
          />
        }
      />
        
      <Route path="/projects/:projectId/transfers/:transferId"
        render={(props) =>
          <ProjectTransfersForm
            projectId={props.match.params.projectId}
            transferId={props.match.params.transferId}
            userData={userData}
          />
        }
      />

      <Route path="/projects/:projectId/transfers"
        render={(props) =>
          <ProjectTransfersList
            projectId={props.match.params.projectId}
            userData={userData}
          />
        }
      />
    </Switch>
  )
}