export const emailShouldPassEmailRegex = (email) => {
  const emailDotComRegex = /^([a-z0-9_.-]+)@([\da-z-]+)(\.[a-zA-Z]+)+$/i;
  return emailDotComRegex.test(email);
};

export const validateFirstName = (firstName) => {
  let validation;
  if (firstName === undefined) {
    validation = {
      valid: false,
      error: "Você não preencheu o primeiro nome",
    };
    return validation;
  } else if (isNaN(firstName)) {
    validation = {
      valid: true,
    };
    return validation;
  }
  validation = {
    valid: false,
    error: "Este campo não pode ser numérico",
  };
  return validation;
};

export const validateLastName = (lastName) => {
  let validation;
  if (lastName === undefined) {
    validation = {
      valid: false,
      error: "Você não preencheu o último nome",
    };
    return validation;
  } else if (isNaN(lastName)) {
    validation = {
      valid: true,
    };
    return validation;
  }
  validation = {
    valid: false,
    error: "Este campo não pode ser numérico",
  };
  return validation;
};

export const validateUsername = (username) => {
  let validation;
  if (username === undefined) {
    validation = {
      valid: false,
      error: "Você não preencheu o último nome",
    };
    return validation;
  } else if (isNaN(username)) {
    validation = {
      valid: true,
    };
    return validation;
  }
  validation = {
    valid: false,
    error: "Este campo não pode ser numérico",
  };
  return validation;
};

export const validateEmail = (email) => {
  let validation;
  if (emailShouldPassEmailRegex(email)) {
    validation = {
      valid: true,
    };
  } else {
    validation = {
      valid: false,
      error: "Este não é um e-mail válido",
    };
  }
  return validation;
};

export const validatePassword = (password, passwordConfirm) => {
  let validation;
  if (password.length < 8) {
    validation = {
      valid: false,
      error: "A sua senha precisa ter no mínimo 8 caractéres",
    };
  } else if (password !== passwordConfirm) {
    validation = {
      valid: false,
      error: "As senhas inseridas não são iguais",
    };
  } else {
    validation = {
      valid: true,
    };
  }
  return validation;
};

export const validateFileSizeRestriction = (fileSize) => (fileObject) => {
  return (
    !fileObject ||
    fileObject.content.size === undefined ||
    (fileObject && fileObject.content.size <= fileSize)
  );
};

export const validateSignUp = (answers) => {
  const errors = {
    firstName: validateFirstName(answers.firstName),
    lastName: validateLastName(answers.lastName),
    username: validateUsername(answers.username),
    email: validateEmail(answers.email),
    password: validatePassword(answers.password, answers.passwordConfirm),
  };
  return errors;
};

export const translateErrorMessage = (errorMessage) => {
  let translatedErrorMessage = "";
  if (errorMessage.includes("This password is entirely numeric.")) {
    translatedErrorMessage += "A senha é inteiramente numérica. ";
  }
  if (errorMessage.includes("This password is too common.")) {
    translatedErrorMessage += "A senha é muito comum. ";
  }
  if (errorMessage.includes("The password is too similar to the first name")) {
    translatedErrorMessage += "A senha é muito similar ao nome. ";
  }
  if (errorMessage.includes("The password is too similar to the last name")) {
    translatedErrorMessage += "A senha é muito similar ao sobrenome. ";
  }
  if (errorMessage.includes("The password is too similar to the username")) {
    translatedErrorMessage += "A senha é muito similar ao nome de usuário. ";
  }
  if (errorMessage.includes("The password is too similar to the email")) {
    translatedErrorMessage += "A senha é muito similar ao e-mail. ";
  }
  if (errorMessage.includes("This password is too short.")) {
    translatedErrorMessage += "A senha é muito curta. ";
  }
  if (errorMessage.includes("No active account found with the given credentials")) {
    translatedErrorMessage += "Dados inválidos. ";
  }
  if (errorMessage.includes("This field may not be blank.")) {
    translatedErrorMessage += "Há um campo vazio. ";
  }
  if (errorMessage.includes("A user is already registered with this e-mail address.")) {
    translatedErrorMessage += "Este e-mail já está sendo usado por outro usuário. ";
  }
  if (errorMessage.includes("A user is already registered with this e-mail address.")) {
    translatedErrorMessage += "Este e-mail já está sendo usado por outro usuário. ";
  }
  if (errorMessage.includes("No authorization token set")) {
    translatedErrorMessage += "Dados inválidos. ";
  }
  if (translatedErrorMessage == "") {
    translatedErrorMessage = errorMessage;
  }
  return translatedErrorMessage;
};

export const validateSupportedFileFormats = (fileFormats) => (fileObject) => {
  if (!fileObject || !fileObject.file_name) return false;

  const fileExt = fileObject.file_name.split(".").slice(-1)[0];
  return fileObject && fileFormats.includes(fileExt);
};
