import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from '../../../../utils/APIutils';
import { getInitialFormValues } from '../../../../utils/useForm';
import { FilterForm } from '../../../Filtering/FilterForm';
import { Button } from '../../../Forms';
import { PaginationControls } from '../../../Pagination';
import { Toast } from '../../../Toast';
import { remanagementStatus } from '../../../../constants/projectRemanagementForm'

import styles from './styles.module.scss';

const filtersForm = [
  {
    name: "solicitation_date__gte",
    title: "Data de Solicitação",
    fieldType: "input",
    inputType: "date"
  },
  {
    name: "remanagement_status__icontains",
    title: "Status do Remanejamento",
    fieldType: "select",
    options: remanagementStatus.map(([l, v]) => ({label: l, value : v}))
  }
];

const initialFilters = getInitialFormValues({DATA: filtersForm});

function formatDate(dateString){
  const [year, month, day] = dateString.split("-").map(item => Number(item));
  return `${day}/${month}/${year}`;
}

function currencyFormat(num) {
  return num.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
}

export default function ProjectRemanagementList({projectId, userData}){
  const [remanagementsList, setRemanagementList] = useState(null);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);

  const loadProjectRemanagements = () => {
    const notBlankFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => !!value)
    );
    setLoading(true);
    get(`projects/${projectId}/remanagements`, {params: {page, ...notBlankFilters}})
    .then((response) => response.data)
    .then((data) => {
      setRemanagementList(data.items);
      setPageCount(data.pageCount);
    })
    .catch((error) => console.log(error))
    .finally(() => setLoading(false));
  };

  useEffect(() => {
    if(projectId && (remanagementsList === null || reload)){
      loadProjectRemanagements();
      setReload(false);
    }
  }, [projectId, reload])

  useEffect(() => {
    if(projectId){
      loadProjectRemanagements();
    }
  }, [page, filters])

  return (
    <>
      <div className={styles.newButtonWrapper}>
        <Link tabIndex={-1} to={`/projects/${projectId}/remanagements/new`}>
          <Button styleString="filled">
            Novo
          </Button>
        </Link>
      </div>
      <div className={styles.listControls}>
        <PaginationControls
          currentPage={page}
          totalPages={pageCount}
          setPage={setPage}
        />

        <FilterForm
          form={filtersForm}
          filters={filters}
          setFilters={(values) => setFilters(values)}
        />
      </div>

      <div className={styles.wrapper}>
        <Toast show={loading} loading={loading}/>

        <section className={styles.remanagementsListSection}>
          <table>
            <thead>
              <tr>
                <th> Item Original </th>
                <th> Item Novo </th>
                <th> Data de Solicitação </th>
                <th> Valor Remanejado </th>
                <th> Status do Remanejamento </th>
              </tr>
            </thead>
            <tbody>
              {remanagementsList?.length > 0 && remanagementsList.map((remanagement) =>
                <tr>
                  <td>{remanagement.previous_investment_item}</td>
                  <td>{remanagement.new_investment_item}</td>
                  <td>{formatDate(remanagement.solicitation_date)}</td>
                  <td>{currencyFormat(remanagement.remanaged_budget)}</td>
                  <td>{remanagement.remanagement_status}</td>
                  <td>
                    <div className={styles.remanagementOptions}>
                      <Link tabIndex={-1} to={`/projects/${projectId}/remanagements/${remanagement.id}`}>
                        <button> Visualizar </button>
                      </Link>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>
      </div>
    </>
  )
} 