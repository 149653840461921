import { formStatus, toLabelValuePair } from "./options";
import * as Yup from "yup";
import { targetPopulationOptions } from "./projectsForm";

function sortByKey(array, key) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

const validate500chars = (value) => {
  if (value.length > 500) {
    return "Limite de 500 caracteres atingido";
  }
};

export const projectPlanningPages = {
  PRESENTATION: {
    label: "Apresentação",
    order: 1,
  },
  COLLABORATORS: {
    label: "Colaboradores",
    order: 2,
  },
  REGION: {
    label: "Região",
    order: 3,
  },
  POPULATION: {
    label: "Público do projeto",
    order: 4,
  },
  ACTIVITIES: {
    label: "Atividades do projeto",
    order: 5,
  },
  METRICS: {
    label: "Métricas",
    order: 6,
  },
  INVESTMENTS: {
    label: "Investimentos",
    order: 7,
  },
  SCHEDULES: {
    label: "Cronogramas",
    order: 8,
  },
  INCENTIVE_LAW: {
    label: "Lei de incentivo",
    order: 9,
  },
};

export const projectPlanningForm = {
  PRESENTATION: [
    {
      title: "Status",
      name: "form_status",
      fieldType: "select",
      options: toLabelValuePair(formStatus),
      groups: [1, 2, 3],
      columns: "1/4",
    },
    {
      title: "O que é o projeto?",
      fieldType: "textarea",
      name: "project.what",
      validate: validate500chars,
      columns: "1/4",
    },
    {
      title: "Como funciona?",
      fieldType: "textarea",
      name: "project.how",
      validate: validate500chars,
      columns: "1/4",
    },
    {
      title: "Por que precisa acontecer?",
      fieldType: "textarea",
      name: "project.why",
      validate: validate500chars,
      columns: "1/4",
    },
    {
      title: "Pré-requisitos para os atendidos:",
      tooltip:
        "Explicar os critérios para definir quem poderá ser atendido pelo projeto",
      fieldType: "textarea",
      name: "project.prerequisites",
      validate: validate500chars,
      columns: "1/4",
    },
    {
      title: "Total de beneficiados",
      fieldType: "input",
      inputType: "number",
      name: "project.beneficiaries_amount",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .nullable(),
      columns: "1/4",
    },
    {
      title: "Público alvo",
      fieldType: "checkbox",
      name: "project.target_population",
      options: targetPopulationOptions.map(([l, v]) => ({
        label: l,
        value: v,
      })),
      columns: "1/4",
    },
  ],
  COLLABORATORS: [
    {
      title: "Lista de colaboradores",
      name: "collaborators",
      fieldType: "table",
      tableFields: [
        {
          name: "name",
          title: "Nome",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().max(
            100,
            "Limite de 100 caracteres atingido"
          ),
        },
        {
          name: "position",
          title: "Cargo",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().max(
            100,
            "Limite de 100 caracteres atingido"
          ),
        },
        {
          name: "education_level",
          title: "Nível de escolaridade",
          fieldType: "select",
          options: [
            ["Analfabeto", "Analfabeto"],
            ["Ensino fundamental incompleto", "Ensino fundamental incompleto"],
            ["Ensino fundamental completo", "Ensino fundamental completo"],
            ["Ensino médio incompleto", "Ensino médio incompleto"],
            ["Ensino médio completo", "Ensino médio completo"],
            ["Ensino superior incompleto", "Ensino superior incompleto"],
            ["Ensino superior completo", "Ensino superior completo"],
          ].map(([k, v]) => ({ label: k, value: v })),
        },
        {
          name: "relationship",
          title: "Relação com o projeto",
          fieldType: "select",
          options: [
            ["CLT", "CLT"],
            ["Estágio", "Estágio"],
            ["MEI", "MEI"],
            ["RPA", "RPA"],
            ["Sócio", "Sócio"],
            ["Voluntário", "Voluntário"],
          ].map(([k, v]) => ({ label: k, value: v })),
        },
        {
          name: "paid_with_project_resources",
          title: "Colaborador pago com recursos do projeto?",
          fieldType: "radio",
          isBoolean: true,
        },
      ],
      columns: "1/4",
    },
  ],
  REGION: [
    {
      title: "Descreva a região onde está localizado o projeto:",
      fieldType: "textarea",
      name: "region",
      validationSchema: Yup.string().max(
        500,
        "Limite de 500 caracteres atingido"
      ),
      columns: "1/3",
    },
    {
      title: "Qual o IDH da região?",
      fieldType: "input",
      inputType: "number",
      name: "region_idh",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .max(1, "O valor do IDH deve ser entre 0 e 1")
        .nullable(),
      columns: "3/4",
    },
    {
      title: "Nome da comunidade/bairro e da cidade:",
      fieldType: "input",
      inputType: "text",
      name: "district",
      validationSchema: Yup.string().max(
        100,
        "Limite de 100 caracteres atingido"
      ),
      columns: "1/2",
    },
    {
      title: "Número de habitantes da comunidade/bairro:",
      fieldType: "input",
      inputType: "number",
      name: "district_population",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .nullable(),
      columns: "2/3",
    },
    {
      title: "IVM da região atendida pelo projeto:",
      fieldType: "input",
      inputType: "number",
      name: "district_ivm",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .nullable(),
      columns: "3/4",
    },
  ],
  POPULATION: [
    {
      title: "Perfil dos participantes:",
      fieldType: "textarea",
      name: "participants_profile",
      validationSchema: Yup.string().max(
        500,
        "Limite de 500 caracteres atingido"
      ),
      columns: "1/2",
    },
    {
      title: "Quantidade de participantes:",
      fieldType: "input",
      inputType: "number",
      name: "participants_amount",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .nullable(),
      columns: "2/3",
    },
    {
      title: "Quantidade inicial de inscritos:",
      fieldType: "input",
      inputType: "number",
      name: "participants_initial_amount",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .nullable(),
      columns: "3/4",
    },
    {
      title: "Meios de inscrição/seleção do público:",
      fieldType: "textarea",
      name: "participant_entry_method",
      validationSchema: Yup.string().max(
        200,
        "Limite de 200 caracteres atingido"
      ),
      columns: "1/4",
    },
    {
      title: "Meios de contagem de público:",
      fieldType: "textarea",
      name: "participant_accounting_method",
      validationSchema: Yup.string().max(
        200,
        "Limite de 200 caracteres atingido"
      ),
      columns: "1/4",
    },
    {
      title: "Renda média da população dos municípios atendidos:",
      fieldType: "input",
      inputType: "number",
      name: "average_municipality_income",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .nullable(),
      columns: "1/4",
    },
    {
      title: "Renda média de beneficiados pelo projeto:",
      fieldType: "input",
      inputType: "number",
      name: "average_participant_income",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .nullable(),
      columns: "1/4",
    },
  ],
  ACTIVITIES: [
    {
      title: "Lista de atividades do projeto",
      name: "activities",
      fieldType: "table",
      sortingFunction: (value) => {
        if (value[0]?.hasOwnProperty("planned_month")) {
          value = sortByKey(value, "planned_month");
        }
        return value;
      },
      tableFields: [
        {
          title: "Data",
          name: "planned_month",
          fieldType: "input",
          inputType: "month",
          validationSchema: Yup.date().required("Campo obrigatório"),
        },
        {
          title: "Descrição",
          name: "activity_description",
          fieldType: "textarea",
          validationSchema: Yup.string().required("Campo obrigatório").max(
            1000,
            "Limite de 1000 caracteres atingido"
          ),
        },
      ],
      columns: "1/4",
    },
  ],
  METRICS: [
    {
      title: "Métricas de avaliação",
      name: "indicators",
      fieldType: "table",
      tableFields: [
        {
          title: "Objetivo",
          name: "objective",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().required("Campo obrigatório").max(
            200,
            "Limite de 200 caracteres atingido"
          ),
        },
        {
          title: "Indicador",
          name: "indicator",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().required("Campo obrigatório").max(
            200,
            "Limite de 200 caracteres atingido"
          ),
        },
        {
          title: "Resultado quantitativo",
          name: "quantitative_result",
          fieldType: "input",
          inputType: "number",
          validationSchema: Yup.number().required("Campo obrigatório")
            .moreThan(-1, "Apenas números positivos")
            .nullable(),
        },
        {
          title: "Método de avaliação dos resultados",
          name: "result_evaluation",
          fieldType: "select",
          options: ["Somatório", "Média"].map((item) => ({
            label: item,
            value: item,
          })),
          validationSchema: Yup.string().required("Campo obrigatório")
        },
        {
          title: "Método de verificação dos resultados",
          name: "verification_methods_1",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().required("Campo obrigatório").max(
            100,
            "Limite de 100 caracteres atingido"
          ),
        },
        {
          title: "Método de verificação dos resultados 2 (opcional)",
          name: "verification_methods_2",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().max(
            100,
            "Limite de 100 caracteres atingido"
          ),
        },
      ],
      columns: "1/4",
    },
  ],
  INVESTMENTS: [
    {
      title: "Investimentos do projeto",
      name: "investments",
      fieldType: "table",
      tableFields: [
        {
          title: "Tipo de investimento",
          name: "investment_type",
          fieldType: "select",
          options: [
            [
              "Lei de Incentivo - Captação Phi",
              "Lei de Incentivo - Captação Phi",
            ],
            ["Materiais e Equipamentos", "Materiais e Equipamentos"],
            ["Transporte e Alimentação", "Transporte e Alimentação"],
            ["Recursos Humanos", "Recursos Humanos"],
            ["Outros", "Outros"],
          ].map(([k, v]) => ({ label: k, value: v })),
          validationSchema: Yup.string().required("Campo obrigatório"),
        },
        {
          title: "Descrição do item",
          name: "item_description",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().required("Campo obrigatório").max(
            100,
            "Limite de 100 caracteres atingido"
          ),
        },
        {
          title: "Preço unitário",
          name: "unit_price",
          fieldType: "input",
          inputType: "number",
          validationSchema: Yup.number().required("Campo obrigatório")
            .moreThan(-1, "Apenas números positivos")
            .nullable(),
        },
        {
          title: "Unidades",
          name: "amount",
          fieldType: "input",
          inputType: "number",
          validationSchema: Yup.number().required("Campo obrigatório")
            .moreThan(-1, "Apenas números positivos")
            .nullable(),
        },
        {
          title: "Preço total",
          name: "total_price",
          fieldType: "lambda",
          inputType: "number",
          selectValues: (formValues, context, apiOptions) => {
            return formValues?.["investments"]?.[context.index];
          },
          determineValue: (selectedValues, context, apiOptions) => {
            return selectedValues?.["total_price"];
          },
        },
      ],
      columns: "1/4",
    },
    {
      title: "Total dos investimentos",
      name: "total_investment",
      fieldType: "lambda",
      inputType: "number",
      selectValues: (formValues, context) => formValues?.["total_investment"],
      determineValue: (selectedValues, context) => selectedValues,
      columns: "1/2",
    },
  ],
  SCHEDULES: [
    {
      title: "Repasses Phi",
      name: "phi_transfers",
      fieldType: "table",
      tableFields: [
        {
          name: "date",
          title: "Data estimada",
          fieldType: "input",
          inputType: "date",
          validationSchema: Yup.date().required("Campo obrigatório"),
        },
        {
          name: "value",
          title: "Valor a ser repassado",
          fieldType: "input",
          inputType: "number",
          validationSchema: Yup.number().required("Campo obrigatório"),
        },
      ],
      columns: "1/4",
    },
    {
      title: "Relatórios organização",
      name: "org_reports",
      fieldType: "table",
      tableFields: [
        {
          name: "date",
          title: "Data estimada",
          fieldType: "input",
          inputType: "date",
          validationSchema: Yup.date().required("Campo obrigatório"),
        },
        {
          name: "start_date",
          title: "Data de início",
          fieldType: "input",
          inputType: "date",
          validationSchema: Yup.date().required("Campo obrigatório"),
        },
        {
          name: "end_date",
          title: "Data de fim",
          fieldType: "input",
          inputType: "date",
          validationSchema: Yup.date().required("Campo obrigatório"),
        },
      ],
      columns: "1/4",
    },
    {
      title: "Repasses investidor",
      name: "investor_transfers",
      groups: [1, 2, 3],
      fieldType: "table",
      tableFields: [
        {
          name: "date",
          title: "Data estimada",
          fieldType: "input",
          inputType: "date",
          validationSchema: Yup.date().required("Campo obrigatório"),
        },
        {
          name: "value",
          title: "Valor a ser repassado",
          fieldType: "input",
          inputType: "number",
          validationSchema: Yup.number().required("Campo obrigatório"),
        },
      ],
      columns: "1/4",
    },
  ],
  INCENTIVE_LAW: [
    {
      title: "Descrição da contrapartida",
      fieldType: "input",
      inputType: "text",
      name: "consideration_description",
      validationSchema: Yup.string().max(
        500,
        "Limite de 500 caracteres atingido"
      ),
      columns: "1/4",
    },
    {
      title: "Quantidade prevista",
      fieldType: "input",
      inputType: "number",
      name: "consideration_amount",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .nullable(),
      columns: "1/4",
    },
  ],
};
