import {
  Button,
  Input,
  Select,
  TextArea,
  Checkbox,
  Radio,
  Switch,
  FormTable,
  PrefilledFormTable,
  APIOptionsSelect,
  FileInput,
  Lambda,
  ReportFinanceTable,
} from "../../Forms";
import { FinanceTable, PrefilledFinanceTable } from "../../Forms/FinanceTable";
import { UseTooltip } from "./Tooltip";
import { Field } from "formik";

import styles from "./styles.module.scss";
import QuestionErrorBoundary from "./QuestionErrorBoundary";

export default function Question({
  title,
  text,
  tooltip,
  name,
  context,
  error,
  ...props
}) {
  const InputComponent = getInputComponent(props.fieldType);
  return (
    <QuestionErrorBoundary {...props}>
      <div
        style={{ gridColumn: props.columns }}
        className={`${styles.wrapper} ${
          props.fieldType === "table" ? styles.fitTable : ""
        } `}
      >
        <Field name={name} {...props}>
          {({ form, field }) => (
            <>
              <header>
                {title && <h3>{title}</h3>}
                <div className={styles.helpIcon}>
                  {tooltip && (
                    <button type="button">
                      <UseTooltip text={tooltip} />
                    </button>
                  )}
                </div>
              </header>
              {text && <p>{text}</p>}
              <InputComponent
                title={title}
                name={name}
                context={context}
                error={error}
                {...props}
                value={field.value}
                setValue={(value) => {
                  form.setFieldValue(name, value);
                }}
              />
            </>
          )}
        </Field>
      </div>
    </QuestionErrorBoundary>
  );
}

export function getFieldStandardValue(fieldType) {
  switch (fieldType) {
    case "input":
      return "";
    case "select":
      return "";
    case "apiselect":
      return "";
    case "button":
      return undefined;
    case "textarea":
      return "";
    case "checkbox":
      return {};
    case "radio":
      return "";
    case "booleanswitch":
      return false;
    case "switch":
      return "";
    case "table":
      return [];
    case "prefilledtable":
      return [];
    case "financetable":
      return [];
    case "prefilledfinancetable":
      return [];
    case "reportFinanceTable":
      return [];
    case "file":
      return "";
    case "multifile":
      return [];
    case "lambda":
      return "";
    default:
      return "";
  }
}

export function getInputComponent(fieldType) {
  switch (fieldType) {
    case "input":
      return Input;
    case "apiselect":
      return APIOptionsSelect;
    case "select":
      return Select;
    case "button":
      return Button;
    case "textarea":
      return TextArea;
    case "checkbox":
      return Checkbox;
    case "radio":
      return Radio;
    case "booleanswitch":
      return Switch;
    case "switch":
      return Switch;
    case "table":
      return FormTable;
    case "prefilledtable":
      return PrefilledFormTable;
    case "financetable":
      return FinanceTable;
    case "prefilledfinancetable":
      return PrefilledFinanceTable;
    case "reportFinanceTable":
      return ReportFinanceTable;
    case "file":
      return FileInput;
    case "multifile":
      return (props) => <FileInput multiple={true} {...props} />;
    case "lambda":
      return Lambda;
    default:
      return "";
  }
}

export function getFieldStandardError(fieldName) {
  switch (fieldName) {
    case "analyst":
      return "Campo obrigatório";
    case "end_date":
      return "Campo obrigatório";
    case "form_status":
      return "Campo obrigatório";
    case "manager":
      return "Campo obrigatório";
    case "name":
      return "Campo obrigatório";
    case "org":
      return "Campo obrigatório";
    case "start_date":
      return "Campo obrigatório";
    case "status":
      return "Campo obrigatório";
    case "value":
      return "Campo obrigatório";
    case "parcel":
      return "Campo obrigatório";
    case "type":
      return "Campo obrigatório";
    case "begin_date":
      return "Campo obrigatório";
    case "org_name":
      return "Campo obrigatório";
    case "foundation_date":
      return "Campo obrigatório";
    case "logo":
      return "Campo obrigatório";
    default:
      return "";
  }
}
