import styles from './styles.module.scss';

export default function Button({
  className,
  children,
  onClick,
  styleString,
  disabled,
  context,
  ...props
}){
  const classString = disabled === true ? (
    styles.disabled
  ) : styleString === "filled" ? (
    styles.filled
  ) : styleString === "outlined" ? (
    styles.outlined
  ) : "";
  const currentClassName = `${styles.button} ${classString} ${className ? className : ""}`;
  return (
    <button
      disabled={disabled}
      className={currentClassName}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}