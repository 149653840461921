import { useState } from "react";
import { Link, Route, Switch } from "react-router-dom"
import { isUserInGroups } from "../../utils/permissionUtils";
import { ConditionsEnforcer } from "../Auth/ConditionsEnforcer";
import { Button } from "../Forms";
import { OrgDetail } from "./OrgDetail";
import { OrgName } from "./OrgName";
import { OrgsList } from "./OrgsList";

import styles from './styles.module.scss';

export default function OrgsPage({userData}){

  return (
    <div className={styles.wrapper}>
      <div className={styles.pageHeader}>
        <Switch>
          <Route
            path="/orgs/:orgId"
            render={(props) => (
              <OrgName
                orgId={props.match.params.orgId}
              />
            )}
          />
          <Route
            path="/orgs"
          >
            <h2>
              Organizações
            </h2>
          </Route>
        </Switch>

        <Switch>
          <Route path="/orgs/:slug">
            <ConditionsEnforcer
              conditions={[
                isUserInGroups(userData, [1, 2, 3, 7])
              ]}
            >
              <Link tabIndex={-1} to="/orgs">
                <Button styleString="outlined">
                  Voltar
                </Button>
              </Link>
            </ConditionsEnforcer>
          </Route>

          <Route path="/orgs">
            <ConditionsEnforcer
              conditions={[
                isUserInGroups(userData, [6])
              ]}
            >
              <Link tabIndex={-1} to="/orgs/new">
                <Button styleString="filled">
                  Novo
                </Button>
              </Link>
            </ConditionsEnforcer>
          </Route>
        </Switch>
      </div>

      <Switch>
        <Route path="/orgs/:orgId"
          render={(props) =>
            <OrgDetail
              orgId={props.match.params.orgId}
              userData={userData}
            />
          }
        />
        <Route path="/orgs">
          <ConditionsEnforcer
            conditions={[
              isUserInGroups(userData, [1, 2, 3, 7])
            ]}
            fallbackTo={`/orgs/${userData?.org ? userData.org : "new"}`}
          >
            <OrgsList/>
          </ConditionsEnforcer>
        </Route>
      </Switch>
    </div>
  )
}