import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { get } from "../../../utils/APIutils";
import { Loading } from "../../Loading";

import styles from './styles.module.scss'

export default function NotificationList({}){
  const [notifications, setNotifications] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(notifications === null){
      setLoading(true);
      get("notifications")
      .then((response) => response.data)
      .then((responseObject) => {
        setNotifications(responseObject);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error)
      });
    }
  }, []);

  return(
    <section className={styles.listWrapper}>
      <div className={styles.header}>
        <h3>
          Notificações
        </h3>
        <span>
          {notifications && notifications.length}
        </span>
      </div>
      <ol className={styles.notificationList}>
        {loading ? (
          <Loading/>
        ) : (
          notifications?.length > 0 ? (
            notifications.map((notification) => 
              <li
                className={styles.notification}
                key={notification["created_at"]}
              >
                <div>
                  <ReactSVG className={styles.notificationIcon} src="/icons/info_24px.svg"/>
                </div>
                <div>
                  <p>{notification.text}</p>
                </div>
                <div>
                  <Link href={notification.href}>
                    <ReactSVG className={styles.anchorIcon} src="/icons/open_in_new_24px.svg"/>
                  </Link>
                </div>
              </li>
            )
          ) : (
            <li className={styles.noNotifications}>
              <p> Você não tem notificações </p>
            </li>
          )
        )}
      </ol>
    </section>
  )
}