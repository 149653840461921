import {
  getInitialFormValues,
  getValidationValue,
} from "../../../utils/useForm";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { Button } from "../Button";
import { Question } from "../../FormPage/Question";
import { ModalLayout } from "../../Modal";
import * as Yup from "yup";

import styles from "./styles.module.scss";

export default function InsertNewModal({
  title,
  show,
  fields,
  context,
  setShow,
  onSave,
}) {
  const validationSchema = useMemo(
    () => fields && Yup.object().shape(getValidationValue({ FIELDS: fields })),
    [fields]
  );
  const standardInitialValues = useMemo(
    () => fields && getInitialFormValues({ FIELDS: fields }),
    [fields]
  );

  return (
    <ModalLayout title={title} show={show} setShow={setShow}>
      <Formik
        enableReinitialize
        initialValues={standardInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, formikProps) => {
          setShow(false);
          formikProps.resetForm();
          onSave(values);
        }}
      >
        {({ values, ...props }) => (
          <>
            <div className={styles.questions}>
              <Form id="insertNewModalForm">
                {fields?.map((question, index) => (
                  <Question
                    key={question.name}
                    value={values[question.name]}
                    context={context}
                    error={props.errors[question.name]}
                    {...question}
                  />
                ))}
              </Form>
            </div>
            <div className={styles.footer}>
              <Button
                styleString="filled"
                type="button"
                form="insertNewModalForm"
                onClick={() => props.submitForm()}
              >
                Adicionar
              </Button>
            </div>
          </>
        )}
      </Formik>
    </ModalLayout>
  );
}
