import { useEffect, useMemo, useState } from "react";
import { Redirect } from "react-router";
import { get, patch, post, put } from "../../../utils/APIutils";
import {
  projectsForm,
  projectsFormPages,
} from "../../../constants/projectsForm";
import { FormPage } from "../../FormPage";
import { isUserInGroups, isUserPhi } from "../../../utils/permissionUtils";
import { Toast, useToast } from "../../Toast";
import { infoLevels } from "../../../constants";
import { formStatus, isAnyStatus } from "../../../constants/options";

import styles from "./styles.module.scss";

function filterProjectForm(userData) {
  return {
    DATA: projectsForm.DATA.filter(
      (field) => !field.groups || isUserInGroups(userData, field.groups)
    ),
  };
}

export default function ProjectForm({ projectId, userData }) {
  const [filteredForm, setFilteredForm] = useState(() =>
    filterProjectForm(userData)
  );
  const [projectData, setProjectData] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [reload, setReload] = useState(false);
  const [toast, setToast] = useToast();
  const [loading, setLoading] = useState(false);

  const loadProject = () => {
    if (projectId) {
      setLoading(true);
      get(`projects/${projectId}`)
        .then((response) => setProjectData(response.data))
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  };

  const submitForAnalysis = (_projectId) => {
    return patch(`projects/${_projectId}`, {}).then(() =>
      projectId ? setReload(true) : setRedirect(true)
    );
  };

  const handleSaveNew = (formData) => {
    return post(`projects`, formData).then((response) => {
      setToast({
        show: true,
        infoLevel: infoLevels.SUCCESS,
        children: "Alteração feita com sucesso",
      });
      return response.data.id;
    });
  };

  const handleSaveUpdate = (formData) => {
    return put(`projects/${projectId}`, formData).then((response) => {
      setToast({
        show: true,
        infoLevel: infoLevels.SUCCESS,
        children: "Alteração feita com sucesso",
      });
      return response.data.id;
    });
  };

  const handleSave = (formData) => {
    if (isUserInGroups(userData, [4, 5, 6])) {
      formData.org = formData.org ?? userData.org;
      formData.manager = formData.manager ?? 1;
      formData.analyst = formData.analyst ?? 1;
    }

    if (formData.manager_approval === "") { delete formData.manager_approval } 
    if (formData.director_approval === "") { delete formData.director_approval } 

    setLoading(true);
    return (projectId ? handleSaveUpdate(formData) : handleSaveNew(formData))
      .then(() => (projectId ? setReload(true) : setRedirect(true)))
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response.status),
        });
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (formData) => {
    if (isUserInGroups(userData, [4, 5, 6])) {
      formData.org = formData.org ?? userData.org;
      formData.manager = formData.manager ?? 1;
      formData.analyst = formData.analyst ?? 1;
    }

    setLoading(true);
    return (projectId ? handleSaveUpdate(formData) : handleSaveNew(formData))
      .then((projectId) => {
        return submitForAnalysis(projectId);
      })
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response.status),
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (reload || (projectId && projectData === null)) {
      loadProject();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    setFilteredForm(filterProjectForm(userData));
  }, [projectId, userData]);

  const editable = useMemo(() => {
    return Boolean(
      !projectId ||
        (projectData !== null &&
          (isUserPhi(userData) ||
            isAnyStatus(
              projectData.form_status,
              formStatus["Desatualizado"],
              formStatus["Editável"],
              formStatus["Incompleto"]
            )))
    );
  }, [projectId, projectData, userData]);

  return (
    <div className={styles.wrapper}>
      {(!projectId || (projectId && projectData)) && (
        <FormPage
          name="projectForm"
          form={filteredForm}
          pages={projectsFormPages}
          initialState={projectData}
          editable={editable !== false}
          reload={reload}
          setReload={setReload}
          onSave={handleSave}
          onSubmit={handleSubmit}
          context={{ projectId }}
          userData={userData}
        />
      )}

      {redirect && <Redirect to="/projects/" />}

      <Toast {...toast} />

      <Toast show={loading} loading={loading} />
    </div>
  );
}
