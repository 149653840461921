import { Switch, Route } from "react-router-dom";
import { ProjectRemanagementsForm } from "./ProjectRemanagementsForm";
import { ProjectRemanagementsList } from "./ProjectRemanagementsList";

export default function ProjectRemanagements({userData}){

  return (
    <Switch>
      <Route
          path="/projects/:projectId/remanagements/new"
          render={(props) => (
            <>
              <ProjectRemanagementsForm
                projectId={props.match.params.projectId}
                remanagementId={undefined}
                userData={userData}
              />
            </>
          )}
        />
        <Route
          path="/projects/:projectId/remanagements/:remanagementId"
          render={(props) => (
            <>
              <ProjectRemanagementsForm
                projectId={props.match.params.projectId}
                remanagementId={props.match.params.remanagementId}
                userData={userData}
              />
            </>
          )}
        />
        <Route
          path="/projects/:projectId/remanagements"
          render={(props) => (
            <>
              <ProjectRemanagementsList
                projectId={props.match.params.projectId}
                userData={userData}
              />
            </>
          )}
        />
    </Switch>
  )
}