import { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { ReactSVG } from "react-svg";
import { infoLevels } from "../../../../constants";
import { formStatus, isAnyStatus } from "../../../../constants/options";
import {
  projectTransferForm,
  projectsTransferFormPages,
} from "../../../../constants/projectTransferForm";
import { get, patch, post, put, getFile } from "../../../../utils/APIutils";
import { isUserPhi } from "../../../../utils/permissionUtils";
import { filterFormFieldsByUserGroup } from "../../../../utils/useForm";
import { FormPage } from "../../../FormPage";
import { StatusLabel } from "../../../FormPage/StatusLabel";
import { Button } from "../../../Forms";
import { Toast, useToast } from "../../../Toast";
import { saveAs } from "file-saver";

import styles from "./styles.module.scss";

export default function ProjectTransfersForm({
  projectId,
  transferId,
  editable,
  userData,
}) {
  const [filteredForm, setFilteredForm] = useState(() =>
    filterFormFieldsByUserGroup(userData, projectTransferForm)
  );
  const [transferData, setTransferData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useToast();
  const [reload, setReload] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  const loadTransferData = () => {
    setLoading(true);
    get(`projects/${projectId}/transfers/${transferId}`)
      .then((response) => response.data)
      .then((transferData) => setTransferData(transferData))
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response.status),
        });
      })
      .finally(() => setLoading(false));
  };

  const submitForAnalysis = (transferId) => {
    return patch(`projects/${projectId}/transfers/${transferId}`);
  };

  const handleSaveNew = (formData) => {
    return post(`projects/${projectId}/transfers`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      setToast({
        show: true,
        infoLevel: infoLevels.SUCCESS,
        children: "Alteração feita com sucesso",
      });
      return response.data.id;
    });
  };

  const handleSaveUpdate = (formData) => {
    return put(`projects/${projectId}/transfers/${transferId}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      setToast({
        show: true,
        infoLevel: infoLevels.SUCCESS,
        children: "Alteração feita com sucesso",
      });
      return response.data.id;
    });
  };

  const handleSave = (formData) => {
    setLoading(true);
    return (
      transferId === undefined
        ? handleSaveNew(formData)
        : handleSaveUpdate(formData)
    )
      .then((responseTransferId) => {
        if (transferId === undefined) {
          return setRedirect(
            `/projects/${projectId}/transfers/${responseTransferId}`
          );
        }
        return loadTransferData();
      })
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response.status),
        });
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (formData) => {
    setLoading(true);
    return (
      transferId === undefined
        ? handleSaveNew(formData)
        : handleSaveUpdate(formData)
    )
      .then((responseTransferId) => submitForAnalysis(responseTransferId))
      .then((response) => {
        if (transferId === undefined) {
          return setRedirect(
            `/projects/${projectId}/transfers/${response.data.id}`
          );
        }
        return loadTransferData();
      })
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response.status),
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (transferId && projectId && (transferData === null || reload)) {
      loadTransferData();
      setReload(false);
    }
  }, [projectId, transferId, reload]);

  useEffect(() => {
    setFilteredForm(filterFormFieldsByUserGroup(userData, projectTransferForm));
  }, [userData]);

  useEffect(() => {
    setShowDownloadButton(projectId && isUserPhi(userData));
  }, [projectId, userData]);

  return (
    <div className={styles.formPageWrapper}>
      {showDownloadButton && (
        <button
          className={styles.downloadTransfersButton}
          onClick={() => saveAs(transferData?.transfer_receipt_file?.content)}
        >
          <span>Baixar NF</span>
          <ReactSVG src="/icons/download_24px.svg" />
        </button>
      )}

      {(!transferId || (transferId && transferData)) !== null && (
        <FormPage
          name="projectTransferForm"
          form={filteredForm}
          initialState={transferData}
          pages={projectsTransferFormPages}
          editable={editable !== false}
          reload={reload}
          setReload={setReload}
          onSubmit={handleSubmit}
          onSave={handleSave}
          context={{ projectId }}
          userData={userData}
        />
      )}

      {redirect && <Redirect to={redirect} />}

      <Toast {...toast} />

      <Toast loading={loading} show={loading} />
    </div>
  );
}
