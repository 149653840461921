import { createPortal } from "react-dom";
import { ReactSVG } from "react-svg";

import styles from "./styles.module.scss";

export default function ModalLayout({ title, show, setShow, children }) {
  return createPortal(
    show ? (
      <div
        className={[styles.overlay, ...(show ? [] : [styles.hidden])].join(" ")}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
            {title && <h2>{title}</h2>}
            <button autoFocus={true} onClick={() => setShow(false)}>
              <ReactSVG src="/icons/close_24px.svg" />
            </button>
          </div>
          <div>{children}</div>
        </div>
      </div>
    ) : null,
    document.getElementById("root")
  );
}
