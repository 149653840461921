import { AxiosPromise } from "axios";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { Question } from "../../../FormPage/Question";
import { Button } from "../../../Forms";
import { ModalLayout } from "../../../Modal";

import styles from "./styles.module.scss";

enum NewReportFieldNames {
  projectId = "project_id",
  beginDate = "begin_date",
  endDate = "end_date",
}

interface NewReportModalProps {
  projectId: number;
  onSubmit: (
    newReportData: Record<NewReportFieldNames, any>
  ) => AxiosPromise<void>;
  show: boolean;
  setShow: (newShow: boolean) => void;
}

function NewReportModal(props: NewReportModalProps): JSX.Element {
  const { projectId, onSubmit, show, setShow } = props;
  console.log("projectId: ", projectId);
  const initialReportValues = useMemo(
    () => ({
      [NewReportFieldNames.projectId]: projectId,
      [NewReportFieldNames.beginDate]: "",
      [NewReportFieldNames.endDate]: "",
    }),
    [projectId]
  );

  return (
    <>
      <ModalLayout title="Novo relatório" show={show} setShow={setShow}>
        <Formik
          initialValues={initialReportValues}
          enableReinitialize
          onSubmit={(data) => onSubmit(data)}
        >
          {(formikProps) => (
            <Form>
              <Question
                name={NewReportFieldNames.beginDate}
                title="Data do início do período do relatório"
                fieldType="input"
                inputType="date"
                text={undefined}
                tooltip={undefined}
                context={undefined}
                error={formikProps.errors[NewReportFieldNames.beginDate]}
              />
              <Question
                name={NewReportFieldNames.endDate}
                title="Data do fim do período do relatório"
                fieldType="input"
                inputType="date"
                text={undefined}
                tooltip={undefined}
                context={undefined}
                error={formikProps.errors[NewReportFieldNames.endDate]}
              />
              <div className={styles.modalFooter}>
                <Button type="submit" styleString="filled" {...({} as any)}>
                  Criar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalLayout>
    </>
  );
}

export default NewReportModal;
