import { formStatus, toLabelValuePair } from "./options";
import * as Yup from "yup";

const UFValues = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

const activityAreaOptions = [
  ["Acessibilidade", "Acessibilidade"],
  [
    "Combate à violência (contra a mulher, abuso infantil, etc.)",
    "Combate à violência (contra a mulher, abuso infantil, etc.)",
  ],
  ["Cultura (música, teatro, artes)", "Cultura (música, teatro, artes)"],
  [
    "Desenvolvimento (construção, estrutura, saneamento, habitação)",
    "Desenvolvimento (construção, estrutura, saneamento, habitação)",
  ],
  ["Direitos Humanos", "Direitos Humanos"],
  ["Educação", "Educação"],
  ["Esporte", "Esporte"],
  [
    "Geração de renda (capacitação e inserção no mercado de trabalho)",
    "Geração de renda (capacitação e inserção no mercado de trabalho)",
  ],
  [
    "Geração de renda (empreendedorismo)",
    "Geração de renda (empreendedorismo)",
  ],
  [
    "Geração de renda (foco no primeiro emprego)",
    "Geração de renda (foco no primeiro emprego)",
  ],
  ["Inclusão (população egressa)", "Inclusão (população egressa)"],
  [
    "Inclusão (população em situação de rua)",
    "Inclusão (população em situação de rua)",
  ],
  [
    "Inclusão (população usuária de drogas)",
    "Inclusão (população usuária de drogas)",
  ],
  ["Inclusão (refugiados)", "Inclusão (refugiados)"],
  ["Lazer", "Lazer"],
  ["Meio ambiente", "Meio ambiente"],
  ["PCD (Pessoas com deficiência)", "PCD (Pessoas com deficiência)"],
  ["Outras", "Outras"],
];

export const targetPopulationOptions = [
  ["Crianças (0 a 4 anos)", "children_0_4"],
  ["Crianças (4 a 12 anos)", "children_4_12"],
  ["Adolescentes", "teenagers"],
  ["Adultos", "adults"],
  ["Famílias", "families"],
  ["Idosos", "elderly"],
  ["Jovens", "youth"],
  ["Jovens em conflito com a lei", "minor_onfractors"],
  ["LGBT", "lgbt"],
  ["Pessoas com deficiência", "disabled_people"],
  ["População de abrigo", "sheltered"],
  ["População de rua", "homeless"],
  ["População rural", "rural_population"],
  ["Pessoas encarceradas", "inmates"],
  ["Superdotados", "gifted"],
  ["Outros", "others"],
];

export const projectsFormPages = {
  DATA: {
    label: "DADOS DO PROJETO",
    order: 1,
  },
};

export const projectsForm = {
  DATA: [
    {
      title: "Status do formulário",
      name: "form_status",
      fieldType: "select",
      options: toLabelValuePair(formStatus),
      groups: [1, 2, 3],
      validationSchema: Yup.number().required("Campo obrigatório"),
      columns: "1/2",
    },
    {
      title: "Aprovação da Gerência",
      name: "manager_approval",
      fieldType: "select",
      groups: [1, 2, 3],
      columns: "1/2",
      options: [
        ["Aprovado", "APPROVED"],
        ["Não Aprovado", "NOT_APPROVED"],
        ["Análise Pendente", "PENDING"],
      ].map(([l, v]) => ({ label: l, value: v })),
    },
    {
      title: "Comentários da Gerência",
      text: "",
      tooltip: "",
      label: "",
      fieldType: "textarea",
      groups: [1, 2, 3],
      name: "manager_comments",
      placeholder: "",
      validationSchema: Yup.string().max(
        200,
        "Limite de 200 caracteres atingido"
      ),
      columns: "2/4",
    },
    {
      title: "Aprovação da Diretoria",
      name: "director_approval",
      fieldType: "select",
      groups: [1, 2, 3],
      columns: "1/2",
      options: [
        ["Aprovado", "APPROVED"],
        ["Não Aprovado", "NOT_APPROVED"],
        ["Análise Pendente", "PENDING"],
      ].map(([l, v]) => ({ label: l, value: v })),
    },
    {
      title: "Comentários da Diretoria",
      text: "",
      tooltip: "",
      label: "",
      fieldType: "textarea",
      groups: [1, 2, 3],
      name: "director_comments",
      placeholder: "",
      validationSchema: Yup.string().max(
        200,
        "Limite de 200 caracteres atingido"
      ),
      columns: "2/4",
    },
    {
      title: "Nome do projeto",
      fieldType: "input",
      inputType: "text",
      name: "name",
      validationSchema: Yup.string()
        .max(100, "Limite de 100 caracteres atingido")
        .required("Campo obrigatório"),
      columns: "1/4",
    },
    {
      title: "Organização",
      fieldType: "apiselect",
      name: "org",
      optionsSource: () => `options/orgs`,
      groups: [1, 2, 3],
      validationSchema: Yup.number().required("Campo obrigatório"),
      columns: "1/4",
    },
    {
      title: "O que é o projeto?",
      fieldType: "textarea",
      name: "what",
      validationSchema: Yup.string().max(
        500,
        "Limite de 500 caracteres atingido"
      ),
      columns: "1/4",
    },
    {
      title: "Como funciona?",
      fieldType: "textarea",
      name: "how",
      validationSchema: Yup.string().max(
        500,
        "Limite de 500 caracteres atingido"
      ),
      columns: "1/4",
    },
    {
      title: "Por que precisa acontecer?",
      fieldType: "textarea",
      name: "why",
      validationSchema: Yup.string().max(
        500,
        "Limite de 500 caracteres atingido"
      ),
      columns: "1/4",
    },
    {
      title: "Pré-requisitos para os atendidos:",
      tooltip:
        "Explicar os critérios para definir quem poderá ser atendido pelo projeto",
      fieldType: "textarea",
      name: "prerequisites",
      validationSchema: Yup.string().max(
        500,
        "Limite de 500 caracteres atingido"
      ),
      columns: "1/4",
    },
    {
      title: "Analista",
      fieldType: "apiselect",
      name: "analyst",
      optionsSource: () => `options/analysts`,
      groups: [1, 2, 3],
      validationSchema: Yup.number().required("Campo obrigatório"),
      columns: "1/4",
    },
    {
      title: "Gerente",
      fieldType: "apiselect",
      name: "manager",
      optionsSource: () => `options/managers`,
      groups: [1, 2, 3],
      validationSchema: Yup.number().required("Campo obrigatório"),
      columns: "1/4",
    },
    {
      // TODO: select de investidor
      title: "Investidor",
      fieldType: "input",
      inputType: "text",
      name: "investor",
      groups: [1, 2, 3],
      validationSchema: Yup.string().max(
        200,
        "Limite de 200 caracteres atingido"
      ),
      columns: "1/2",
    },
    {
      title: "Data de início",
      fieldType: "input",
      inputType: "date",
      name: "start_date",
      validationSchema: Yup.date().required("Campo obrigatório"),
      columns: "2/3",
    },
    {
      title: "Data de término",
      fieldType: "input",
      inputType: "date",
      name: "end_date",
      validationSchema: Yup.date()
        .min(
          Yup.ref("start_date"),
          "Data de término não pode vir antes da data de início"
        )
        .required("Campo obrigatório"),
      columns: "3/4",
    },
    {
      title: "Valor total (R$)",
      fieldType: "input",
      inputType: "number",
      name: "total_value",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .nullable(),
      columns: "1/4",
    },
    {
      //obrigatorio
      title: "Status do projeto",
      fieldType: "select",
      name: "status",
      placeholder: "Selecione...",
      options: [
        ["Ativo", "ACTIVE"],
        ["Finalizado", "FINISHED"],
        ["Cancelado", "CANCELLED"],
        ["Em captação", "FUNDING"],
        ["Paralisado", "PARALYZED"],
        ["Pendente", "PENDING"],
        ["Recurso não liberado", "RESOURCE_BLOCKED"],
      ].map(([l, v]) => ({ label: l, value: v })),
      groups: [1, 2, 3],
      validationSchema: Yup.string().required("Campo obrigatório"),
      columns: "1/4",
    },
    {
      title: "UF do Projeto",
      fieldType: "select",
      name: "uf",
      placeholder: "Selecione...",
      options: UFValues.map((uf) => ({ label: uf, value: uf })),
      columns: "1/4",
    },
    {
      title: "Área de atuação",
      fieldType: "select",
      name: "activity_area",
      placeholder: "Selecione...",
      options: activityAreaOptions.map(([l, v]) => ({ label: l, value: v })),
      columns: "1/3",
    },
    {
      title: "Total de beneficiados",
      fieldType: "input",
      inputType: "number",
      name: "beneficiaries_amount",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .nullable(),
      columns: "3/4",
    },
    {
      title: "Público alvo",
      fieldType: "checkbox",
      name: "target_population",
      options: targetPopulationOptions.map(([l, v]) => ({
        label: l,
        value: v,
      })),
      columns: "1/4",
    },
    {
      title: "Observações",
      fieldType: "textarea",
      name: "observations",
      validationSchema: Yup.string().max(
        200,
        "Limite de 200 caracteres atingido"
      ),
      columns: "1/4",
    },
  ],
};
