import { useMemo } from "react";
import { useMultipleApiOptions } from "../../../utils/useApiOptions";

import styles from "./styles.module.scss";

enum FinancesFieldNames {
  investmentItem = "investment_item",
  investmentType = "investment_type",
  initialBudget = "initial_budget",
  expensesUntilLastReport = "expenses_until_last_report",
  currentExpenses = "current_expenses",
  remanagedBudget = "remanaged_budget",
  currentBudget = "current_budget",
  amount = "amount",
  previousItem = "previous_investment_item",
  newItem = "new_investment_item",
}

interface InvestmentItem {
  value: string | number;
  label: string;
  [FinancesFieldNames.investmentType]: string;
  [FinancesFieldNames.expensesUntilLastReport]: number;
  [FinancesFieldNames.initialBudget]: number;
}

interface ReportExpense {
  [FinancesFieldNames.investmentItem]: string;
  [FinancesFieldNames.amount]: number;
}

interface ReportRemanagement {
  [FinancesFieldNames.previousItem]: string;
  [FinancesFieldNames.newItem]: string;
  [FinancesFieldNames.remanagedBudget]: number;
}

interface FinanceTableRow {
  [FinancesFieldNames.investmentType]: string;
  [FinancesFieldNames.investmentItem]: string | number;
  [FinancesFieldNames.initialBudget]: number;
  [FinancesFieldNames.expensesUntilLastReport]: number;
  [FinancesFieldNames.currentExpenses]: number;
  [FinancesFieldNames.remanagedBudget]: number;
  [FinancesFieldNames.currentBudget]: number;
}

interface TableField {
  name: keyof FinanceTableRow;
  title: string;
}

interface ReportFinanceTableProps {
  label: string;
  name: string;
  formValues: Record<string, any>;
  context: Record<string, any>;
}

enum FinanceApiOptions {
  investmentItem = "investment_item",
}

const TableFields: TableField[] = [
  { name: FinancesFieldNames.investmentType, title: "Tipo de investimento" },
  { name: FinancesFieldNames.investmentItem, title: "Item de investimento" },
  { name: FinancesFieldNames.initialBudget, title: "Orçamento inicial" },
  {
    name: FinancesFieldNames.expensesUntilLastReport,
    title: "Valor executado até o período anterior",
  },
  {
    name: FinancesFieldNames.currentExpenses,
    title: "Valor executado no período",
  },
  {
    name: FinancesFieldNames.remanagedBudget,
    title: "Valor remanejado no período",
  },
  { name: FinancesFieldNames.currentBudget, title: "Valor disponível" },
];

const apiFields = [
  {
    name: "investment_item",
    fieldType: "apiselect",
    optionsSource: (context: Record<string, any>) =>
      `options/projectreportinvestments/${context?.projectId}/${context?.reportStartDate}`,
  },
];

function sortExpensesPerItem(
  items: InvestmentItem[],
  expenses: ReportExpense[]
) {
  const expensesPerItem = Object.fromEntries(
    items.map((item) => [item.value, 0])
  );
  expenses.forEach((expense) => {
    const {
      [FinancesFieldNames.investmentItem]: item,
      [FinancesFieldNames.amount]: amount,
    } = expense;
    expensesPerItem[item] += +amount;
  });
  return expensesPerItem;
}

function sortRemanagementsPerItem(
  items: InvestmentItem[],
  remanagements: ReportRemanagement[]
) {
  const remanagementsPerItem = Object.fromEntries(
    items.map((item) => [item.value, 0])
  );
  remanagements.forEach((remanagement) => {
    const {
      [FinancesFieldNames.previousItem]: previousItem,
      [FinancesFieldNames.newItem]: newItem,
      [FinancesFieldNames.remanagedBudget]: remanagedBudget,
    } = remanagement;
    remanagementsPerItem[previousItem] -= remanagedBudget;
    remanagementsPerItem[newItem] += +remanagedBudget;
  });
  return remanagementsPerItem;
}

function calculateInvestmentItemFinances(
  items: InvestmentItem[],
  expenses: { [item: string]: number },
  remanagements: { [item: string]: number }
): FinanceTableRow[] {
  const financeTable = items.map((item) => {
    const {
      value: investmentItemId,
      [FinancesFieldNames.expensesUntilLastReport]: expensesUntilLastReport,
    } = item;
    const currentExpenses = expenses[investmentItemId];
    const remanagedBudget = remanagements[investmentItemId];
    const currentBudget = expensesUntilLastReport - currentExpenses + remanagedBudget;

    return {
      ...item,
      [FinancesFieldNames.investmentItem]: investmentItemId,
      [FinancesFieldNames.currentExpenses]: currentExpenses,
      [FinancesFieldNames.remanagedBudget]: remanagedBudget,
      [FinancesFieldNames.currentBudget]: currentBudget,
    };
  });
  return financeTable;
}

function displayTableField(
  field: TableField,
  value: string | number,
  apiOptions: Record<
    string,
    { value: number | string; label: string; investment_type: string }[]
  >
) {
  if (field.name === FinancesFieldNames.investmentItem) {
    const selectedOption = apiOptions[FinanceApiOptions.investmentItem]?.find(
      (option) => String(option.value) === String(value)
    );
    return selectedOption?.label ?? value;
  }

  if (field.name === FinancesFieldNames.investmentType) {
    const selectedOption = apiOptions[FinanceApiOptions.investmentItem]?.find(
      (option) => String(option.value) === String(value)
    );
    return selectedOption?.investment_type ?? value;
  }
  return value;
}

function ReportFinanceTable({
  label,
  name,
  formValues,
  context,
}: ReportFinanceTableProps) {
  const remanagements = formValues["remanagements"];
  const expenses = formValues["finances"];
  const contextWithDate = useMemo(
    () => ({ ...context, reportStartDate: formValues?.["begin_date"] }),
    [context, formValues]
  );
  const { options: apiOptions }: { options: Record<string, any> } =
    useMultipleApiOptions(apiFields, contextWithDate);
  const items: InvestmentItem[] = useMemo(
    () => apiOptions?.["investment_item"] ?? [],
    [apiOptions]
  );

  const remanagementsPerItem = useMemo(
    () => sortRemanagementsPerItem(items, remanagements),
    [items, remanagements]
  );
  const expensesPerItem = useMemo(
    () => sortExpensesPerItem(items, expenses),
    [items, expenses]
  );

  const financeTableData = useMemo(
    () =>
      calculateInvestmentItemFinances(
        items,
        expensesPerItem,
        remanagementsPerItem
      ),
    [expensesPerItem, items, remanagementsPerItem]
  );

  return (
    <div className={styles.wrapper}>
      {label && (
        <div className={styles.label}>
          <label>{label}</label>
        </div>
      )}

      <div className={styles.tableWrapper}>
        <table id={name}>
          <thead>
            <tr>
              {TableFields.map((field) => (
                <th key={field.name}>{field.title}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {financeTableData.length === 0 ? (
              <tr>
                <td colSpan={TableFields.length}> Não há itens na lista </td>
              </tr>
            ) : (
              financeTableData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {TableFields.map((field) => (
                    <td key={field.name}>
                      <div className={styles.value}>
                        {displayTableField(field, row[field.name], apiOptions)}
                      </div>
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ReportFinanceTable;
