import { useCallback, useEffect, useMemo, useState } from "react";
import { infoLevels } from "../constants";
import { get } from "./APIutils";

export const useApiOptions = (url, onLoadOptions) => {
  const [options, setOptions] = useState(null);

  const loadData = useCallback(() => {
    get(url)
      .then((response) => response.data)
      .then((data) => {
        setOptions(data);
        onLoadOptions?.(data);
      })
      .catch((error) => console.log(error));
  }, [onLoadOptions, url]);

  useEffect(() => {
    if (options === null) {
      loadData();
    }
  }, []);

  return {
    options,
    loadData,
  };
};
const loadData = (url, fieldName) => {
  return get(url)
    .then((response) => [fieldName, response.data])
    .catch((error) => console.log(error));
};

export const useMultipleApiOptions = (fieldList, context, setToast) => {
  const [options, setOptions] = useState({});

  const reloadOptions = useCallback((fieldName) => {
    const url = fieldList
      .find((field) => field.name === fieldName)
      ?.optionsSource(context);
    if (!url) return;

    return loadData(url, fieldName)
      .then((optionEntries) => {
        var newOptions = {};
        newOptions[fieldName] = optionEntries[1];
        setOptions({...options, ...newOptions});
      })
      .catch((error) =>
        setToast?.({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: "Ocorreu um erro ao carregar opções para o formulário.",
        })
      );
  }, [options, context, fieldList]);

  useEffect(() => {
    Promise.all(
      fieldList.map((field) => {
        const url = field.optionsSource(context);
        return loadData(url, field.name);
      })
    )
      .then((optionEntries) => {
        const newOptions = Object.fromEntries(optionEntries);
        setOptions(newOptions);
      })
      .catch((error) =>
        setToast?.({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: "Ocorreu um erro ao carregar opções para o formulário.",
        })
      );
  }, [context, fieldList]);

  return { options, reloadOptions };
};
