import { Popover } from 'react-tiny-popover';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from "react-router-dom";

import styles from './styles.module.scss';

export default function MeatballMenu({
    projectId,
    tabList,
}){
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  
    return (
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'top', 'left', 'right']} // preferred positions by priority
        content={
        <div className={styles.PopoverOptions}>
          {tabList.map((tab) => (
            <Link tabIndex={-1} to={`/projects/${projectId}/${tab.name}`}>
              <button>
                {tab.title}
              </button>
            </Link>
          ))}
        </div>}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
          <ReactSVG src="/icons/meatballMenu.svg"/>
        </div>
      </Popover>
    
    )
  }