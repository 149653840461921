import { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { setRefreshToken, setToken, noAuthPost } from "../../../utils/APIutils";
import { Input, Button } from "../../Forms";
import { Toast } from "../../Toast";
import { infoLevels } from "../../../constants";
import { translateErrorMessage } from "../../../utils/ValidationUtils";

import styles from "./styles.module.scss";

export default function Login({ setReload }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = () => {
    noAuthPost("auth/login", {
      username,
      password,
    })
      .then((response) => {
        if (response.status !== 200) {
          const errorMessage = response.data;
          setError(
            Object.entries(errorMessage)
              .map(([key, value]) => `${key}: ${value}`)
              .join("\n")
          );
          setShowToast(true);
        } else {
          const data = response.data;
          setToken(data.access);
          setRefreshToken(data.refresh);
          setRedirect(true);
          setReload(true);
        }
      })
      .catch((err) => {
        setError(err);
        setShowToast(true);
      });
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.login} onSubmit={(e) => e.preventDefault()}>
        <Input
          inputType="text"
          name="username"
          id="username"
          label="Nome de usuário"
          value={username}
          setValue={setUsername}
          editable={true}
        />
        <Input
          inputType="password"
          name="password"
          id="password"
          label="Senha"
          value={password}
          setValue={setPassword}
          editable={true}
        />
        <div className={styles.row}>
          <Button
            styleString="filled"
            className={styles.submit}
            onClick={handleLogin}
          >
            Fazer login
          </Button>
          <Link to="/signup" className={styles.link}>
            <Button styleString="outlined" className={styles.signup}>
              Criar conta
            </Button>
          </Link>
        </div>
        <div>
          {/*TODO DEIXAR BONITO*/}
          <Link to="/password-reset-request/" className={styles.link}>
            <a>Esqueci minha senha</a>
          </Link>
        </div>
      </form>
      <Toast
        show={showToast}
        setShow={setShowToast}
        title="Erro de autenticação"
        infoLevel={infoLevels.ERROR}
      >
        {translateErrorMessage(
          error
        )}
      </Toast>
      {redirect && <Redirect to="/" />}
    </div>
  );
}
