import styles from "./styles.module.scss";

function currencyFormat(num) {
  return num.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
}

export default function FormTable({ label, name, tableFields, value }) {
  return (
    <>
      <div className={styles.wrapper}>
        {label && (
          <div className={styles.label}>
            <label>{label}</label>
          </div>
        )}
        <div className={styles.tableWrapper}>
          <table id={name}>
            {tableFields?.map((field) => (
              <tr key={field.name}>
                <th> {field.title} </th>
                <td>
                  {value[field.name] && currencyFormat(value[field.name])}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </>
  );
}
