import { ReactSVG } from 'react-svg';
import { getRange } from '../../../utils/paginationUtils';
import styles from './styles.module.scss';


export default function PaginationControls({currentPage, totalPages, setPage}){

  const pageRange = getRange(currentPage, 4).map((pageIndex) => pageIndex+1);

  const nextPage = () => {
    setPage(currentPage + 1);
  }

  const prevPage = () => {
    setPage(currentPage - 1);
  }


  return (
    <div className={styles.paginationControls}>
      <button
        className={styles.prev}
        onClick={() => {
          prevPage();
        }}
        disabled={currentPage <= 0}
      >
        <ReactSVG src="/icons/chevron_left_24px.svg" />
      </button>

      {pageRange.map((pageIndex) => {
        return pageIndex < 0 || pageIndex >= totalPages ? (
          <div className={styles.filler}></div>
        ) : (
          <button
            className={[
              styles.pageButton,
              ...(pageIndex === currentPage ? [styles.selected] : [])
            ].join(" ")}
            onClick={() => setPage(pageIndex)}
          >
            {pageIndex + 1}
          </button>
        )
      })}

      <button
        className={styles.next}
        onClick={() => {
          nextPage();
        }}
        disabled={currentPage >= totalPages -1}
      >
        <ReactSVG src="/icons/chevron_right_24px.svg" />
      </button>
    </div>
  )
}