export const formStatus = {
  Incompleto: 1,
  Desatualizado: 2,
  "Análise pendente": 3,
  Editável: 4,
  Aprovado: 5,
};

export const projectStatusDisplay = Object.fromEntries([
  ["ACTIVE", "Ativo"],
  ["FINISHED", "Finalizado"],
  ["CANCELLED", "Cancelado"],
  ["FUNDING", "Em captação"],
  ["PARALYZED", "Paralisado"],
  ["PENDING", "Pendente"],
  ["RESOURCE_BLOCKED", "Recurso não liberado"],
]);

export function toLabelValuePair(options) {
  return Object.entries(options).map(([label, value]) => ({ label, value }));
}

export function getFormStatusLabel(statusInteger) {
  return Object.entries(formStatus).find(
    ([label, value]) => value === statusInteger
  )?.[0];
}

export function translateBool(bool) {
  if(bool == 'false') {
    return 'Não';
  } else if(bool == 'true') {
    return 'Sim';
  }
}

export function isAnyStatus(currentFormStatus, ...acceptedStatuses) {
  return Boolean(
    acceptedStatuses.find(
      (acceptedStatus) => String(acceptedStatus) === String(currentFormStatus)
    )
  );
}
