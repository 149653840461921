import { formStatus, getFormStatusLabel } from "../../../constants/options";

import styles from "./styles.module.scss";

const statusStyleString = {
  [null]: styles.blank,
  1: styles.incomplete,
  2: styles.outdated,
  3: styles.pending,
  4: styles.editable,
  5: styles.approved,
};

export default function StatusLabel({ status }) {
  return (
    <div className={styles.statusLabel}>
      <span>Status dos dados:</span>
      <StatusTag status={status} />
    </div>
  );
}

export function StatusTag({ status }) {
  return (
    <div className={[styles.statusTag, statusStyleString[status]].join(" ")}>
      {status === null ? "Em branco" : getFormStatusLabel(status)}
    </div>
  );
}
