import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { getFieldStandardValue, Question } from "../../FormPage/Question";
import { ModalLayout } from '../../Modal';

import styles from './styles.module.scss';
import { ReactSVG } from 'react-svg';

export default function FilterForm({form, filters, setFilters}){
  const [initialForm, setInitialForm] = useState(() => Object.fromEntries(
    form.map(({name, fieldType}) => [name, getFieldStandardValue(fieldType)])
  ));
  useEffect(() => {
    setInitialForm(Object.fromEntries(form.map(({name, fieldType}) => [name, getFieldStandardValue(fieldType)])));
  }, [form]);

  const [showModal, setShowModal] = useState(false);

  return (
    <Formik
      initialValues={initialForm}
      onSubmit={(values) => {
        setFilters(values);
        setShowModal(false);
      }}
      enableReinitialize
    >
      {(formikProps) =>
        <>
          <div className={styles.filtersWrapper}>
            <div className={styles.filterControls}>
              <button type="button" onClick={() => setShowModal(true)}>
                <span>
                  Filtros
                </span>
                <ReactSVG src="/icons/filter_list_24px.svg"/>
              </button>
              {!!Object.values(filters).some(value => !!value) &&
                <button
                  type="button"
                  onClick={() => {
                    formikProps.resetForm();
                    formikProps.submitForm()
                  }}
                >
                  <span>
                    Limpar
                  </span>
                  <ReactSVG src="/icons/remove_circle_24px.svg"/>
                </button>
              }
            </div>
            <ul className={styles.filtersList}>
              {Object.entries(filters).filter(([name, value]) => !!value).map(([name, value]) =>
                <li className={styles.filterItem}>
                  <span>
                    {`${form.find(({name: fieldName}) => fieldName === name)?.title}: ${value}`}
                  </span>
                  <button
                    onClick={() => {
                      formikProps.setFieldValue(name, "");
                      formikProps.submitForm();
                    }}
                  >
                    <ReactSVG src="/icons/close_24px.svg"/>
                  </button>
                </li>
              )}
            </ul>
          </div>

          <ModalLayout show={showModal} setShow={setShowModal} title={"Filtros"}>
            <Form>
              {form.map((field) =>
                <div className={styles.fieldWrapper}>
                  <Question
                    {...field}
                    value={formikProps.values[field.name]}
                    setValue={(value) => formikProps.setFieldValue(field.name, value)}
                  />
                </div>
              )}
              <div className={styles.filterModalControls}>
                <button type="submit">
                  <span>
                    Aplicar
                  </span>
                  <ReactSVG src="/icons/check_24px.svg"/>
                </button>
                
                <button type="button" onClick={() => {
                    formikProps.resetForm();
                    formikProps.submitForm();
                  }}
                >
                  <span>
                    Limpar
                  </span>
                  <ReactSVG src="/icons/remove_circle_24px.svg"/>
                </button>
              </div>
            </Form>
          </ModalLayout>
        </>
      }
    </Formik>
  )
}