import { useState, useEffect, useMemo } from "react";
import { TableInsertModal } from "./TableInsertModal";
import { Loading } from "../../Loading";
import { ReactSVG } from "react-svg";
import {
  collectTableApiSelectOptionFields,
  getInitialFormValues,
} from "../../../utils/useForm";
import { useFileContentUrl, hasImageExtension } from "../../../utils/fileUtils";

import styles from "./styles.module.scss";
import { FileActions } from "../FileInput/FileInput";
import { useMultipleApiOptions } from "../../../utils/useApiOptions";

function FileFieldValueDisplay({ value }) {
  const fileSource = useFileContentUrl(value?.content);
  return value ? (
    <div className={styles.imagePreviewWrapper}>
      <div className={styles.previewAndName}>
        {hasImageExtension(value.file_name) ? (
          <img
            className={styles.imagePreview}
            id={`table-img-${value.file_name}`}
            src={fileSource}
            alt={`${value.file_name}`}
          />
        ) : (
          <ReactSVG src="/icons/insert_drive_file_24px.svg" />
        )}
        <label htmlFor={`table-img-${value.file_name}`}>
          {value.file_name}
        </label>
      </div>
      <div className={styles.filePreviewControls}>
        <FileActions fileName={value.file_name} fileUrl={fileSource} short />
      </div>
    </div>
  ) : (
    "--"
  );
}

function FieldValueDisplay({ value, field, formValues, context, apiOptions }) {
  if (field.fieldType === "file") {
    if (value === null) {
      return "--";
    }
    return <FileFieldValueDisplay value={value} />;
  }

  if (field.fieldType === "select") {
    const selectedOption = field.options.find(
      (option) => String(option.value) === String(value)
    );
    if (field.alt && !selectedOption)
      return (
        <FieldValueDisplay
          value={value}
          field={field.alt.field}
          formValues={formValues}
          context={context}
          apiOptions={apiOptions}
        />
      );

    return selectedOption?.label ?? "--";
  }

  if (field.fieldType === "apiselect") {
    const selectedOption = apiOptions[field.name]?.find(
      (option) => String(option.value) === String(value)
    );
    if (field.alt && !selectedOption)
      return (
        <FieldValueDisplay
          value={value}
          field={field.alt.field}
          formValues={formValues}
          context={context}
          apiOptions={apiOptions}
        />
      );

    return selectedOption?.label ?? "--";
  }

  if (field.fieldType === "lambda") {
    if (field.selectValues && field.determineValue) {
      const lambdaSelectedValues = field.selectValues(
        formValues,
        context,
        apiOptions
      );

      const lambdaDeterminedValue = field.determineValue(
        lambdaSelectedValues,
        context,
        apiOptions
      );
      return lambdaDeterminedValue ?? "--";
    }

    return value ?? "--";
  }
  const valueString = String(value ?? "--");
  if (valueString === "true") {
    return (
      <div className={styles.booleanTrueIcon}>
        <ReactSVG src="/icons/check_box_24px.svg" />
      </div>
    );
  } else if (valueString === "false") {
    return (
      <div className={styles.booleanFalseIcon}>
        <ReactSVG src="/icons/close_24px.svg" />
      </div>
    );
  }
  return valueString || "--";
}

function isNotHidden(fieldData) {
  return fieldData.fieldType !== "input" || fieldData.inputType !== "hidden";
}

export default function FormTable({
  title,
  label,
  id,
  error,
  placeholder,
  insertable,
  maxItems,
  minItems,
  name,
  tableFields,
  value,
  formValues,
  setValue,
  editable,
  removable,
  context,
  sortingFunction,
}) {
  const [showModal, setShowModal] = useState(false);
  const [editingRowKey, setEditingRowKey] = useState(null);

  const apiSelectFields = useMemo(
    () => collectTableApiSelectOptionFields(tableFields),
    [tableFields]
  );
  const sortedValue = useMemo(() => sortingFunction ? sortingFunction(value) : value, [value, sortingFunction]);
  const { options: apiOptions, reloadOptions } = useMultipleApiOptions(
    apiSelectFields,
    context
  );

  useEffect(() => {
    if (value?.length === 0 && minItems !== undefined) {
      const emptyItem = getInitialFormValues({ FIELDS: tableFields });
      setValue([
        ...value,
        ...Array.from({
          length: value?.length >= minItems ? 0 : minItems - value,
        }).map(() => emptyItem),
      ]);
    }
  }, [minItems, setValue, tableFields, value]);

  const exibitionTableFields = useMemo(() => {
    return tableFields?.filter(isNotHidden) ?? [];
  }, [tableFields]);

  return (
    <>
      <div className={styles.wrapper}>
        {label && (
          <div className={styles.label}>
            <label>{label}</label>
          </div>
        )}
        <div className={styles.tableWrapper}>
          <table id={name}>
            <thead>
              <tr>
                {exibitionTableFields?.map((field) => (
                  <th key={field.name}> {field.title} </th>
                ))}
                <th className={styles.rowControls}>
                  {editable !== false &&
                    insertable !== false &&
                    (maxItems === undefined || value?.length < maxItems) && (
                      <div>
                        <button
                          className={styles.addButton}
                          type="button"
                          onClick={() => {
                            setEditingRowKey(null);
                            setShowModal(true);
                          }}
                        >
                          <ReactSVG
                            className={styles.deleteIcon}
                            src="/icons/add_circle_24px.svg"
                          />
                        </button>
                      </div>
                    )}
                </th>
              </tr>
            </thead>
            <tbody>
              {value !== null ? (
                value?.length === 0 ? (
                  <tr>
                    <td colSpan={exibitionTableFields?.length}>
                      Não há itens na lista
                    </td>
                  </tr>
                ) : (
                  value?.map?.((item, rowIndex) => (
                    <tr key={rowIndex}>
                      {exibitionTableFields?.map((field, index) => {
                        const rowContext = { ...context, index: rowIndex };
                        return (
                          <td key={field.name}>
                            <div className={styles.value}>
                              <FieldValueDisplay
                                value={item[field.name]}
                                field={field}
                                formValues={formValues}
                                context={rowContext}
                                apiOptions={apiOptions}
                              />
                            </div>
                          </td>
                        );
                      })}
                      <td className={styles.rowControls}>
                        {editable !== false && 
                          removable !== false && (
                          <div>
                            <button
                              type="button"
                              className={styles.editButton}
                              onClick={() => {
                                setEditingRowKey(rowIndex);
                                setShowModal(true);
                              }}
                            >
                              <ReactSVG
                                className={styles.editIcon}
                                src="/icons/create_24px.svg"
                              />
                            </button>
                            {(minItems === undefined ||
                              value?.length > minItems) && (
                              <button
                                type="button"
                                className={styles.deleteButton}
                                onClick={() => {
                                  value.splice(rowIndex, 1);
                                  setValue([...value]);
                                }}
                              >
                                <ReactSVG
                                  className={styles.deleteIcon}
                                  src="/icons/remove_circle_24px.svg"
                                />
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                )
              ) : (
                <Loading />
              )}
            </tbody>
          </table>
        </div>
      </div>
      <TableInsertModal
        title={title}
        formValues={formValues}
        tableName={name}
        writeRow={(rowValue) => {
          if (editingRowKey !== null) {
            setValue(
              value.map((item, index) =>
                index === editingRowKey ? rowValue : item
              )
            );
          } else {
            setValue([...value, rowValue]);
          }
        }}
        data={value}
        context={context}
        editingRowKey={editingRowKey}
        show={showModal}
        setShow={(value) => {
          setShowModal(value);
          if (!value) {
            setEditingRowKey(null);
          }
        }}
        apiOptions={apiOptions}
        reloadOptions={reloadOptions}
        tableFields={tableFields}
        onLoadOptions={(key, options) => {}}
      />
    </>
  );
}
