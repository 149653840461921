import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ReactSVG } from "react-svg";
import { infoLevels } from "../../constants";
import { Loading } from "../Loading";

import styles from "./styles.module.scss";

const initialToastState = {
  show: false,
  setShow: null,
  title: "Aviso",
  children: null,
  infoLevel: infoLevels.INFO,
  loading: false,
};

export const useToast = () => {
  const [toastState, _setToastState] = useState(initialToastState);

  const setToastState = useCallback(
    (newState) => {
      return _setToastState({ ...toastState, ...newState });
    },
    [toastState]
  );

  toastState.setShow = (newShow) => setToastState({ show: newShow });

  return [toastState, setToastState];
};

export default function Toast({
  show,
  setShow,
  title,
  children,
  infoLevel,
  loading,
}) {
  const infoLevelClassName = infoLevel ? styles[infoLevel.toLowerCase()] : "";
  const toastContainerNode = document.getElementById("toastContainer");
  return show && toastContainerNode
    ? createPortal(
        <div className={styles.wrapper}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className={`${styles.header} ${infoLevelClassName}`}>
                {title && <h3>{title}</h3>}
                {!loading && (
                  <button
                    onClick={() => setShow(false)}
                    className={styles.close}
                  >
                    <ReactSVG src="/icons/close_24px.svg" />
                  </button>
                )}
              </div>
              <div className={styles.content}>{children}</div>
            </>
          )}
        </div>,
        toastContainerNode,
        `${title}-toast`
      )
    : null;
}
