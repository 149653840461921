import Error from '../Input/Error';

import styles from './styles.module.scss';

export default function TextArea({
  name,
  id,
  value,
  setValue,
  placeholder,
  label,
  editable,
  context,
  error
}){
  return (
    <div className={`inputContainer ${styles.inputContainer}`}>
      {label &&
        <div className={styles.label}>
          <label>
            {label}
          </label>
        </div>
      }
      <textarea
        id={id}
        tabIndex={editable ? undefined : -1}
        name={name}
        placeholder={placeholder}
        value={value}
        readOnly={editable === false ? true : undefined}
        onChange={(evt) => setValue(evt.target.value)}
      />
      {error && <Error error={error} />}
    </div>
  )
}