import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { NotificationList } from "./NotificationList";
import { checkToken } from "../../utils/APIutils";
import { Loading } from "../Loading";
import { isUserPhi } from "../../utils/permissionUtils";
import { OrgRoadmap } from "./OrgRoadmap";

import styles from "./styles.module.scss";


import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';

import { Button } from "../Forms";

// Font.register({ family: 'Open Sans', fonts: [
//   { src: 'https://fonts.cdnfonts.com/css/open-sans' }
//  ]});




export default function Landing({ userData, userGroup }) {
  const [tokenCheck, setTokenCheck] = useState(null);

  useEffect(() => {
    checkToken()
      .then((response) => {
        setTokenCheck(true);
      })
      .catch((error) => {
        setTokenCheck(false);
      });
  }, []);

  return tokenCheck !== null ? (
    tokenCheck === false ? (
      <Redirect to="/login" />
    ) : (
      <main className={styles.wrapper}>
        <div className={styles.leftColumn}>
          <section className={styles.greetings}>
            <h2>Boas vindas ao SPG!</h2>
          </section>
          {!userData || isUserPhi(userData) ? <div></div> : <OrgRoadmap />}
        </div>
        <div className={styles.rightColumn}>
          <NotificationList />
        </div>
      </main>
    )
  ) : (
    <div className={styles.loadingWrapper}>
      <Loading />
    </div>
  );
}
