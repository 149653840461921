import { Switch, Route, Link } from "react-router-dom";
import { ProjectForm, ProjectList } from ".";
import { isUserInGroups } from "../../utils/permissionUtils";
import { ConditionsEnforcer } from "../Auth/ConditionsEnforcer";
import { Button } from "../Forms";
import { ProjectName } from "./ProjectName";
import { ProjectPlanningForm } from "./ProjectPlanning";
import { ProjectReports } from "./ProjectReports";
import { ProjectFinance } from "./ProjectFinance";
import { ProjectRemanagements } from "./ProjectRemanagements";
import { ProjectTransfers } from "./ProjectTransfers";

import styles from "./styles.module.scss";

export default function ProjectPage({ userData }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.pageHeader}>
        <Switch>
          <Route
            path="/projects/:projectId/finance"
            render={(props) => (
              <>
                <ProjectName
                  projectId={props.match.params.projectId}
                  append={" - Financeiro"}
                />
              </>
            )}
          />
          <Route
            path="/projects/:projectId/remanagements"
            render={(props) => (
              <>
                <ProjectName
                  projectId={props.match.params.projectId}
                  append={" - Remanejamentos"}
                />
              </>
            )}
          />
          <Route
            path="/projects/:projectId/transfers"
            render={(props) => (
              <>
                <ProjectName
                  projectId={props.match.params.projectId}
                  append={" - Repasses"}
                />
              </>
            )}
          />
          <Route
            path="/projects/:projectId/reports"
            render={(props) => (
              <>
                <ProjectName
                  projectId={props.match.params.projectId}
                  append={" - Relatórios"}
                />
              </>
            )}
          />
          <Route
            path="/projects/:projectId"
            render={(props) => (
              <ProjectName projectId={props.match.params.projectId} />
            )}
          />
          <Route path="/projects">
            <h2>Projetos</h2>
          </Route>
        </Switch>

        <Switch>
          <Route
            path={"/projects/:projectId/transfers/:slug"}
            render={(props) => (
              <Link
                tabIndex={-1}
                to={`/projects/${props.match.params.projectId}/transfers`}
              >
                <Button styleString="outlined">Voltar</Button>
              </Link>
            )}
          />
          <Route
            path={"/projects/:projectId/remanagements/:slug"}
            render={(props) => (
              <Link
                tabIndex={-1}
                to={`/projects/${props.match.params.projectId}/remanagements`}
              >
                <Button styleString="outlined">Voltar</Button>
              </Link>
            )}
          />
          <Route
            path={"/projects/:projectId/reports/:slug"}
            render={(props) => (
              <Link
                tabIndex={-1}
                to={`/projects/${props.match.params.projectId}/reports`}
              >
                <Button styleString="outlined">Voltar</Button>
              </Link>
            )}
          />
          <Route path={"/projects/:slug"}>
            <Link tabIndex={-1} to={"/projects"}>
              <Button styleString="outlined">Voltar</Button>
            </Link>
          </Route>
          <Route exact path={"/projects"}>
            <Link tabIndex={-1} to={"/projects/new"}>
              <Button styleString="filled">Novo</Button>
            </Link>
          </Route>
        </Switch>
      </div>

      <Switch>
        <Route
          path="/projects/:projectId/finance"
          render={(props) => (
            <ConditionsEnforcer
              conditions={[isUserInGroups(userData, [1, 2, 3, 4, 5, 7])]}
              fallbackTo={"/orgs"}
            >
              <ProjectFinance projectId={props.match.params.projectId} />
            </ConditionsEnforcer>
          )}
        />
        <Route
          path="/projects/:projectId/transfers"
          render={(props) => (
            <ConditionsEnforcer
              conditions={[isUserInGroups(userData, [1, 2, 3, 4, 5, 7])]}
              fallbackTo={"/orgs"}
            >
              <ProjectTransfers userData={userData} />
            </ConditionsEnforcer>
          )}
        />
        <Route
          path="/projects/:projectId/remanagements"
          render={(props) => (
            <ConditionsEnforcer
              conditions={[isUserInGroups(userData, [1, 2, 3, 4, 5, 7])]}
              fallbackTo={"/orgs"}
            >
              <ProjectRemanagements userData={userData} />
            </ConditionsEnforcer>
          )}
        />
        <Route
          path="/projects/:projectId/reports"
          render={(props) => (
            <ConditionsEnforcer
              conditions={[isUserInGroups(userData, [1, 2, 3, 4, 5, 7])]}
              fallbackTo={"/orgs"}
            >
              <ProjectReports userData={userData} />
            </ConditionsEnforcer>
          )}
        />
        <Route
          path="/projects/:projectId/planning"
          render={(props) => (
            <ConditionsEnforcer
              conditions={[isUserInGroups(userData, [1, 2, 3, 4, 5, 7])]}
              fallbackTo={"/orgs"}
            >
              <ProjectPlanningForm
                projectId={props.match.params.projectId}
                userData={userData}
                editable={true}
              />
            </ConditionsEnforcer>
          )}
        />
        <Route
          exact
          path="/projects/new"
          render={() => (
            <ConditionsEnforcer
              conditions={[isUserInGroups(userData, [1, 2, 3, 4, 5, 7])]}
            >
              <ProjectForm projectId={null} userData={userData} />
            </ConditionsEnforcer>
          )}
        />
        <Route
          exact
          path="/projects/:projectId"
          render={(props) => (
            <ConditionsEnforcer
              conditions={[isUserInGroups(userData, [1, 2, 3, 4, 5, 7])]}
              fallbackTo={"/orgs"}
            >
              <ProjectForm
                projectId={props.match.params.projectId}
                userData={userData}
              />
            </ConditionsEnforcer>
          )}
        />
        <Route exact path="/projects">
          <ConditionsEnforcer
            conditions={[isUserInGroups(userData, [1, 2, 3, 4, 5, 7])]}
            fallbackTo={"/orgs"}
          >
            <ProjectList />
          </ConditionsEnforcer>
        </Route>
      </Switch>
    </div>
  );
}
