import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MeatballMenu } from "../../MeatballMenu";
import { projectStatusDisplay } from "../../../constants/options";
import { get } from "../../../utils/APIutils";
import { getInitialFormValues } from "../../../utils/useForm";
import { FilterForm } from "../../Filtering/FilterForm";
import { PaginationControls } from "../../Pagination";
import { Toast } from "../../Toast";

import styles from "./styles.module.scss";
import { StatusTag } from "../../FormPage/StatusLabel";
import { localizeDateString } from "../../../utils/dateUtils";

const filtersForm = [
  {
    name: "name__icontains",
    title: "Nome",
    fieldType: "input",
    inputType: "search",
  },
  {
    name: "analyst__username__icontains",
    title: "Analista",
    fieldType: "input",
    inputType: "search",
  },
  {
    name: "investor__icontains",
    title: "Investidor",
    fieldType: "input",
    inputType: "search",
  },
  {
    name: "org__org_name__icontains",
    title: "Organização",
    fieldType: "input",
    inputType: "search",
  },
  {
    name: "activity_area__icontains",
    title: "Área",
    fieldType: "input",
    inputType: "search",
  },
  {
    name: "status__icontains",
    title: "Status do Projeto",
    fieldType: "input",
    inputType: "search",
  },
];

const projectTabs = [
  {
    name: "finance",
    title: "Financeiro",
  },
  {
    name: "planning",
    title: "Planejamento",
  },
  {
    name: "transfers",
    title: "Repasses",
  },
  {
    name: "reports",
    title: "Relatórios",
  },
  {
    name: "remanagements",
    title: "Remanejamentos",
  },
];

const initialFilters = getInitialFormValues({ DATA: filtersForm });

const formatProjectDates = (projectData) => {
  const startDateString = localizeDateString(projectData.start_date);
  const endDateString = localizeDateString(projectData.end_date);
  return `${startDateString} a ${endDateString}`;
};

export default function ProjectList({ orgId }) {
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);

  const loadProjects = () => {
    const notBlankFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => !!value)
    );
    setLoading(true);
    get("projects", { params: { page, ...notBlankFilters } })
      .then((response) => response.data)
      .then((data) => {
        setProjects(data.items);
        setPageCount(data.pageCount);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (projects === null) {
      loadProjects();
    }
  }, []);

  useEffect(() => {
    if (projects !== null) {
      loadProjects();
    }
  }, [page, filters]);

  return (
    <>
      <div className={styles.listControls}>
        <PaginationControls
          currentPage={page}
          totalPages={pageCount}
          setPage={setPage}
        />

        <FilterForm
          form={filtersForm}
          filters={filters}
          setFilters={(values) => setFilters(values)}
        />
      </div>
      <div className={styles.wrapper}>
        <section className={styles.projectListSection}>
          <Toast show={loading} loading={loading} />
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Área</th>
                <th>Período</th>
                <th>Organização</th>
                <th>Analista</th>
                <th>Investidor</th>
                <th>Status do projeto</th>
                <th>Status dos dados</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {projects?.map?.((project) => (
                <tr key={project.id}>
                  <td>{project.name}</td>
                  <td>{project.activity_area}</td>
                  <td>{formatProjectDates(project)}</td>
                  <td>{project.org}</td>
                  <td>{project.analyst}</td>
                  <td>{project.investor}</td>
                  <td>{projectStatusDisplay[project.status]}</td>
                  <td>
                    <StatusTag status={project.form_status} />
                  </td>
                  <td>
                    <div className={styles.projectOptions}>
                      <button>
                        <MeatballMenu
                          projectId={project.id}
                          tabList={projectTabs}
                        ></MeatballMenu>
                      </button>
                      <Link tabIndex={-1} to={`/projects/${project.id}`}>
                        <button>Dados</button>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </div>
    </>
  );
}
