import { formStatus, toLabelValuePair } from "./options";
import * as Yup from "yup";
import { validateFileSizeRestriction } from "../utils/ValidationUtils";

const MAX_FILE_SIZE = 2 ** 22; // 4MB
const pictureFileSize = 2 ** 23; // 8MB
const executionSectionText =
  "Analise o desempenho do projeto, indicando os aspectos que facilitaram e os que prejudicaram o desempenho. Seja imparcial nas respostas, de forma que, em caso de dificuldades na execução, a equipe Phi possa apoiar para auxiliar na retomada do projeto ao escopo inicial. Nosso objetivo não é julgar a resposta e sim colaborar para melhorar!";

const itemToOption = (item) => ({ label: item, value: item });

export const projectsReportFormPages = {
  DATA: {
    label: "Dados do Relatório",
    order: 1,
  },
  PUBLIC: {
    label: "Público Atingido",
    order: 2,
  },
  ACTIVITIES: {
    label: "Atividades",
    order: 3,
  },
  INDICATORS: {
    label: "Indicadores",
    order: 4,
  },
  MANAGEMENT: {
    label: "Gestão",
    order: 5,
  },
  EXECUTION: {
    label: "Execução",
    order: 6,
  },
  FINANCES: {
    label: "Financeiro",
    order: 7,
  },
  STORY: {
    label: "História Inspiradora",
    order: 8,
  },
  MEDIA: {
    label: "Material de Divulgação",
    order: 9,
  },
  LAW_INCENTIVES: {
    label: "Projetos Incentivados",
    order: 10,
  },
  OBSERVATIONS: {
    label: "Observações",
    order: 11,
  },
};

export const projectReportForm = {
  DATA: [
    {
      title: "LGPD",
      text: "Declaro que todas as informações inseridas são verdadeiras e que a organização possui autorização para uso e compartilhamento das imagens inseridas neste documento, podendo o INSTITUTO PHI - PHILANTROPIA INTELIGENTE ('INSTITUTO PHI'), utilizá-las em suas campanhas promocionais e institucionais, sem que nada haja a ser reclamado a título de direitos conexos.",
      tooltip: "",
      label: "",
      fieldType: "radio",
      name: "lgpd",
      isBoolean: true,
      validationSchema: Yup.boolean().nullable().required("(Campo obrigatório)")
        .notOneOf([false], "A autorização é necessária para prosseguir"),
      columns: "1/4",
    },
    {
      title: "Status",
      name: "form_status",
      fieldType: "select",
      options: toLabelValuePair(formStatus),
      groups: [1, 2, 3],
      validationSchema: Yup.number().required("Campo obrigatório"),
      columns: "1/4",
    },
    {
      title: "Data de envio do relatório para o Phi",
      name: "sent_to_phi_at",
      fieldType: "input",
      inputType: "date",
      columns: "1/2",
    },
    {
      title: "Data de envio do relatório para o investidor",
      name: "sent_to_investor_at",
      fieldType: "input",
      inputType: "date",
      groups: [1, 2, 3, 7],
      columns: "1/2",
    },
    {
      title: "Data de aprovação do relatório",
      name: "approved_at",
      fieldType: "input",
      inputType: "date",
      columns: "1/2",
    },
    //
    //   Estes campos são obrigatórios mas foram removidos. Vou apagar estes comentários assim que tiver a confirmação de que
    // será possível preenchê-los automaticamente no backend com base nos campos novos
    //
    // {
    //   title: "Início do período do relatório",
    //   name: "begin_date",
    //   fieldType: "input",
    //   inputType: "date",
    //   validationSchema: Yup.date().required("Campo obrigatório"),
    //   columns: "1/2",
    // },
    // {
    //   title: "Término do período do relatório",
    //   name: "end_date",
    //   fieldType: "input",
    //   inputType: "date",
    //   validationSchema: Yup.date()
    //     .min(
    //       Yup.ref("begin_date"),
    //       "Data de término não pode vir antes da data de início"
    //     )
    //     .required("Campo obrigatório"),
    //   columns: "2/4",
    // },
  ],
  PUBLIC: [
    {
      title: "Quantidade de participantes",
      name: "total_participants",
      fieldType: "input",
      inputType: "number",
      validationSchema: Yup.number()
        .nullable()
        .moreThan(-1, "Apenas números positivos"),
      columns: "1/2",
    },
    {
      title: "Perfil dos participantes",
      name: "participants_profile",
      fieldType: "input",
      inputType: "text",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "2/3",
    },
    {
      title: "Quantidade de inscritos no período",
      name: "project_period_participants",
      fieldType: "input",
      inputType: "number",
      validationSchema: Yup.number()
        .nullable()
        .moreThan(-1, "Apenas números positivos"),
      columns: "3/4",
    },
    {
      title: "Processo de seleção",
      text: "Houve alguma modificação no processo de inscrição/seleção do público?",
      name: "has_participant_inscription_method_been_modified",
      fieldType: "radio",
      isBoolean: true,
      columns: "1/2",
    },
    {
      text: "Motivo da modificação",
      name: "inscription_method_modification_reason",
      fieldType: "input",
      inputType: "text",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "2/3",
    },
    {
      text: "Novo método de inscrição",
      name: "new_inscription_method",
      fieldType: "input",
      inputType: "text",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "3/4",
    },
  ],
  ACTIVITIES: [
    {
      title: "Atividades realizadas no período",
      text: "Preencha as colunas em branco com o estado da execução das atividades e comentários sobre ela.",
      name: "activities",
      fieldType: "table",
      removable: false,
      tableFields: [
        {
          title: "Data planejada para execução",
          name: "planned_month",
          fieldType: "lambda",
          inputType: "month",
          validationSchema: Yup.string().required("Campo obrigatório"),
          selectValues: (formValues, context, apiOptions) => {
            return formValues?.["activities"]?.[context.index];
          },
          determineValue: (selectedValues, context, apiOptions) => {
            const options = apiOptions?.["project_activity"];
            const selectedOption = options?.find(
              (item) =>
                String(item?.value) ===
                String(selectedValues?.["project_activity"])
            );
            return selectedOption?.["planned_month"];
          },
        },
        {
          title: "Data efetiva da execução",
          name: "executed_at_month",
          fieldType: "input",
          inputType: "month",
        },
        {
          title: "Atividade",
          name: "project_activity",
          fieldType: "apiselect",
          optionsSource: (context) =>
            `options/projectactivities/${context?.projectId}`,
          validationSchema: Yup.string().required("Campo obrigatório"),
        },
        {
          title: "Execução da atividade",
          name: "activity_execution",
          fieldType: "radio",
          options: [
            "Não executada",
            "Executada parcialmente",
            "Executada totalmente",
          ].map(itemToOption),
          validationSchema: Yup.string().required("Campo obrigatório"),
        },
        {
          title: "Comentários acerca da avaliação",
          name: "evaluation_comments",
          fieldType: "textarea",
        },
      ],
      columns: "1/4",
    },
    {
      title: "Atividades extras",
      text: "Se for o caso, insira atividades desenvolvidas no projeto apoiado e que não estavam previstas no planejamento.",
      name: "extra_activities",
      fieldType: "table",
      maxItems: 5,
      tableFields: [
        {
          title: "Data da execução",
          name: "executed_at_month",
          fieldType: "input",
          inputType: "month",
        },
        {
          title: "Atividade",
          name: "activity_description",
          fieldType: "textarea",
          validationSchema: Yup.string().max(500, "Limite de 500 caracteres atingido"),
          validationSchema: Yup.string().required("Campo obrigatório"),
        },
        {
          title: "Execução da atividade",
          name: "activity_execution",
          fieldType: "radio",
          options: [
            "Não executada",
            "Executada parcialmente",
            "Executada totalmente",
          ].map(itemToOption),
          validationSchema: Yup.string().required("Campo obrigatório"),
        },
        {
          title: "Comentários acerca da avaliação",
          name: "evaluation_comments",
          fieldType: "textarea",
          validationSchema: Yup.string().max(500, "Limite de 500 caracteres atingido"),
        },
      ],
      columns: "1/4",
    },
    {
      title:
        "Atividades desenvolvidas pela organização não contempladas por este apoio",
      text: "Descreva até 4 atividades desenvolvidas pela instituição no período e que não possuam ligação com o projeto apoiado, mas são relevantes para sabermos mais sobre o que a organização faz",
      name: "not_backed_activities",
      maxItems: 4,
      fieldType: "table",
      tableFields: [
        {
          title: "Descrição",
          name: "activity_description",
          fieldType: "textarea",
          validationSchema: Yup.string().max(500, "Limite de 500 caracteres atingido"),
        },
      ],
      columns: "1/4",
    },
    {
      title: "Próximas atividades",
      text: "Quais são as cinco principais atividades previstas para o próximo período de execução do projeto, que utilizarão recursos do projeto?",
      minItems: 5,
      insertable: false,
      name: "next_activities",
      fieldType: "table",
      tableFields: [
        {
          title: "Descrição",
          name: "activity_description",
          fieldType: "textarea",
          validationSchema: Yup.string().max(500, "Limite de 500 caracteres atingido"),
        },
      ],
      columns: "1/4",
    },
  ],
  INDICATORS: [
    {
      title: "Indicadores e metas",
      name: "indicators",
      fieldType: "table",
      tableFields: [
        {
          title: "Indicador",
          name: "indicator",
          fieldType: "apiselect",
          optionsSource: (context) =>
            `options/projectindicators/${context?.projectId}`,
          validationSchema: Yup.string().required("Campo obrigatório"),
        },
        {
          title: "Forma de avaliação",
          name: "result_evaluation",
          fieldType: "lambda",
          selectValues: (formValues, context, apiOptions) => {
            return formValues?.["indicators"]?.[context.index];
          },
          determineValue: (selectedValues, context, apiOptions) => {
            return selectedValues?.["result_evaluation"];
          },
        },
        {
          title: "Valores anteriores acumulados",
          name: "accumulated_results",
          fieldType: "lambda",
          selectValues: (formValues, context, apiOptions) => {
            return formValues?.["indicators"]?.[context.index];
          },
          determineValue: (selectedValues, context, apiOptions) => {
            return selectedValues?.["accumulated_results"] ?? 0;
          },
        },
        {
          title: "Meta efetiva do período",
          name: "reached_results",
          fieldType: "input",
          inputType: "number",
          validationSchema: Yup.number().required("Campo obrigatório")
            .moreThan(-1, "Apenas números positivos")
            .nullable(),
        },
        {
          title: "Total planejado",
          name: "total_planned",
          fieldType: "lambda",
          selectValues: (formValues, context, apiOption) => {
            return formValues?.["indicators"]?.[context.index];
          },
          determineValue: (selectedValues, context, apiOptions) => {
            return selectedValues?.["total_planned"];
          },
        },
        {
          title: "Progresso relativo no período (%)",
          name: "relative_results",
          fieldType: "lambda",
          selectValues: (formValues, context, apiOptions) => {
            return formValues?.["indicators"]?.[context.index];
          },
          determineValue: (selectedValues, context, apiOptions) => {
            const totalPlanned = selectedValues?.["total_planned"];
            const reachedResults = selectedValues?.["reached_results"];
            if (
              !totalPlanned ||
              reachedResults === undefined ||
              reachedResults === null
            )
              return "--";
            const percentage =
              (Number(reachedResults) / Number(totalPlanned)) * 100;
            return `${percentage.toFixed(2)}%`;
          },
        },
        {
          title: "Progresso total até o momento (%)",
          name: "accumulated_relative_results",
          fieldType: "lambda",
          selectValues: (formValues, context, apiOptions) => {
            return formValues?.["indicators"]?.[context.index];
          },
          determineValue: (selectedValues, context, apiOptions) => {
            const totalPlanned = selectedValues?.["total_planned"];
            const reachedResults = selectedValues?.["reached_results"];
            const accumulatedResults =
              selectedValues?.["accumulated_results"] ?? 0;
            const resultEvaluation = selectedValues?.["result_evaluation"];
            const reportsCount = selectedValues?.["previous_reports_count"];
            if (
              totalPlanned === null ||
              totalPlanned === undefined ||
              reachedResults === null ||
              reachedResults === undefined ||
              accumulatedResults === null ||
              accumulatedResults === undefined ||
              resultEvaluation === null ||
              resultEvaluation === undefined ||
              reportsCount === null ||
              reportsCount === undefined
            )
              return "--";

            const updateAvgOperation = (
              prevResults,
              currentResults,
              reportsCount
            ) => {
              return (
                (Number(reportsCount) * Number(prevResults) +
                  Number(currentResults)) /
                (Number(reportsCount) + 1)
              );
            };

            const updateSumOperation = (prevResults, currentResults) =>
              Number(prevResults) + Number(currentResults);

            const accumulatedAndReachedResults =
              resultEvaluation === "Média"
                ? updateAvgOperation(
                    accumulatedResults,
                    reachedResults,
                    reportsCount
                  )
                : updateSumOperation(accumulatedResults, reachedResults);

            const percentage =
              (accumulatedAndReachedResults / totalPlanned) * 100;
            return `${percentage.toFixed(2)}%`;
          },
        },
        {
          title: "Observação",
          name: "observation",
          fieldType: "input",
          inputType: "textarea",
          validationSchema: Yup.string().max(
            1000,
            "Limite de 1000 caracteres atingido"
          ),
          columns: "1/4",
        },
      ],
      columns: "1/4",
    },
  ],
  FINANCES: [
    {
      title: "Consolidado financeiro",
      name: "finance_table",
      fieldType: "reportFinanceTable",
      columns: "1/4",
    },
    {
      title: "Resumo financeiro",
      name: "finance_table",
      fieldType: "financetable",
      columns: "1/4",
      tableFields: [
        {
          title: "Orçamento",
          name: "total_planned_costs",
          fieldType: "lambda",
          inputType: "number",
        },
        {
          title: "Saldo anterior",
          name: "previous_balance",
          fieldType: "lambda",
          inputType: "number",
        },
        {
          title: "Repasse no período",
          name: "report_period_total_transfers",
          fieldType: "lambda",
          inputType: "number",
        },
        {
          title: "Gasto no período",
          name: "report_period_total_costs",
          fieldType: "lambda",
          inputType: "number",
        },
        {
          title: "Saldo em conta",
          name: "current_balance",
          fieldType: "lambda",
          inputType: "number",
        },
      ],
    },
    {
      title: "Relatório financeiro",
      name: "finances",
      fieldType: "table",
      tableFields: [
        {
          title: "Tipo de investimento",
          name: "investment_type",
          fieldType: "lambda",
          selectValues: (formValues, context, apiOptions) => {
            return formValues?.["finances"]?.[context.index];
          },
          determineValue: (selectedValues, context, apiOptions) => {
            const options = apiOptions?.["investment_item"];
            const selectedOption = options?.find(
              (item) =>
                String(item?.value) ===
                String(selectedValues?.["investment_item"])
            );
            return selectedOption?.["investment_type"];
          },
        },
        {
          title: "Item de investimento",
          name: "investment_item",
          fieldType: "apiselect",
          optionsSource: (context) =>
            `options/projectinvestments/${context?.projectId}`,
            validationSchema: Yup.string().required("Campo obrigatório"),
        },
        {
          title: "Orçamento",
          name: "amount",
          label: "R$",
          fieldType: "input",
          inputType: "number",
          validationSchema: Yup.number().required("Campo obrigatório")
            .moreThan(-1, "Apenas números positivos")
            .nullable(),
        },
        {
          title: "Arquivo da nota fiscal",
          name: "invoice_file",
          fieldType: "file",
          validationSchema: Yup.mixed().required("Campo obrigatório").test(
            "fileSize",
            "Aceito apenas arquivos menores que 4 MB",
            validateFileSizeRestriction(MAX_FILE_SIZE)
          ),
        },
      ],
      columns: "1/4",
    },
    {
      title: "Relatório de remanejamentos",
      name: "remanagements",
      fieldType: "table",
      tableFields: [
        {
          title: "Item de investimento anterior",
          name: "previous_investment_item",
          fieldType: "apiselect",
          optionsSource: (context) =>
            `options/projectinvestments/${context?.projectId}`,
          validationSchema: Yup.string().required("Campo obrigatório"),
        },
        {
          title: "Novo item",
          fieldType: "apiselect",
          name: "new_investment_item",
          addNew: true,
          getSaveUrl: (context) => `projects/${context.projectId}/addNewItem`,
          optionsSource: (context) =>
            `options/projectinvestments/${context?.projectId}`,
          validationSchema: Yup.string().required("Campo obrigatório").notOneOf(
            [Yup.ref("previous_investment_item")],
            "Novo item não pode ser igual ao original"
          ),
          addNewFieds: [
            {
              title: "Tipo de investimento",
              name: "investment_type",
              fieldType: "select",
              options: [
                [
                  "Lei de Incentivo - Captação Phi",
                  "Lei de Incentivo - Captação Phi",
                ],
                ["Materiais e Equipamentos", "Materiais e Equipamentos"],
                ["Transporte e Alimentação", "Transporte e Alimentação"],
                ["Recursos Humanos", "Recursos Humanos"],
                ["Outros", "Outros"],
              ].map(([k, v]) => ({ label: k, value: v })),
            },
            {
              title: "Descrição do item",
              name: "item_description",
              fieldType: "input",
              inputType: "text",
              validationSchema: Yup.string().max(100, "Limite de 100 caracteres atingido"),
            },
            {
              title: "Preço unitário",
              name: "unit_price",
              fieldType: "input",
              inputType: "number",
              validationSchema: Yup.number()
                .moreThan(-1, "Apenas números positivos")
                .nullable(),
            },
            {
              title: "Unidades",
              name: "amount",
              fieldType: "input",
              inputType: "number",
              validationSchema: Yup.number()
                .moreThan(-1, "Apenas números positivos")
                .nullable(),
            },
          ],
          columns: "1/4"
        },
        {
          title: "Valor remanejado",
          name: "remanaged_budget",
          fieldType: "input",
          label: "R$",
          type: "number",
          validationSchema: Yup.number().required("Campo obrigatório")
            .moreThan(-1, "Apenas números positivos")
            .nullable(),
        },
        {
          title: "Arquivo da nota fiscal",
          name: "remanagement_invoice_file",
          fieldType: "file",
          validationSchema: Yup.mixed().required("Campo obrigatório").test(
            "fileSize",
            "Aceito apenas arquivos menores que 4 MB",
            validateFileSizeRestriction(MAX_FILE_SIZE)
          ),
        },
      ],
      columns: "1/4",
    },
    {
      title: "Extratos bancários",
      name: "statements",
      fieldType: "table",
      tableFields: [
        {
          title: "Arquivo",
          name: "statement_file",
          fieldType: "file",
          validationSchema: Yup.mixed().required("Campo obrigatório").test(
            "fileSize",
            "Aceito apenas arquivos menores que 4 MB",
            validateFileSizeRestriction(MAX_FILE_SIZE)
          ),
        },
      ],
      columns: "1/4",
    },
  ],
  MANAGEMENT: [
    {
      title: "Quantas pessoas da equipe se dedicam à execução deste projeto?",
      name: "project_team_participants",
      fieldType: "input",
      inputType: "number",
      validationSchema: Yup.number()
        .moreThan(-1, "Apenas números positivos")
        .nullable(),
      columns: "1/3",
    },
    {
      title: "Como a equipe tem se organizado?",
      name: "team_organization",
      fieldType: "radio",
      options: [
        "Idealmente, dentro do previsto",
        "De forma regular",
        "O processo precisa melhorar",
      ].map(itemToOption),
      columns: "3/4",
    },
    {
      title: "Cite até três pontos que justifiquem sua resposta:",
      name: "management_points",
      fieldType: "table",
      minItems: 1,
      maxItems: 3,
      tableFields: [
        {
          text: "Descrição",
          name: "description",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().max(100, "Limite de 100 caracteres atingido"),
        },
      ],
      columns: "1/4",
    },
  ],
  EXECUTION: [
    {
      title: "Os objetivos do projeto estão sendo cumpridos?",
      text: executionSectionText,
      name: "objectives_advancement",
      fieldType: "radio",
      options: [
        "Sim, de forma integral",
        "Sim, de forma parcial",
        "Não, estamos com dificuldades",
      ].map(itemToOption),
      columns: "1/4",
    },
    {
      name: "advancement_reasons",
      text: "Descreva as razões identificadas",
      fieldType: "input",
      inputType: "textarea",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Cite até três ganhos/conquistas do período do relatório",
      text: "Tanto para o projeto apoiado quanto para a organização e seus beneficiários",
      name: "conquests",
      maxItems: 3,
      fieldType: "table",
      tableFields: [
        {
          text: "Conquista",
          name: "conquest",
          fieldType: "textarea",
          validationSchema: Yup.string().max(500, "Limite de 500 caracteres atingido"),
        },
      ],
      columns: "1/4",
    },
    {
      title:
        "Cite até três dificuldades/problemas encontrados no período do relatório",
      tooltip: "Evite focar apenas em captação de recursos",
      name: "difficulties",
      maxItems: 3,
      fieldType: "table",
      tableFields: [
        {
          text: "Dificuldade",
          name: "difficulty",
          fieldType: "textarea",
          validationSchema: Yup.string().max(500, "Limite de 500 caracteres atingido"),
        },
      ],
      columns: "1/4",
    },
    {
      title:
        "Se você fosse resumir esse período do projeto em duas palavras, quais seriam?",
      text: "Palavra 1",
      name: "keyword_1",
      fieldType: "input",
      inputType: "text",
      validationSchema: Yup.string().max(50, "Limite de 50 caracteres atingido"),
      columns: "1/3",
    },
    {
      text: "Palavra 2",
      name: "keyword_2",
      fieldType: "input",
      inputType: "text",
      validationSchema: Yup.string().max(50, "Limite de 50 caracteres atingido"),
      columns: "3/4",
    },
  ],
  STORY: [
    {
      title: "História inspiradora",
      name: "inspiring_story",
      fieldType: "textarea",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Foto relacionada",
      name: "inspiring_story_picture",
      fieldType: "file",
      validationSchema: Yup.mixed().test(
        "fileSize",
        "Aceito apenas arquivos menores que 8 MB",
        (value) =>
          value == null ||
          value.content.size === undefined ||
          (value && value.content.size <= pictureFileSize)
      ),
      columns: "1/4",
    },
    {
      title: "Legenda da foto",
      name: "inspiring_story_picture_label",
      fieldType: "input",
      inputType: "text",
      validationSchema: Yup.string().max(100, "Limite de 100 caracteres atingido"),
      columns: "1/4",
    },
  ],
  MEDIA: [
    {
      title: "Registro fotográfico ou material de divulgação",
      text: "Envie no mínimo 4 fotos em boa resolução",
      name: "pictures",
      minItems: 4,
      insertable: true,
      fieldType: "table",
      tableFields: [
        {
          title: "Arquivo",
          name: "file",
          fieldType: "file",
          validationSchema: Yup.mixed().test(
            "fileSize",
            "Aceito apenas arquivos menores que 8 MB",
            (value) =>
              value == null ||
              value.content.size === undefined ||
              (value && value.content.size <= pictureFileSize)
          ),
        },
        {
          title: "Legenda da foto",
          name: "label",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().max(100, "Limite de 100 caracteres atingido"),
        },
      ],
      columns: "1/4",
    },
  ],
  LAW_INCENTIVES: [
    {
      title: "Mídia relacionada",
      text: "Preencha apenas se o projeto é apoiado via Lei de Incentivo. Liste mídias espontâneas e pagas (matérias em jornais, revistas, rádio, TV etc.), sobre o projeto incentivado e/ou o apoio. Colocar o título e o link para imagem, site, programa de rádio, podcast ou vídeo. Liste também posts e videos publicados em redes sociais sobre o projeto e que citam a empresa incentivadora.",
      name: "law_incentives_media",
      fieldType: "table",
      tableFields: [
        {
          title: "Tipo de mídia",
          name: "media_type",
          fieldType: "select",
          options: [
            ["Mídia paga", 1],
            ["Mídia espontânea", 2],
            ["Publicação própria", 3],
          ].map(([label, value]) => ({ label, value })),
          validationSchema: Yup.string().required("Campo obrigatório"),
        },
        {
          title: "Título",
          name: "title",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().max(100, "Limite de 100 caracteres atingido"),
        },
        {
          title: "Link de acesso",
          name: "link",
          fieldType: "input",
          inputType: "url",
          validationSchema: Yup.string()
            .max(100, "Limite de 100 caracteres atingido")
            .url("Insira um site válido"),
        },
      ],
      columns: "1/4",
    },
    {
      title: "Contrapartidas executadas",
      name: "incentives_law_tax_considerations",
      fieldType: "table",
      tableFields: [
        {
          title: "Descrição da contrapartida",
          name: "description",
          fieldType: "input",
          inputType: "text",
          validationSchema: Yup.string().required("Campo obrigatório").max(100, "Limite de 100 caracteres atingido"),
        },
        {
          title: "Quantidade executada no período",
          name: "amount",
          fieldType: "input",
          inputType: "number",
          validationSchema: Yup.number().required("Campo obrigatório"),
        },
      ],
      columns: "1/4",
    },
  ],
  OBSERVATIONS: [
    {
      title: "Observações",
      text: "Caso tenha algo essencial que não foi possível contar nos outros campos, escreva aqui.",
      name: "observations",
      fieldType: "textarea",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "1/4",
    },
  ],
};
