import { formStatus, toLabelValuePair } from "./options";
import * as Yup from "yup";
import {
  validateFileSizeRestriction,
  validateSupportedFileFormats,
} from "../utils/ValidationUtils";

const MAX_FILE_SIZE = 2 ** 22; // 4MB
const logoSuportedFormats = ["jpg", "jpeg", "png"];

const UFValues = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

const managementQuestionsAmounts = [
  {
    label: "0",
    value: "0",
  },
  {
    label: "1 a 4",
    value: "1 a 4",
  },
  {
    label: "5 a 10",
    value: "5 a 10",
  },
  {
    label: "11 a 20",
    value: "11 a 20",
  },
  {
    label: "21 a 50",
    value: "21 a 50",
  },
  {
    label: "51 a 100",
    value: "51 a 100",
  },
  {
    label: "Acima de 100",
    value: "Acima de 100",
  },
];

const orgDocumentTypes = [
  [1, "Outro tipo de documento"],
  [2, "Estatuto social"],
  [3, "Ata da última assembleia de eleição de diretoria"],
  [4, "Cartão do CNPJ"],
  [5, "RG e CPF do responsável legal"],
  [6, "Comprovante de residência do responsável legal"],
  [
    7,
    "Certidão de Débitos Relativos a Créditos Tributários Federais e à Dívida Ativa da União",
  ],
  [8, "Certificado de regularidade do FGTS"],
  [9, "Certidão Negativa de Débitos Trabalhistas"],
  [10, "Certidão Negativa de Tributos Estaduais"],
  [11, "Certidão Negativa de Tributos Municipais"],
  [12, "Dados Bancários da Instituição"],
  [13, "Certidão isenção ITCMD"],
].map(([value, label]) => ({ value, label }));

//PAGES
export const dataFormPages = {
  DATA: {
    label: "Dados da organização",
    order: 1,
  },
  ADDRESS: {
    label: "Endereço da organização",
    order: 2,
  },
  ORG_CONTACT: {
    label: "Responsável pela organização",
    order: 3,
  },
  FILL_CONTACT: {
    label: "Responsável pelo preenchimento do formulário",
    order: 4,
  },
};

export const profileFormPages = {
  ORG_PROFILE: {
    label: "PERFIL DA ORGANIZAÇÃO",
    order: 1,
  },
};

export const managementFormPages = {
  MANAGEMENT: {
    label: "GESTÃO",
    order: 6,
  },
};

export const transparencyFormPages = {
  TRANSPARENCY: {
    label: "TRANSPARÊNCIA",
    order: 7,
  },
};

export const solidityFormPages = {
  SOLIDITY: {
    label: "SOLIDEZ",
    order: 8,
  },
};

export const impactPotentialFormPages = {
  POTENTIAL: {
    label: "POTENCIAL DE IMPACTO",
    order: 9,
  },
};

export const documentsFormPages = {
  DOCUMENTS: {
    label: "DOCUMENTOS",
    order: 10,
  },
};

//FORMS
export const dataForm = {
  DATA: [
    {
      title: "Status",
      name: "form_status",
      fieldType: "select",
      options: toLabelValuePair(formStatus),
      groups: [1, 2, 3],
      validationSchema: Yup.number()
      .required("Campo obrigatório"),
      columns: "1/4"
    },
    {
      title: "Nome fantasia",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "text",
      name: "org_name",
      label: "",
      placeholder: "Digite aqui...",
      validationSchema: Yup.string()
      .max(200, "Limite de 200 caracteres atingido")
      .required("Campo obrigatório"),
      columns: "1/4"
    },
    {
      title: "CNPJ",
      text: "CNPJ da organização",
      tooltip: "Preencha o CNPJ sem pontos, traços ou barras",
      fieldType: "input",
      inputType: "text",
      pattern: "[0-9]{3}(-[0-9]{3}){2}/[0-9]{2}",
      name: "cnpj",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/2",
    },
    {
      title: "Razão social",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "text",
      name: "legal_name",
      label: "",
      placeholder: "Digite aqui...",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "2/4",
    },
    {
      title: "Data da fundação",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "date",
      name: "foundation_date",
      label: "",
      placeholder: "",
      validationSchema: Yup.date()
      .required("Campo obrigatório"),
      columns: "1/4"
    },
    {
      title: "Analista",
      fieldType: "apiselect",
      name: "analyst",
      optionsSource: () => `options/analysts`,
      groups: [1, 2, 3],
      columns: "1/4"
    },
    {
      title: "Perfil",
      text: "",
      tooltip: "",
      fieldType: "select",
      name: "org_profile",
      label: "",
      placeholder: "Selecione...",
      options: [
        {
          label: "ONG / OSC",
          value: "ONG / OSC",
        },
        {
          label: "Coletivo / Movimento",
          value: "Coletivo / Movimento",
        },
        {
          label: "Fundação",
          value: "Fundação",
        },
        {
          label: "Instituto familiar",
          value: "Instituto familiar",
        },
        {
          label: "Instituto empresarial",
          value: "Instituto empresarial",
        },
        {
          label: "Negócio Social",
          value: "Negócio Social",
        },
        {
          label: "Outro",
          value: "Outro",
        },
      ],
      alt: {
        value: "Outro",
        field: {
          title: "",
          text: "",
          tooltip: "",
          fieldType: "input",
          inputType: "text",
          name: "org_profile",
          label: "Especifique",
          placeholder: "",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/2",
    },
    {
      title: "E-mail",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "email",
      name: "org_email",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().email("Insira um email válido"),
      columns: "2/4",
    },
    {
      title: "Facebook",
      text: "",
      tooltip: "Necessário o endereço completo (com http:// ou https://)",
      fieldType: "input",
      inputType: "text",
      name: "facebook_url",
      label: "",
      placeholder: "",
      validationSchema: Yup.string()
        .url("Insira um site válido")
        .max(200, "Limite de 200 caracteres atingido"),
      columns: "1/2",
    },
    {
      title: "Site",
      text: "",
      tooltip: "Necessário o endereço completo (com http:// ou https://)",
      fieldType: "input",
      inputType: "text",
      name: "site_url",
      label: "",
      placeholder: "",
      validationSchema: Yup.string()
        .url("Insira um site válido")
        .max(200, "Limite de 200 caracteres atingido"),
      columns: "2/4",
    },
    {
      title: "Logo da organização",
      text: "",
      tooltip: "",
      fieldType: "file",
      name: "logo",
      label: "",
      placeholder: "",
      validationSchema: Yup.mixed()
        .required("Campo obrigatório")
        .test(
          "fileSize",
          "Aceito apenas arquivos menores que 4 MB",
          validateFileSizeRestriction(MAX_FILE_SIZE)
        )
        .test(
          "fileFormat",
          "Formatos suportados são: png, jpeg e jpg",
          validateSupportedFileFormats(logoSuportedFormats)
        ),
      columns: "1/4",
    },
  ],
  ADDRESS: [
    {
      title: "Endereço",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "text",
      name: "org_address",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/2",
    },
    {
      title: "Número",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "number",
      name: "org_address_number",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(10, "Limite de 10 caracteres atingido"),
      columns: "2/3",
    },
    {
      title: "Complemento",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "text",
      name: "org_address_complement",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "3/4",
    },
    {
      title: "Bairro",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "text",
      name: "org_district",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/2",
    },
    {
      title: "Cidade",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "text",
      name: "org_city",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "2/3",
    },
    {
      title: "UF",
      text: "",
      tooltip: "",
      fieldType: "select",
      name: "org_UF",
      label: "",
      placeholder: "",
      options: UFValues.map((UF) => ({ label: UF, value: UF })),
      columns: "3/4",
    },
    {
      title: "CEP",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "text",
      name: "org_CEP",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(8, "Limite de 8 caracteres atingido"),
      columns: "1/2",
    },
    {
      title: "Telefone 1",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "tel",
      name: "org_phone_1",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(20, "Limite de 20 caracteres atingido"),
      columns: "2/3",
    },
    {
      title: "Telefone 2",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "tel",
      name: "org_phone_2",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(20, "Limite de 20 caracteres atingido"),
      columns: "3/4",
    },
  ],
  ORG_CONTACT: [
    {
      title: "Nome",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "text",
      name: "org_representative_name",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Celular",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "tel",
      name: "org_representative_phone",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(20, "Limite de 20 caracteres atingido"),
      columns: "1/2",
    },
    {
      title: "E-mail",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "email",
      name: "org_representative_email",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().email("Insira um email válido"),
      columns: "2/4",
    },
  ],
  FILL_CONTACT: [
    {
      title: "Nome",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "text",
      name: "org_form_filler_name",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Celular",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "tel",
      name: "org_form_filler_phone",
      label: "",
      placeholder: "",
      validationSchema: Yup.string().max(20, "Limite de 20 caracteres atingido"),
      columns: "1/2",
    },
    {
      title: "E-mail",
      text: "",
      tooltip: "",
      fieldType: "input",
      inputType: "email",
      name: "org_form_filler_email",
      label: "",
      placeholder: "",
      page: "FILL_CONTACT",
      validationSchema: Yup.string().email("Insira um email válido"),
      columns: "2/4",
    },
  ],
};

export const profileForm = {
  ORG_PROFILE: [
    {
      title: "Status",
      name: "form_status",
      fieldType: "select",
      options: toLabelValuePair(formStatus),
      groups: [1, 2, 3],
      validationSchema: Yup.number().required("Campo obrigatório"),
      columns: "1/4",
    },
    {
      title: "Missão da organização",
      text: "",
      tooltip: "",
      fieldType: "textarea",
      name: "mission",
      label: "",
      placeholder: "Digite aqui...",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Problema alvo",
      text: "Qual o problema social ou ambiental que a organização busca solucionar? Explique qual o problema e qual a gravidade e abrangência dele. Seja claro e objetivo, esta questão estará em sua folha de apresentação.",
      tooltip: "",
      fieldType: "textarea",
      name: "target_issue",
      label: "",
      placeholder: "Digite aqui...",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Solução",
      text: "Qual a proposta da organização para atuar na solução do problema social citado acima? Explique qual a abordagem e metodologia que a organização utiliza para criar o impacto positivo. Seja claro e objetivo, esta questão estará em sua folha de apresentação.",
      tooltip: "",
      fieldType: "textarea",
      name: "solution",
      label: "",
      placeholder: "Digite aqui...",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Unidades",
      text: "Em qual região, bairro ou comunidade a organização atua? Quantas e quais unidades são?",
      tooltip: "",
      fieldType: "textarea",
      name: "units",
      label: "",
      placeholder: "Digite aqui...",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Área de atuação",
      text: "Qual a principal área atendida pela organização?",
      tooltip: "",
      fieldType: "select",
      name: "primary_area",
      label: "",
      placeholder: "Selecione...",
      options: [
        ["Acessibilidade", "Acessibilidade"],
        [
          "Combate à violência (contra a mulher, abuso infantil, etc.)",
          "Combate à violência",
        ],
        [
          "Combate à desigualdade social",
          "Combate à desigualdade social",
        ],
        ["Cultura (música, teatro, artes)", "Cultura"],
        [
          "Desenvolvimento (construção, estrutura, saneamento, habitação)",
          "Desenvolvimento",
        ],
        ["Direitos Humanos", "Direitos Humanos"],
        ["Educação", "Educação"],
        ["Empoderamento feminino", "Empoderamento feminino"],
        ["Esporte", "Esporte"],
        [
          "Geração de renda (capacitação e inserção no mercado de trabalho)",
          "Geração de renda (capacitação e inserção no mercado de trabalho)",
        ],
        [
          "Geração de renda (empreendedorismo)",
          "Geração de renda (empreendedorismo)",
        ],
        [
          "Geração de renda (foco no primeiro emprego)",
          "Geração de renda (foco no primeiro emprego)",
        ],
        ["Inclusão (população egressa)", "Inclusão (população egressa)"],
        [
          "Inclusão (população em situação de rua)",
          "Inclusão (população em situação de rua)",
        ],
        [
          "Inclusão (população usuária de drogas)",
          "Inclusão (população usuária de drogas)",
        ],
        ["Inclusão (refugiados)", "Inclusão (refugiados)"],
        ["Lazer", "Lazer"],
        ["Meio ambiente", "Meio ambiente"],
        ["PCD (Pessoas com deficiência)", "PCD (Pessoas com deficiência)"],
        ["Outras", "Outras"],
      ].map(([label, value]) => ({ label, value })),
      alt: {
        value: "Outro",
        field: {
          title: "",
          text: "",
          tooltip: "",
          fieldType: "input",
          inputType: "text",
          name: "primary_area",
          label: "Especifique",
          placeholder: "",
        },
      },
      columns: "1/4",
    },
    {
      title: "Público",
      text: "Qual o principal público atendido pela organização?",
      tooltip: "",
      fieldType: "checkbox",
      name: "target_population",
      label: "",
      options: [
        {
          label: "Adolescentes",
          value: "teenagers",
        },
        {
          label: "Adultos",
          value: "adults",
        },
        {
          label: "Crianças (0 a 4 anos)",
          value: "children_up_to_four",
        },
        {
          label: "Crianças (4 a 12 anos)",
          value: "children_four_to_twelve",
        },
        {
          label: "Famílias",
          value: "families",
        },
        {
          label: "Idosos",
          value: "elderly",
        },
        {
          label: "Jovens",
          value: "youth",
        },
        {
          label: "Jovens infratores",
          value: "young_offenders",
        },
        {
          label: "LGBT",
          value: "lgbt",
        },
        {
          label: "PCD (Pessoas com deficiência)",
          value: "pwd",
        },
        {
          label: "População de Abrigo",
          value: "sheltered",
        },
        {
          label: "População de Rua",
          value: "homeless",
        },
        {
          label: "População Rural",
          value: "rural_population",
        },
        {
          label: "Presidiários",
          value: "convicted",
        },
        {
          label: "Superdotados",
          value: "highly_gifted",
        },
        {
          label: "Outro",
          value: "other",
        },
      ],
      alt: {
        value: "other",
        field: {
          title: "",
          text: "",
          tooltip: "",
          fieldType: "input",
          inputType: "text",
          name: "target_population",
          label: "Especifique",
          placeholder: "",
        },
        columns: "3/4",
      },
      columns: "1/3",
    },
    {
      title: "Descrição do público",
      text: "Descreva o perfil do público atendido:",
      tooltip: "",
      fieldType: "textarea",
      name: "target_population_description",
      label: "",
      placeholder: "Digite aqui...",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "3/4",
    },
    {
      title: "Descrição de programas",
      text: "Descreva os principais programas que a organização realiza:",
      tooltip: "",
      fieldType: "textarea",
      name: "program_description",
      label: "",
      placeholder: "Digite aqui...",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Outros resultados",
      text: "Insira que outros resultados relevantes a organização teve (inclusive prêmios, certificações, selos):",
      tooltip: "",
      fieldType: "textarea",
      name: "other_results",
      label: "",
      placeholder: "Digite aqui...",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Ligações religiosas",
      text: "A organização é ligada formalmente a alguma instituição religiosa e/ou prega alguma religião em sua atuação?",
      tooltip: "",
      fieldType: "radio",
      name: "has_religious_connections",
      label: "",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Ligações políticas",
      text: "A organização é ligada a algum partido político?",
      tooltip: "",
      fieldType: "radio",
      name: "has_political_connections",
      label: "",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Isenção de imposto",
      text: "A organização é isenta do ITCMD - Imposto de Transmissão Causa Mortis e Doação?",
      tooltip: 
        "Em alguns estados também chamado de ITD/ITCD, é um tributo de competência dos Estados e do Distrito Federal, cujo fato gerador é a transmissão causa mortis de imóveis e a doação de quaisquer bens ou direitos, conforme Constituição Federal - artigo 155, I e § 1º; CTN: artigos 35 a 42",
      fieldType: "radio",
      name: "tax_exemption",
      label: "",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Objetivos de Desenvolvimento Sustentável",
      text: `Qual(is) dos Objetivos de Desenvolvimento Sustentável (ODS) listados abaixo são efetivamente trabalhados pela organização?`,
      tooltip:
        "Os Objetivos de Desenvolvimento Sustentável (ODS) são uma agenda mundial adotada durante a Cúpula das Nações Unidas sobre o Desenvolvimento Sustentável em setembro de 2015 composta por 17 objetivos e 169 metas a serem atingidos até 2030.",
      fieldType: "checkbox",
      name: "ods",
      label: "",
      placeholder: "Selecione...",
      options: [
        {
          label:
            "ODS 01: Erradicação da pobreza (acabar com a pobreza em todas as suas formas, em todos os lugares)",
          value: "ods1",
        },
        {
          label:
            "ODS 02: Fome zero e agricultura sustentável (acabar com a fome, alcanças a segurança alimentar e melhoria da nutrição e promover a agricultura sustentável)",
          value: "ods2",
        },
        {
          label:
            "ODS 03: Saúde e bem-estar (assegurar uma vida saudável e promover o bem-estar para todas e todos, em todas as idades)",
          value: "ods3",
        },
        {
          label:
            "ODS 04: Educação de qualidade (assegurar a educação inclusiva e equitativa e de qualidade, e promover oportunidades de aprendizagem ao longo da vida para todas e todos)",
          value: "ods4",
        },
        {
          label:
            "ODS 05: Igualdade de gênero (alcançar a igualdade de gênero e empoderar todas as mulheres e meninas)",
          value: "ods5",
        },
        {
          label:
            "ODS 06: Água potável e saneamento (assegurar a disponibilidade e gestão sustentável da água e saneamento para todas e todos)",
          value: "ods6",
        },
        {
          label:
            "ODS 07: Energia limpa e acessível (assegurar o acesso confiável, sustentável, moderno e a preço acessível à energia para todas e todos)",
          value: "ods7",
        },
        {
          label:
            "ODS 08: Trabalho decente e crescimento econômico (promover o crescimento econômico sustentado, inclusivo e sustentável, emprego pleno e produtivo e trabalho decente para todas e todos)",
          value: "ods8",
        },
        {
          label:
            "ODS 09: Indústria, inovação e infraestrutura (construir infraestruturas resilientes, promover a industrialização inclusiva e sustentável e fomentar a inovação)",
          value: "ods9",
        },
        {
          label:
            "ODS 10: Redução das desigualdades (reduzir a desigualdade dentro dos países e entre eles)",
          value: "ods10",
        },
        {
          label:
            "ODS 11: Cidades e comunidades sustentáveis (tornar as cidades e os assentamentos humanos inclusivos, seguros, resilientes e sustentáveis)",
          value: "ods11",
        },
        {
          label:
            "ODS 12: Consumo e produção responsáveis (assegurar padrões de produção e de consumo sustentáveis)",
          value: "ods12",
        },
        {
          label:
            "ODS 13: Ação contra mudança global do clima (tomar medidas urgentes para combater a mudança climática e seus impactos)",
          value: "ods13",
        },
        {
          label:
            "ODS 14: Vida na água (conservação e uso sustentável dos oceanos, dos mares e dos recursos marinhos para o desenvolvimento sustentável)",
          value: "ods14",
        },
        {
          label:
            "ODS 15: Vida terrestre (Proteger, recuperar e promover o uso sustentável dos ecossistemas terrestres, gerir de forma sustentável as florestas, combater a desertificação, deter e reverter a degradação da terra e deter a perda de biodiversidade)",
          value: "ods15",
        },
        {
          label:
            "ODS 16: Paz, justiça e instituições eficazes (promover sociedades pacíficas e inclusivas para o desenvolvimento sustentável, proporcionar o acesso à justiça para todos e construir instituições eficazes, responsáveis e inclusivas em todos os níveis)",
          value: "ods16",
        },
        {
          label:
            "ODS 17: Parcerias e meios de implantação (fortalecer os meios de implementação e revitalizar a parceria global para o desenvolvimento sustentável)",
          value: "ods17",
        },
      ],
      columns: "1/4",
    },
    {
      title: "Gênero da liderança",
      text: "Com qual gênero a liderança da organização se identifica?",
      tooltip: "",
      fieldType: "radio",
      name: "leadership_gender",
      label: "",
      options: [
        {
          label: "Mulher (cisgênero ou transgênero)",
          value: "Mulher (cisgênero ou transgênero)",
        },
        {
          label: "Homem (cisgênero ou transgênero)",
          value: "Homem (cisgênero ou transgênero)",
        },
        {
          label: "Não Binário",
          value: "Não Binário",
        },
        {
          label: "Agênero",
          value: "Agênero",
        },
      ],
      columns: "1/2",
    },
    {
      title: "Detalhes da liderança",
      text: "Como prefere que seja classificada a forma de liderança da organização?",
      tooltip: "",
      fieldType: "radio",
      name: "leadership_classification",
      label: "",
      placeholder: "Selecione...",
      options: [
        {
          label: "Liderança negra",
          value: "Liderança negra",
        },
        {
          label: "Liderança quilombola",
          value: "Liderança quilombola",
        },
        {
          label: "Liderança trans",
          value: "Liderança trans",
        },
        {
          label: "Liderança indígena",
          value: "Liderança indígena",
        },
        {
          label: "Liderança sem distinção racial ou orientação",
          value: "Liderança sem distinção racial ou orientação",
        },
      ],
      columns: "2/4",
    },
    {
      title: "Advocacy",
      text: "A organização possui projetos voltados para advocacy?",
      tooltip:
      `Consulte a definição: 
      https://www.ipea.gov.br/acaosocial/article26c3.html?id.article=592`,
      fieldType: "radio",
      name: "has_advocacy_projects",
      label: "",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Municípios",
      text: "Quais os municípios atendidos pelo seu projeto, com população, IDH e IVM?",
      tooltip: `Exemplo:
      
      Municipio: São Paulo
      População: 12.396.372
      IDH: 0,794
      IVM: 43,19
      
      Municipio: Rio de Janeiro
      População: 6.775.561
      IDH: 0,799
      IVM: 52,31
      
      Para consultar o IVM:
      https://institutovotorantim.org.br/ivm/`,
      fieldType: "textarea",
      name: "municipalities",
      placeholder: "Digite aqui...",
      label: "",
      validationSchema: Yup.string().max(1000, "Limite de 1000 caracteres atingido"),
      columns: "1/4",
    },
  ],
};

export const managementForm = {
  MANAGEMENT: [
    {
      title: "Status",
      name: "form_status",
      fieldType: "select",
      options: toLabelValuePair(formStatus),
      groups: [1, 2, 3],
      validationSchema: Yup.number().required("Campo obrigatório"),
      columns: "1/4",
    },
    {
      title: "Funcionários formalizados",
      text: "Quantos funcionários formalizados pelo regime CLT a organização tem atualmente",
      tooltip: "",
      fieldType: "radio",
      name: "formal_employees",
      options: managementQuestionsAmounts,
      columns: "1/2",
    },
    {
      title: "Grau de ensino de funcionários",
      text: "Dos funcionários contratados, quantos possuem nível superior completo?",
      tooltip: "",
      fieldType: "radio",
      name: "higher_education_employees",
      label: "",
      options: managementQuestionsAmounts,
      columns: "2/3",
    },
    {
      title: "Voluntários regulares",
      text: "Quantos voluntários regulares a organização tem atualmente?",
      tooltip:
        "Considera-se voluntário regular aquele que se dedica mais de 10 horas semanais a organização",
      fieldType: "radio",
      name: "regular_volunteers",
      label: "",
      options: managementQuestionsAmounts,
      columns: "3/4",
    },
    {
      title: "Capacitação",
      text: "A organização promove capacitação para seus funcionários?",
      tooltip: "",
      name: "capacitation",
      fieldType: "radio",
      label: "",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Gestão financeira",
      text: "Existe ao menos uma pessoa exclusivamente responsável pela gestão financeira da organização?",
      tooltip: "",
      name: "financial_management",
      fieldType: "radio",
      label: "",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Previsão orçamentária",
      text: "Existe uma previsão orçamentária de todas as possíveis despesas da organização ao longo do ano?",
      tooltip: "",
      name: "cash_flow",
      fieldType: "radio",
      label: "",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Conta bancária",
      text: "A organização possui conta bancária ativa, cadastrada em seu CNPJ?",
      tooltip: "",
      name: "bank_account",
      fieldType: "radio",
      label: "",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Observação sobre conta bancária:",
      text: "",
      tooltip: "",
      label: "",
      fieldType: "input",
      inputType: "text",
      name: "bank_account_observation",
      placeholder: "Digite aqui...",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Planejamento estratégico",
      text: "Existe um planejamento estratégico de todas as atividades que serão desenvolvidas ao longo do ano?",
      tooltip: "",
      label: "",
      fieldType: "radio",
      name: "strategical_planning",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Documentos formais",
      text: "Os documentos formais Estatuto Social e Ata de Assembleia estão atualizados e devidamente registrados?",
      tooltip: "",
      label: "",
      fieldType: "radio",
      name: "formal_documents",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Observação sobre documentos formais:",
      text: "",
      tooltip: "",
      label: "",
      fieldType: "input",
      inputType: "text",
      name: "formal_documents_observation",
      placeholder: "Digite aqui...",
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Certidões",
      text: "A organização está em dia com todas as certidões exigidas para o cumprimento de suas atividades?",
      tooltip: "",
      label: "",
      fieldType: "radio",
      name: "certification",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Pendências fiscais",
      text: "A organização possui alguma pendência fiscal junto aos Órgãos Federativos e/ou está respondendo por algum processo na justiça?",
      tooltip: "",
      label: "",
      fieldType: "switch",
      name: "has_debts",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          title: "",
          text: "",
          tooltip: "",
          label: "Qual(is)?",
          fieldType: "input",
          inputType: "text",
          name: "has_debts",
          placeholder: "",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
  ],
};

export const transparencyForm = {
  TRANSPARENCY: [
    {
      title: "Status",
      name: "form_status",
      fieldType: "select",
      options: toLabelValuePair(formStatus),
      groups: [1, 2, 3],
      validationSchema: Yup.number().required("Campo obrigatório"),
      columns: "1/4",
    },
    {
      title: "Estatuto social",
      text: "O Estatuto social da organização está publicado em seu site?",
      fieldType: "switch",
      name: "has_social_statute_published",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Link",
          fieldType: "input",
          inputType: "text",
          name: "has_social_statute_published",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Publicação de relatórios",
      text: "A organização publica relatórios anuais de atividades, atualizados, em seu site?",
      fieldType: "switch",
      name: "publishes_reports",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Link",
          fieldType: "input",
          inputType: "text",
          name: "publishes_reports",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Publicação de demonstrações financeiras",
      text: "A organização publica as demonstrações contábeis e financeiras anuais?",
      fieldType: "switch",
      name: "publishes_financial_statements",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Link",
          fieldType: "input",
          inputType: "text",
          name: "publishes_financial_statements",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Contabilidade",
      text: "A contabilidade da organização é terceirizada?",
      fieldType: "switch",
      name: "has_private_accounting",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Nome do escritório",
          fieldType: "input",
          inputType: "text",
          name: "has_private_accounting",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Auditoria externa",
      text: "A organização possui relatório de auditoria externa, atualizado, publicado em seu site?",
      fieldType: "switch",
      name: "has_external_auditting",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Link",
          fieldType: "input",
          inputType: "text",
          name: "has_external_auditting",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Assessoria jurídica",
      text: "A organização possui assessoria jurídica formal?",
      fieldType: "switch",
      name: "has_legal_advice",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Nome do escritório",
          fieldType: "input",
          inputType: "text",
          name: "has_legal_advice",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
  ],
};

export const solidityForm = {
  SOLIDITY: [
    {
      title: "Status",
      name: "form_status",
      fieldType: "select",
      options: toLabelValuePair(formStatus),
      groups: [1, 2, 3],
      validationSchema: Yup.number().required("Campo obrigatório"),
      columns: "1/4",
    },
    {
      title: "Tempo de formalização",
      text: "Qual o tempo de formalização da organização?",
      name: "formalization_timespan",
      fieldType: "radio",
      options: [
        {
          label: "Não está formalizada",
          value: "Não está formalizada",
        },
        {
          label: "Menos de 2 anos",
          value: "<2",
        },
        {
          label: "Entre 2 e 4 anos",
          value: "2-4",
        },
        {
          label: "5 anos ou mais",
          value: ">5",
        },
      ],
      columns: "1/4",
    },
    {
      title: "Captação de recursos",
      text: "Existe ao menos uma pessoa exclusivamente responsável pela captação de recursos da organização?",
      name: "has_fundraising_responsible",
      fieldType: "radio",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Fontes de receita",
      text: "Quais são as principais fontes de receita da organização?",
      name: "fundraising_sources",
      fieldType: "checkbox",
      options: [
        {
          label: "Campanhas de plataformas online (crowdfunding)",
          value: "crowdfunding",
        },
        {
          label: "Convênios e parcerias com governo",
          value: "state_partnerships",
        },
        {
          label: "Doações internacionais",
          value: "international_donations",
        },
        {
          label: "Eventos",
          value: "events",
        },
        {
          label: "Fundo patrimonial/endowment/rendimento financeiro",
          value: "finances",
        },
        {
          label: "Grandes doações de empresas (patrocínios)",
          value: "sponsorships",
        },
        {
          label: "Grandes mantenedores (pessoa física, filantropos)",
          value: "philanthropists",
        },
        {
          label: "Leis de incentivo",
          value: "incentive_laws",
        },
        {
          label: "Microdoadores recorrentes",
          value: "reccurring_donors",
        },
        {
          label: "Parcerias com outras instituições, fundações",
          value: "org_partnerships",
        },
        {
          label: "Prestação de serviços / venda de produtos",
          value: "goods_and_services",
        },
      ],
      columns: "1/4",
    },
    {
      title: "Doações online",
      text: "A organização aceita doações pela internet?",
      name: "online_donations_link",
      fieldType: "switch",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Link",
          fieldType: "input",
          inputType: "text",
          name: "online_donations_link",
        },
      },
      validationSchema: Yup.string()
        .url("Insira um site válido")
        .max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Leis de incentivo",
      text: "A organização possui projeto(s) inscrito(s) em algumas das leis de incentivo abaixo?",
      name: "legal_incentives",
      fieldType: "checkbox",
      tooltip: `Caso não possua, basta deixar as caixas em branco`,
      options: [
        ["FUMCAD", "fumcad"],
        ["Fundo do Idoso", "ederly_fund"],
        [
          "Fundo Estadual dos Direitos da Criança e do Adolescente (CONDECA)",
          "condeca",
        ],
        ["Lei do ICMS de São Paulo", "icms_sp"],
        ["Lei do ISS Municipal do Rio de Janeiro", "iss_rj"],
        [
          "Lei Estadual de Incentivo à Cultura do Rio de Janeiro – ICMS",
          "icms_rj",
        ],
        ["Lei Federal de Incentivo ao Esporte", "sport_incentive_federal_law"],
        ["Lei Rouanet", "rouanet"],
        ["PROMAC", "promac"],
        ["PRONON e PRONAS", "pronon_pronas"],
      ].map(([label, value]) => ({ label, value })),
      columns: "1/4",
    },
    {
      title: "Outras leis de incentivo",
      text: "A organização possui projeto(s) inscrito(s) em alguma outra lei de incentivo?",
      fieldType: "switch",
      name: "has_other_legal_incentives",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Especifique:",
          fieldType: "input",
          inputType: "text",
          name: "has_other_legal_incentives",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Orçamento anual",
      text: "Qual foi o orçamento anual da organização no último ano?",
      fieldType: "select",
      name: "yearly_funds",
      placeholder: "Selecione...",
      options: [
        {
          label: "Até R$ 50 mil",
          value: "Até R$ 50 mil",
        },
        {
          label: "De R$ 51 a R$ 100 mil",
          value: "De R$ 51 a R$ 100 mil",
        },
        {
          label: "De R$ 101 a R$ 500 mil",
          value: "De R$ 101 a R$ 500 mil",
        },
        {
          label: "De R$ 501 a R$ 1 milhão",
          value: "De R$ 501 a R$ 1 milhão",
        },
        {
          label: "De R$ 1 a R$ 10 milhões",
          value: "De R$ 1 a R$ 10 milhões",
        },
        {
          label: "Acima de R$ 10 milhões",
          value: "Acima de R$ 10 milhões",
        },
        {
          label: "Organizações novas",
          value: "Organizações novas",
        },
        {
          label: "Informação não disponibilizada",
          value: "Informação não disponibilizada",
        },
      ],
      columns: "1/4",
    },
    {
      title: "Porcentagens de orçamento",
      text: "Qual a porcentagem do orçamento total é doada pelo maior apoiador da instituição?",
      tooltip:
        'Por exemplo: "Meu maior doador é a Empresa ABC que é responsável por 75% do meu orçamento" = marcar mais de 70%',
      fieldType: "radio",
      name: "biggest_donor_funds_percentage",
      options: [
        ["Mais de 70%", "more_than_70"],
        ["Entre 51% e 69%", "between_51_69"],
        ["Entre 31 e 50%", "between_31_50"],
        ["Menos de 30%", "less_than_30"],
        ["Não temos financiadores", "no_donors"],
      ].map(([label, value]) => ({ label, value })),
      columns: "1/4",
    },
    {
      title: "Sede",
      text: "A organização tem sede fixa?",
      name: "has_fixed_headquarters",
      fieldType: "radio",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Parcerias formais não-financeiras",
      text: "Há parcerias formais que fornecem recursos não financeiros que ajudam a manter o funcionamento da organização?",
      tooltip:
        "Por exemplo: agência de comunicação pro bono, parceiro que sede o espaço para realização das atividades, etc",
      name: "has_non_financial_formal_partnerships",
      fieldType: "switch",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Qual(is)?",
          fieldType: "input",
          inputType: "text",
          name: "has_non_financial_formal_partnerships",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
  ],
};

export const impactPotentialForm = {
  POTENTIAL: [
    {
      title: "Status",
      name: "form_status",
      fieldType: "select",
      options: toLabelValuePair(formStatus),
      groups: [1, 2, 3],
      validationSchema: Yup.number().required("Campo obrigatório"),
      columns: "1/4",
    },
    {
      title: "Número de beneficiados",
      text: "Qual o número total de beneficiários atendidos diretamente pela organização por ano?",
      fieldType: "radio",
      name: "beneficiary_number",
      options: [
        "Até 100",
        "De 101 a 500",
        "De 501 a 1000",
        "De 1001 a 5000",
        "Acima de 5000",
        "Dado não contabilizado",
      ].map((item) => ({ label: item, value: item })),
      columns: "1/4",
    },
    {
      title: "Políticas públicas",
      text: "Existem ações visando a influência direta em políticas públicas na sua área de atuação?",
      fieldType: "switch",
      name: "public_policy",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Descreva:",
          name: "public_policy",
          fieldType: "input",
          inputType: "text",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Rede",
      text: "A organização participa de alguma rede formalmente consolidada?",
      fieldType: "switch",
      name: "participates_in_some_network",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Qual(is)?",
          name: "participates_in_some_network",
          fieldType: "input",
          inputType: "text",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Parcerias formais",
      text: "Há parcerias formais com outras organizações que ajudam a potencializar o impacto social da organização?",
      fieldType: "switch",
      tooltip:
        "Por exemplo: parcerias com hospitais que indicam famílias a serem atendidas; Parcerias com empresas que empregam os alunos capacitados, etc.",
      name: "has_any_formal_partnership",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Descreva:",
          name: "has_any_formal_partnership",
          fieldType: "input",
          inputType: "text",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
    {
      title: "Metas, objetivos, indicadores",
      text: "A organização cria metas, objetivos e indicadores para avaliar e monitorar cada um de seus projetos de forma recorrente?",
      fieldType: "radio",
      name: "has_goals_objectives_or_indicators",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Metodologia",
      text: "Existe um trabalho sistematizado das experiências da organização a fim de replicar sua metodologia?",
      fieldType: "radio",
      name: "has_work_methodology",
      isBoolean: true,
      columns: "1/4",
    },
    {
      title: "Ações de expansão",
      text: "Há ações em andamento para expansão da organização pelo próximo ano?",
      fieldType: "switch",
      tooltip:
        "Por exemplo: a partir do ano seguinte conseguiremos prestar consultoria em praticas sustentáveis de relacionamento comunitário / a partir do ano seguinte conseguiremos produzir materiais para serem vendidos na organização / no próximo ano replicaremos nossa metodologia em mais 1 região ou em 1 organização parceira.",
      name: "has_expanse_actions",
      standardInactiveValue: "",
      alt: {
        value: true,
        field: {
          label: "Qual(is)?",
          name: "has_expanse_actions",
          fieldType: "input",
          inputType: "text",
        },
      },
      validationSchema: Yup.string().max(200, "Limite de 200 caracteres atingido"),
      columns: "1/4",
    },
  ],
};

export const documentsForm = {
  DOCUMENTS: [
    {
      title: "Status",
      name: "form_status",
      fieldType: "select",
      options: toLabelValuePair(formStatus),
      groups: [1, 2, 3],
      validationSchema: Yup.number()
      .required("Campo obrigatório"),
      columns: "1/4"
    },
    {
      title: "Documentos básicos",
      fieldType: "prefilledtable",
      prefilledItems: orgDocumentTypes
        .filter(({ label, value }) => value !== 1)
        .map(({ label, value }, index) => ({
          type: value,
          file: "",
        })),
      getValueKey: (item) => item.type,
      getPrefillKey: (item) => item.type,
      name: "documents",
      tableFields: [
        {
          title: "Tipo de documento",
          name: "type",
          fieldType: "select",
          options: orgDocumentTypes,
          editable: false,
        },
        {
          title: "Arquivo",
          name: "file",
          fieldType: "file",
          validationSchema: Yup.mixed().test(
            "fileSize",
            "Aceito apenas arquivos menores que 4 MB",
            validateFileSizeRestriction(MAX_FILE_SIZE)
          ),
        },
        {
          title: "Data de Validade",
          name: "expiry_date",
          fieldType: "input",
          inputType: "date"
        },
      ],
      columns: "1/4",
    },
    {
      title: "Documentos extras",
      fieldType: "table",
      name: "extra_documents",
      tableFields: [
        {
          title: "Tipo de documento: extra",
          name: "type",
          fieldType: "input",
          inputType: "hidden",
          value: orgDocumentTypes[0].value,
        },
        {
          title: "Descrição do documento",
          name: "description",
          fieldType: "input",
          inputType: "text",
        },
        {
          title: "Arquivo",
          name: "file",
          fieldType: "file",
          validationSchema: Yup.mixed().test(
            "fileSize",
            "Aceito apenas arquivos menores que 4 MB",
            validateFileSizeRestriction(MAX_FILE_SIZE)
          ),
        },
        {
          title: "Data de Validade",
          name: "expiry_date",
          fieldType: "input",
          inputType: "date"
        },
      ],
      columns: "1/4",
    },
  ],
};
