import axios from "axios";
import { filterFiles } from "./fileUtils";

const baseUrl = process.env.REACT_APP_BASE_URL;
const axiosInstance = axios.create({
  baseURL: baseUrl,
  transformRequest: [
    (data, headers) => {
      if (headers["Content-Type"] !== "multipart/form-data") {
        return JSON.stringify(data);
      }
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      const filteredFiles = filterFiles(data);
      Object.entries(filteredFiles).forEach(([key, value]) =>
        formData.append(key, value)
      );
      return formData;
    },
  ],
});

const axiosInstance2 = axios.create({
  baseURL: baseUrl,
  transformRequest: [
    (data, headers) => {
      if (headers["Content-Type"] !== "multipart/form-data") {
        return JSON.stringify(data);
      }
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      const filteredFiles = filterFiles(data);
      Object.entries(filteredFiles).forEach(([key, value]) =>
        formData.append(key, value)
      );
      return formData;
    },
  ],
});
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => useRefreshToken()
// );

axiosInstance.interceptors.response.use(response => response, error => {
  const status = error.response ? error.response.status : null

  if (status === 401) {

      return checkToken().then(() => {
          error.config.headers['Authorization'] = 'Bearer ' + getToken();
          error.config.baseURL = undefined;
          return axiosInstance.request(error.config);
      });
  }

    return Promise.reject(error);
});

const AUTH_TOKEN_KEY = "authToken";
const AUTH_REFRESH_TOKEN_KEY = "refreshToken";

export function setToken(token) {
  localStorage.setItem(AUTH_TOKEN_KEY, token);
}

let refreshCounter = 0;
export function checkToken() {
  if (!getToken()) {
    return Promise.reject("No authorization token set");
  } else {
    return post2("auth/validate", {
      token: getToken(),
    }).then((response) =>
        Promise.resolve(response)
      ).catch((response) => {
        if (refreshCounter >= 5) {
          return Promise.reject("Token refresh failed");
        }
        refreshCounter++;
        useRefreshToken().then(() => checkToken())
      });
  }
}

export function clearToken() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
}

export function getToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function setRefreshToken(token) {
  localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, token);
}

export function getRefreshToken() {
  return localStorage.getItem(AUTH_REFRESH_TOKEN_KEY);
}

function useRefreshToken() {
  if (getRefreshToken()) {
    return post("auth/login/refresh", {
      refresh: getRefreshToken(),
    })
      .then((response) =>
        response.status === 200
          ? Promise.resolve(response.data)
          : Promise.reject("Token verification failed")
      )
      .then((responseObject) => {
        setToken(responseObject.access);
      });
  } else {
    return Promise.reject("No refresh token set");
  }
}

function buildHeaders(headers) {
  if (getToken()) {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      ...headers,
    };
  } else {
    return {
      "Content-Type": "application/json",
      ...headers,
    };
  }
}

const standardRequestOptions = {
  params: {},
  headers: {},
};

export const get = (route, { params, headers } = standardRequestOptions) => {
  return axiosInstance.get(`${route}/`, {
    headers: buildHeaders(headers),
    params,
  });
};

export const getFile = (
  route,
  { params, headers } = standardRequestOptions
) => {
  return axiosInstance.get(`${route}`, {
    headers: buildHeaders(headers),
    responseType: "blob",
    params,
  });
};

export const post = (
  route,
  body,
  { params, headers } = standardRequestOptions
) => {
  return axiosInstance.post(`${route}/`, body, {
    headers: buildHeaders(headers),
    params,
  });
};

export const post2 = (
  route,
  body,
  { params, headers } = standardRequestOptions
) => {
  return axiosInstance2.post(`${route}/`, body, {
    headers: buildHeaders(headers),
    params,
  });
};

export const patch = (
  route,
  body,
  { params, headers } = standardRequestOptions
) => {
  return axiosInstance.patch(`${route}/`, body, {
    headers: buildHeaders(headers),
    params,
  });
};

export const put = (
  route,
  body,
  { params, headers } = standardRequestOptions
) => {
  return axiosInstance.put(`${route}/`, body, {
    headers: buildHeaders(headers),
    params,
  });
};

export const noAuthPost = (
  route,
  body,
  { params, headers } = standardRequestOptions
) => {
  return axiosInstance.post(`${route}/`, body, {
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    params,
  });
};
