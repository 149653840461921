import { useEffect, useMemo } from "react";
import styles from "./styles.module.scss";

export default function Lambda({
  name,
  inputType,
  id,
  value,
  setValue,
  onBlur,
  error,
  placeholder,
  label,
  context,
  formValues,
  apiOptions,
  selectValues,
  determineValue,
}) {
  const selectedValues = useMemo(
    () => selectValues(formValues, context, apiOptions),
    [context, formValues, selectValues, apiOptions]
  );

  useEffect(() => {
    const newValue = determineValue(selectedValues, context, apiOptions);
    if (newValue != value) {
      setValue(newValue);
    }
  }, [context, determineValue, selectedValues, setValue, value, apiOptions]);

  return (
    <div className={`inputContainer ${styles.inputContainer}`}>
      {label && (
        <div className={styles.label}>
          <label>{label}</label>
        </div>
      )}
      <input
        tabIndex={-1}
        type={inputType}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        readOnly={true}
      />
    </div>
  );
}
