import styles from './styles.module.scss';

export default function Error({
  error
})
{
  return (
    <div className={styles.errorText}>      
      {error}
    </div>
  )
}
