import React from "react";
import styles from "./styles.module.scss";
class QuestionErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={styles.wrapper}>
          <header>{this.props.title && <h3>{this.props.title}</h3>}</header>
          <p>
            <strong> Erro ao carregar pergunta </strong>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default QuestionErrorBoundary;
