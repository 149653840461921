import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { infoLevels } from '../../../../constants';
import { projectRemanagementForm, projectsRemanagementFormPages } from '../../../../constants/projectRemanagementForm';
import { get, patch, post, put } from '../../../../utils/APIutils';
import { filterFormFieldsByUserGroup } from '../../../../utils/useForm';
import { FormPage } from '../../../FormPage';
import { Toast, useToast } from '../../../Toast';

import styles from './styles.module.scss';

export default function ProjectRemanagementForm({
  projectId,
  remanagementId,
  userData
}){
  const [filteredForm, setFilteredForm] = useState(() => filterFormFieldsByUserGroup(userData, projectRemanagementForm))
  const [remanagementData, setRemanagementData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useToast();
  const [reload, setReload] = useState(false);
  const [redirect, setRedirect] = useState("");

  const loadRemanagementData = () => {
    setLoading(true);
    get(`projects/${projectId}/remanagements/${remanagementId}`)
    .then((response) => response.data)
    .then((remanagementData) => setRemanagementData(remanagementData))
    .catch((error) => {
      console.log(error);
      setToast({show: true, infoLevel: infoLevels.ERROR, children: JSON.stringify(error.response.status)});
    })
    .finally(() => setLoading(false))
  }

  const submitForAnalysis = (remanagementId) => {
    return patch(`projects/${projectId}/remanagements/${remanagementId}`);
  }

  const handleSaveNew = (formData) => {
    return post(`projects/${projectId}/remanagements`, formData, {headers: {"Content-Type": "multipart/form-data"}})
    .then((response) => {
      setToast({show: true, infoLevel: infoLevels.SUCCESS, children: "Alteração feita com sucesso"});
      return response.data.id;
    });
  }

  const handleSaveUpdate = (formData) => {
    return put(`projects/${projectId}/remanagements/${remanagementId}`, formData, {headers: {"Content-Type": "multipart/form-data"}})
    .then((response) => {
      setToast({show: true, infoLevel: infoLevels.SUCCESS, children: "Alteração feita com sucesso"});
      return response.data.id;
    })
  }

  const handleSave = (formData) => {
    setLoading(true);
    return (remanagementId === undefined ? (
      handleSaveNew(formData)
    ) : (
      handleSaveUpdate(formData)
    ))
    .then((responseRemanagementId) => {
      if(remanagementId === undefined){
        return setRedirect(`/projects/${projectId}/remanagements/${responseRemanagementId}`);
      }
      return loadRemanagementData();
    })
    .catch((error) => {
      console.log(error);
      setToast({show: true, infoLevel: infoLevels.ERROR, children: JSON.stringify(error.response.status)});
    })
    .finally(() => setLoading(false));
  }

  const handleSubmit = (formData) => {
    setLoading(true);
    return (remanagementId === undefined ? (
      handleSaveNew(formData)
    ) : (
      handleSaveUpdate(formData)
    ))
    .then((responseRemanagementId) => submitForAnalysis(responseRemanagementId))
    .then((response) => {
      if(remanagementId === undefined){
        return setRedirect(`/projects/${projectId}/remanagements/${response.data.id}`);
      }
      return loadRemanagementData();
    })
    .catch((error) => {
      console.log(error);
      setToast({show: true, infoLevel: infoLevels.ERROR, children: JSON.stringify(error.response.status)});
    })
    .finally(() => setLoading(false));
  }

  useEffect(() => {
    if(remanagementId && projectId && (remanagementData === null || reload)){
        loadRemanagementData();
        setReload(false);
    }
  }, [projectId, remanagementId, reload])

  useEffect(() => {
    setFilteredForm(filterFormFieldsByUserGroup(userData, projectRemanagementForm));
  }, [userData])

  return(
    <div className={styles.formPageWrapper}>

      {(!remanagementId || (remanagementId && remanagementData)) !== null &&
        <FormPage
          name="projectRemanagementForm"
          form={filteredForm}
          initialState={remanagementData}
          pages={projectsRemanagementFormPages}
          editable={remanagementData === null ? true :
                    remanagementData.remanagement_status === "Aberto"}
          reload={reload}
          setReload={setReload}
          onSubmit={handleSubmit}
          onSave={handleSave}
          context={{projectId}}
          userData={userData}
        />
      }

      {redirect &&
        <Redirect to={redirect}/>
      }

      <Toast {...toast} />

      <Toast loading={loading} show={loading}/>

    </div>
  )
} 