import { Topbar } from './Topbar';
import { Sidebar } from './Sidebar';

import styles from './styles.module.scss';

export default function Layout({userData, userGroup, loadingUserData, children}){
  return (
    <div className={styles.layout}>
      <Sidebar
        userGroup={userGroup}
      />
      <div className={styles.contentWrapper}>
        <Topbar
          userData={userData}
          loadingUserData={loadingUserData}
        />
        {children}
      </div>
      <div id="toastContainer" className={styles.toastContainer}>
        
      </div>
    </div>
  )
}