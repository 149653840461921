export const routes = [
  {
    label: "Painel",
    path: "/",
    logo: "home_24px.svg",
    permissions: [1,2,3,4,5,6,7],
  },
  {
    label: "Organizações",
    path: "/orgs",
    logo: "business_24px.svg",
    permissions: [1,2,3,4,5,6,7],
  },
  {
    label: "Projetos",
    path: "/projects",
    logo: "ico-launch_24px.svg",
    permissions: [1,2,3,4,5,6,7],
  },
  // {
  //   label: "Documentos",
  //   path: "/documents",
  //   logo: "description_24px.svg",
  //   permissions: [1,2,3,4,5,6],
  // },
  // {
  //   label: "Paineis",
  //   path: "/dashboards",
  //   logo: "insert_chart_24px.svg",
  //   permissions: [1,2,3],
  // },
  // {
  //   label: "Configurações",
  //   path: "/settings",
  //   logo: "settings_24px.svg",
  //   permissions: [1,2,3,4,5,6],
  // },
  {
    label: "Permissões",
    path: "/permissions",
    logo: "permissions_24px.svg",
    permissions: [1,2,7],
  },
  // {
  //   label: "Importar dados",
  //   path: "/import_data",
  //   logo: "import_24px.svg",
  //   permissions: [1],
  // }
];