import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get, post } from "../../../../utils/APIutils";
import { getInitialFormValues } from "../../../../utils/useForm";
import { FilterForm } from "../../../Filtering/FilterForm";
import { StatusTag } from "../../../FormPage/StatusLabel";
import { Button } from "../../../Forms";
import { PaginationControls } from "../../../Pagination";
import { Toast, useToast } from "../../../Toast";
import { infoLevels } from "../../../../constants";
import { isUserPhi } from "../../../../utils/permissionUtils";

import styles from "./styles.module.scss";
import { NewReportModal } from "../NewReportModal";

const filtersForm = [
  {
    name: "begin_date__gte",
    title: "Início a partir de",
    fieldType: "input",
    inputType: "date",
  },
  {
    name: "end_date__lte",
    title: "Término antes de",
    fieldType: "input",
    inputType: "date",
  },
];

const initialFilters = getInitialFormValues({ DATA: filtersForm });

function formatDate(dateString) {
  if (!dateString) return "-";
  const [year, month, day] = dateString.split("-").map((item) => Number(item));
  return `${day}/${month}/${year}`;
}

export default function ProjectReportsList({ projectId, userData }) {
  const [reportsList, setReportsList] = useState(null);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [showNewReportModal, setShowNewReportModal] = useState(false);
  const [newReportToast, setNewReportToast] = useToast();

  const loadProjectReports = () => {
    const notBlankFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => !!value)
    );
    setLoading(true);
    get(`projects/${projectId}/reports`, {
      params: { page, ...notBlankFilters },
    })
      .then((response) => response.data)
      .then((data) => {
        setReportsList(data.items);
        setPageCount(data.pageCount);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const saveNewReport = useCallback(
    (newReportData) => {
      return post(`projects/${projectId}/reports`, newReportData)
        .then(() => setReload(true))
        .then(() => setShowNewReportModal(false))
        .catch((error) => {
          setNewReportToast({
            show: true,
            title: "Erro",
            children: <p>{error.request.status}</p>,
            infoLevel: infoLevels.ERROR,
          });
        });
    },
    [projectId, setNewReportToast]
  );

  useEffect(() => {
    if (projectId && (reportsList === null || reload)) {
      loadProjectReports();
      setReload(false);
    }
  }, [projectId, reload]);

  useEffect(() => {
    if (projectId) {
      loadProjectReports();
    }
  }, [page, filters]);

  return (
    <>
      <div className={styles.newButtonWrapper}>
        <Button
          styleString="filled"
          onClick={() => setShowNewReportModal(true)}
        >
          Novo
        </Button>
      </div>

      <div className={styles.listControls}>
        <PaginationControls
          currentPage={page}
          totalPages={pageCount}
          setPage={setPage}
        />

        <FilterForm
          form={filtersForm}
          filters={filters}
          setFilters={(values) => setFilters(values)}
        />
      </div>

      <div className={styles.wrapper}>
        <Toast show={loading} loading={loading} />

        <section className={styles.reportsListSection}>
          <table>
            <thead>
              <tr>
                <th> Data de envio ao Phi </th>
                <th> Data prevista de envio ao investidor </th>
                {isUserPhi(userData) && <th> Envio ao investidor</th>}
                <th> Status </th>
                <th> Aprovado em</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reportsList?.length > 0 &&
                reportsList.map((report) => (
                  <tr>
                    <td>{formatDate(report.sent_to_phi_at)}</td>
                    <td>
                      {report.sent_to_investor_expectation != "None"
                        ? formatDate(report.sent_to_investor_expectation)
                        : "-"}
                    </td>
                    {isUserPhi(userData) && (
                      <td>{formatDate(report.sent_to_investor_at)}</td>
                    )}
                    <td>{<StatusTag status={report.form_status} />}</td>
                    <td>
                      {report.approved_at
                        ? formatDate(report.approved_at)
                        : "-"}
                    </td>
                    <td>
                      <div className={styles.reportOptions}>
                        <Link
                          tabIndex={-1}
                          to={`/projects/${projectId}/reports/${report.id}`}
                        >
                          <button>Visualizar</button>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </section>
      </div>

      <Toast {...newReportToast} />

      <NewReportModal
        projectId={projectId}
        setShow={setShowNewReportModal}
        show={showNewReportModal}
        onSubmit={saveNewReport}
      />
    </>
  );
}
