import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Layout } from './components';
import { Login } from './components/Auth';
import { SignUp } from './components/Auth/SignUp';
import { PasswordReset } from './components/Auth/PasswordReset';
import { PasswordResetRequest } from './components/Auth/PasswordResetRequest';
import { Landing } from './components/Landing';
import { OrgsPage } from './components/OrgsPage';
import { ProjectPage } from './components/ProjectPage';
import { checkToken, get, getToken } from './utils/APIutils';
import { PermissionsPage } from './components/PermissionsPage';
import { DashboardsPage } from './components/DashboardsPage';
import { ImportDataPage } from './components/ImportDataPage';

function App() {
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userGroup, setUserGroup] = useState(null);

  const token = getToken();

  useEffect(() => {
    if(!!getToken() && (userData === null || reload)){
      setLoading(true);
      checkToken()
      .then(() => get("auth/user-data"))
      .then((response) => response.data)
      .then((userData) => setUserData(userData))
      .catch((error) => {
        console.log(error);
        setUserData(null);
      })
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
    }
  }, [userData, reload, token])

  useEffect(() => {
    if(!!getToken() && (userGroup === null || reload)){
      checkToken()
      .then(() => get("permissions/user_group"))
      .then((response) => response.data)
      .then((userGroup) => setUserGroup(userGroup))
      .catch((error) => {
        console.log(error);
        setUserGroup(null);
      })
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
    }
  }, [userGroup, reload, token])

  return (
    <Router>
      <Layout
        userData={userData}
        userGroup={userGroup}
        loadingUserData={loading}
      >
        <Switch>
          <Route path="/login">
            <Login
              setReload={setReload}
            />
          </Route>
          <Route path="/signup">
            <SignUp/>
          </Route>
          <Route path="/password-reset-request">
            <PasswordResetRequest/>
          </Route>
          <Route path="/password-reset/:id/:token"
            render={(props) =>
              <PasswordReset
                id={props.match.params.id}
                token={props.match.params.token}
              />
            }
          />
          <Route path="/projects">
            <ProjectPage
              userData={userData}
              userGroup={userGroup}
            />
          </Route>
          <Route path="/orgs">
            <OrgsPage
              userData={userData}
              userGroup={userGroup}
            />
          </Route>
          <Route path="/permissions">
            <PermissionsPage
              userData={userData}
              userGroup={userGroup}
            />
          </Route>
          {/* <Route path="/dashboards">
            <DashboardsPage
              userData={userData}
            />
          </Route> */}
          {/* <Route path="/import_data">
            <ImportDataPage
              userData={userData}
              userGroup={userGroup}
            />
          </Route> */}
          <Route path="/">
            <Landing
              userData={userData}
              userGroup={userGroup}
            />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
