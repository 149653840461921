import { useEffect, useReducer } from "react";
import {
  getFieldStandardValue,
  getFieldStandardError,
} from "../components/FormPage/Question/Question";
import { isUserInGroups } from "./permissionUtils";

const formActionTypes = {
  CHANGE_FIELD_VALUE: "CHANGE_FIELD_VALUE",
  CLEAR_FORM: "CLEAR_FORM",
};

function changeFieldValue(fieldName, value) {
  return {
    type: formActionTypes.CHANGE_FIELD_VALUE,
    payload: {
      fieldName,
      value,
    },
  };
}

function clearForm(initialState) {
  return {
    type: formActionTypes.CLEAR_FORM,
    payload: {
      initialState,
    },
  };
}

function formPageReducer(oldState, action) {
  const newState = { ...oldState };
  switch (action.type) {
    case formActionTypes.CHANGE_FIELD_VALUE:
      newState[action.payload.fieldName] = action.payload.value;
      return newState;
    case formActionTypes.CLEAR_FORM:
      return { ...action.payload.initialState };
    default:
      return newState;
  }
}

export function getValidationValue(form) {
  return Object.fromEntries(
    Object.values(form)
      .map((pageQuestions) =>
        pageQuestions.map((question) => [question.name, question.validationSchema])
      )
      .reduce((flatList, nextPageQuestionsList) =>
        flatList.concat(nextPageQuestionsList)
      )
  );
}

export function getInitialErrorValues(form) {
  return Object.fromEntries(
    Object.values(form)
      .map((pageQuestions) =>
        pageQuestions.map((question) => [
          question.name,
          getFieldStandardError(question.name),
        ])
      )
      .reduce((flatList, nextPageQuestionsList) =>
        flatList.concat(nextPageQuestionsList)
      )
  );
}

export function getInitialFormValues(form) {
  return Object.fromEntries(
    Object.values(form)
      .map((pageQuestions) =>
        pageQuestions.map((question) => [
          question.name,
          question.value ?? getFieldStandardValue(question.fieldType),
        ])
      )
      .reduce((flatList, nextPageQuestionsList) =>
        flatList.concat(nextPageQuestionsList)
      )
  );
}

export function collectFormApiSelectOptionFields(form) {
  return Object.values(form)
    .reduce((flatList, nextPageQuestionsList) =>
      flatList.concat(nextPageQuestionsList)
    )
    .filter((field) => field.fieldType === "apiselect");
}

export function collectTableApiSelectOptionFields(tableFields) {
  return tableFields.filter((field) => field.fieldType === "apiselect");
}

export function useForm(form, initialState = null) {
  const standardInitialValues =
    initialState === null ? getInitialFormValues(form) : initialState;

  const [formState, dispatch] = useReducer(
    formPageReducer,
    standardInitialValues
  );

  useEffect(() => {
    dispatch(clearForm(standardInitialValues));
  }, [initialState]);

  const handleChange = (question) => (value) =>
    dispatch(
      changeFieldValue(
        question.name,
        question.fieldType === "input" && question.inputType === "file"
          ? value //files[0]
          : value
      )
    );

  const clear = () => dispatch(clearForm(standardInitialValues));

  return { formState, handleChange, clear };
}

export function filterFormFieldsByUserGroup(userData, form) {
  return Object.fromEntries(
    Object.entries(form).map(([section, questions]) => [
      section,
      questions.filter(
        (field) => !field.groups || isUserInGroups(userData, field.groups)
      ),
    ])
  );
}
