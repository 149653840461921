import { useState } from "react";
import { validatePassword } from "../../../utils/ValidationUtils";
import { Input, Button } from "../../Forms";
import { noAuthPost } from "../../../utils/APIutils";


import styles from './styles.module.scss';
import { Redirect } from "react-router";

export default function PasswordReset({id, token}){
  const [redirect, setRedirect] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errors, setErrors] = useState("")
  const [error, setError] = useState("");

  const handlePasswordReset = () => {
    const postAnswers = {
      "new_password1" : password,
      "new_password2" : passwordConfirm,
      "uid" : id,
      "token": token
    };
    noAuthPost(`auth/password-reset/confirm/${id}/${token}`, postAnswers)
    .then(async (response) => {
      if(response.status !== 200){
        const errorMessage = response.data;
        setError(Object.entries(errorMessage).map(([key, value]) => `${key}: ${value}`).join("\n"));
      } else {
        setRedirect(true);
      }
    })
    .catch((err) => {
      setError(err);
    })
  }

  const validateAndReset = () => {
    const validations = validatePassword(password, passwordConfirm)
    const isFormValid = Object.values(validations).every((isValid) => isValid);
    if (isFormValid) {
      const errors = Object.fromEntries(Object.entries(validations).map(([key, value]) => [key, value]))
      setErrors(errors)
      handlePasswordReset()
    }
    else{
      const errors = Object.fromEntries(Object.entries(validations).map(([key, value]) => [key, value]))
      //TODO Perguntar para alguem se tem outro jeito mais pratico
      setErrors(errors)
    }
  };

  return(
    <div className={styles.wrapper}>
      <form
        className={styles.signup}
        onSubmit={(e) => e.preventDefault()}
      >
        <Input
          inputType="password"
          name="password1"
          id="password1"
          label="Nova senha"
          value={password}
          setValue={setPassword}
          error={errors.error}
        />
        <Input
          inputType="password"
          name="password2"
          id="password2"
          label="Confirme a nova senha"
          value={passwordConfirm}
          setValue={setPasswordConfirm}
        />
        <div className={styles.row}>
          <Button
            styleString="filled"
            className={styles.signup}
            onClick={() => validateAndReset()}
          >
            Redefinir senha
          </Button>
        </div>
      </form>
      {redirect &&
        <Redirect
          to="/login"
        />
      }
    </div>
  )
}