import { useEffect, useState } from "react";
import { Input } from "..";
import Error from '../Input/Error';

import styles from "./styles.module.scss";

export default function Select({
  name,
  id,
  value,
  setValue,
  placeholder,
  label,
  options,
  alt,
  editable,
  context,
  error
}) {
  const [altActive, setAltActive] = useState(false);
  const [altValue, _setAltValue] = useState("");
  const [internalValue, _setInternalValue] = useState(null);
  const internalSetValue = (value) => {
    _setInternalValue(value);
    const shouldActivateAlt = alt?.value && String(value) === String(alt.value);
    setAltActive(shouldActivateAlt);
  };
  const internalSetAltValue = (value) => {
    _setAltValue(value);
    if (altActive) setValue(value);
  };

  useEffect(() => {
    if (altActive) {
      setValue(altValue);
    }
  }, [altActive]);

  return (
    <div className={`inputContainer ${styles.inputContainer}`}>
      {label && (
        <div className={styles.label}>
          <label>{label}</label>
        </div>
      )}
      <select
        id={id}
        tabIndex={editable ? undefined : -1}
        name={altActive ? undefined : name}
        onChange={(evt) => {
          if (editable !== false) {
            internalSetValue(evt.target.value);
            setValue?.(evt.target.value);
          }
        }}
        value={altActive ? internalValue : value}
        placeholder={placeholder}
        readOnly={editable === false ? true : undefined}
      >
        <option label="selecione" value={null} hidden />
        {options.map((option) => (
          <option
            key={option.label}
            label={option.label}
            value={option.value}
          />
        ))}
      </select>

      {altActive && (
        <Input
          {...alt.field}
          value={altValue}
          setValue={internalSetAltValue}
          editable={editable}
        />
      )}
      {error && <Error error={error} />}
    </div>
  );
}
