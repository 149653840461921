import { useState } from "react";
import { validateEmail } from "../../../utils/ValidationUtils";
import { Input, Button } from "../../Forms";
import { noAuthPost } from "../../../utils/APIutils";


import styles from './styles.module.scss';
import { Redirect } from "react-router";

export default function PasswordResetRequest(){
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("")
  const [error, setError] = useState("");

  const handlePasswordResetRequest = () => {
    const postAnswers = {
      "email" : email
    };
    noAuthPost(`auth/password/reset`, postAnswers)
    .then(async (response) => {
      if(response.status !== 200){
        const errorMessage = response.data;
        setError(Object.entries(errorMessage).map(([key, value]) => `${key}: ${value}`).join("\n"));
      } else {
        setRedirect(true);
      }
    })
    .catch((err) => {
      setError(err);
    })
  }

  const validateAndResetRequest = () => {
    const validations = validateEmail(email)
    const isFormValid = Object.values(validations).every((isValid) => isValid);
    if (isFormValid) {
      const errors = Object.fromEntries(Object.entries(validations).map(([key, value]) => [key, value.error]))
      setErrors(errors)
      handlePasswordResetRequest()
    }
    else{
      const errors = Object.fromEntries(Object.entries(validations).map(([key, value]) => [key, value.error]))
      //TODO Perguntar para alguem se tem outro jeito mais pratico
      setErrors(errors)
    }
  };

  return(
    <div className={styles.wrapper}>
      <form
        className={styles.signup}
        onSubmit={(e) => e.preventDefault()}
      >
        <Input
          inputType="text"
          name="email"
          id="email"
          label="E-mail"
          value={email}
          setValue={setEmail}
          error={errors.email}
        />
        <div className={styles.row}>
          <Button
            styleString="filled"
            className={styles.signup}
            onClick={() => validateAndResetRequest()}
          >
            Enviar email de reset de senha
          </Button>
        </div>
      </form>
      {redirect &&
        <Redirect
          to="/login"
        />
      }
    </div>
  )
}