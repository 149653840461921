import { Route, Switch } from "react-router";
import { ProjectReportsForm } from "./ProjectReportsForm";
import { ProjectReportsList } from "./ProjectReportsList";

export default function ProjectReports({ userData }) {
  return (
    <Switch>
      <Route
        path="/projects/:projectId/reports/new"
        render={(props) => (
          <ProjectReportsForm
            projectId={props.match.params.projectId}
            reportId={undefined}
            userData={userData}
          />
        )}
      />

      <Route
        path="/projects/:projectId/reports/:reportId"
        render={(props) => (
          <ProjectReportsForm
            projectId={props.match.params.projectId}
            reportId={props.match.params.reportId}
            userData={userData}
          />
        )}
      />

      <Route
        path="/projects/:projectId/reports"
        render={(props) => (
          <ProjectReportsList
            projectId={props.match.params.projectId}
            userData={userData}
          />
        )}
      />
    </Switch>
  );
}
