import { useState } from "react";
import {
  validateSignUp,
  translateErrorMessage,
} from "../../../utils/ValidationUtils";
import { Input, Button } from "../../Forms";
import { noAuthPost } from "../../../utils/APIutils";
import { Toast } from "../../Toast";
import { infoLevels, userAgreement } from "../../../constants";

import styles from "./styles.module.scss";
import { Redirect } from "react-router";
import { ModalLayout } from "../../Modal";
import { ReactSVG } from "react-svg";

export default function SignUp() {
  const [redirect, setRedirect] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showToast, setShowToast] = useState(false);
  const signUpAnswers = {
    firstName,
    lastName,
    email,
    username,
    password,
    passwordConfirm,
  };
  const [errors, setErrors] = useState("");
  const [requestError, setRequestError] = useState("");
  const [showTerms, setShowTerms] = useState(false);

  const handleSignUp = () => {
    const postAnswers = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      username: username,
      password1: password,
      password2: passwordConfirm,
    };
    noAuthPost("auth/registration", postAnswers)
      .then(async (response) => {
        if (response.status !== 201) {
          const errorMessage = response.data;
          setRequestError(
            Object.entries(errorMessage)
              .map(([key, value]) => `${key}: ${value}`)
              .join("\n")
          );
          console.log(requestError);
        } else {
          setRedirect(true);
        }
      })
      .catch((err) => {
        setRequestError(err?.response?.data);
        setShowToast(true);
      });
  };

  const validateAndSignUp = () => {
    const validations = validateSignUp(signUpAnswers);
    const isFormValid = Object.values(validations).every(
      (field) => field.valid
    );

    if (isFormValid) {
      const errors = Object.fromEntries(
        Object.entries(validations).map(([key, value]) => [key, value.error])
      );
      setErrors(errors);
      handleSignUp();
    } else {
      const errors = Object.fromEntries(
        Object.entries(validations).map(([key, value]) => [key, value.error])
      );
      //TODO Perguntar para alguem se tem outro jeito mais pratico
      setErrors(errors);
    }
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.signup} onSubmit={(e) => e.preventDefault()}>
        <ModalLayout
          title={"Termos de uso"}
          show={showTerms}
          setShow={setShowTerms}
        >
          <div className={styles.userAgreementModal}>
            {userAgreement.description.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
            <br />
            <h3>Benefícios</h3>
            <ul>
              {userAgreement.benefits.map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
            <div className={styles.checkMark}>
              <ReactSVG src={"/icons/check_box_24px.svg"} />
              <span>{userAgreement.dataUse}</span>
            </div>
            <div className={styles.checkMark}>
              <ReactSVG src={"/icons/check_box_24px.svg"} />
              <span>{userAgreement.imageUse}</span>
            </div>
          </div>
        </ModalLayout>
        <Input
          inputType="text"
          name="firstName"
          id="firstName"
          label="Nome"
          value={firstName}
          setValue={setFirstName}
          error={errors.firstName}
          editable={true}
        />
        <Input
          inputType="text"
          name="lastName"
          id="lastName"
          label="Sobrenome"
          value={lastName}
          setValue={setLastName}
          error={errors.lastName}
          editable={true}
        />
        <Input
          inputType="text"
          name="username"
          id="username"
          label="Usuário"
          value={username}
          setValue={setUsername}
          error={errors.username}
          editable={true}
        />
        <Input
          inputType="text"
          name="email"
          id="email"
          label="E-mail"
          value={email}
          setValue={setEmail}
          error={errors.email}
          editable={true}
        />
        <Input
          inputType="password"
          name="password1"
          id="password1"
          label="Senha"
          value={password}
          setValue={setPassword}
          error={errors.password}
          editable={true}
        />
        <Input
          inputType="password"
          name="password2"
          id="password2"
          label="Confirme sua senha"
          value={passwordConfirm}
          setValue={setPasswordConfirm}
          editable={true}
        />
        <div className={styles.useragreement}>
          Ao clicar neste botão você concorda com os
          <button
            className={styles.terms}
            type="button"
            onClick={() => setShowTerms(true)}
          >
            termos de serviço
          </button>
        </div>
        <div className={styles.row}>
          <Button
            styleString="filled"
            className={styles.signup}
            onClick={() => validateAndSignUp()}
          >
            criar conta
          </Button>
        </div>
      </form>
      <Toast
        show={showToast}
        setShow={setShowToast}
        title="Erro de autenticação"
        infoLevel={infoLevels.ERROR}
      >
        {translateErrorMessage(
          Object.entries(requestError)
            .map(([key, value]) => `${key}: ${value}`)
            .join("\n")
        )}
      </Toast>
      {redirect && <Redirect to="/login" />}
    </div>
  );
}
