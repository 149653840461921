import { useState, useEffect, useMemo, useCallback } from "react";
import { Question } from "./Question";
import { Button } from "../Forms";
import { scrollElementIntoView } from "./FormPageUtils";
import { Redirect } from "react-router-dom";
import { checkToken } from "../../utils/APIutils";
import { Formik, Form } from "formik";
import {
  getInitialFormValues,
  getValidationValue,
  getInitialErrorValues,
  collectFormApiSelectOptionFields,
} from "../../utils/useForm";
import { StatusLabel } from "./StatusLabel";
import * as Yup from "yup";

import styles from "./styles.module.scss";
import { useMultipleApiOptions } from "../../utils/useApiOptions";
import { isUserPhi } from "../../utils/permissionUtils";

function accessError(errors, questionName){
  return questionName.split('.').reduce((obj, key) => obj?.[key], errors);
}

export default function FormPage({
  title,
  name,
  pages,
  form,
  initialState,
  document,
  onSave,
  onSubmit,
  editable,
  reload,
  setReload,
  context,
  userData,
}) {
  const [redirect, setRedirect] = useState(false);
  const [pageNames] = useState(() => Object.keys(pages));
  const [currentPage, _setCurrentPage] = useState(
    pageNames ? pageNames[0] : null
  );
  const setCurrentPage = useCallback((newCurrentPage) => {
    _setCurrentPage(newCurrentPage);
    scrollElementIntoView(newCurrentPage);
  }, []);

  const [standardInitialValues, setStandardInitialValues] = useState(() =>
    initialState === null ? getInitialFormValues(form) : initialState
  );

  const apiSelectFields = useMemo(
    () => collectFormApiSelectOptionFields(form),
    [form]
  );
  const { options: apiOptions, reloadOptions } = useMultipleApiOptions(
    apiSelectFields,
    context
  );

  const validationSchema = useMemo(
    () => Yup.object().shape(getValidationValue(form)),
    [form]
  );

  const standardInitialErrors = useMemo(
    () => (initialState === null ? getInitialErrorValues(form) : undefined),
    [initialState]
  );

  useEffect(() => {
    setStandardInitialValues(
      initialState === null ? getInitialFormValues(form) : initialState
    );
  }, [initialState]);

  useEffect(() => {
    checkToken()
      .then((resp) => redirect && setRedirect(false))
      .catch((err) => setRedirect(true));
  }, []);

  const showSendToAnalysis = useMemo(() => !isUserPhi(userData), [userData]);

  return redirect ? (
    <Redirect to="/login" />
  ) : (
    <div className={styles.wrapper}>
      {title && <h2>{title}</h2>}
      <div
        className={`${styles.content} ${
          document ? styles.multipleChildren : ""
        }`}
      >
        {pages && pageNames.length > 1 && (
          <ol
            id="form-navigation"
            className={`thin-scrollbar ${styles.formNavigation}`}
          >
            {pageNames.map((page) => (
              <li
                key={page}
                className={page === currentPage ? styles.selected : ""}
              >
                <button
                  onClick={() => {
                    setCurrentPage(page);
                  }}
                >
                  {pages[page].label}
                </button>
              </li>
            ))}
          </ol>
        )}
        <div className={styles.formWrapper}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={standardInitialValues}
            initialErrors={standardInitialErrors}
            onSubmit={onSubmit}
          >
            {({ values, ...formikProps }) => {
              const validData = Object.values(formikProps.errors).length === 0;
              console.log(values);
              return (
                <>
                  <div className={styles.editingWrapper}>
                    {initialState?.form_status && (
                      <StatusLabel status={initialState.form_status} />
                    )}
                  </div>
                  {editable && (
                    <div className={styles.controls}>
                      <Button
                        styleString="outlined"
                        type="button"
                        disabled={
                          currentPage === Object.keys(pages)[0]
                            ? true
                            : undefined
                        }
                        onClick={() =>
                          setCurrentPage(
                            Object.keys(pages)[
                              Object.keys(pages).findIndex(
                                (page) => page === currentPage
                              ) - 1
                            ]
                          )
                        }
                      >
                        Anterior
                      </Button>
                      <Button
                        styleString="outlined"
                        type="button"
                        disabled={!validData}
                        onClick={() => onSave(values)}
                      >
                        Salvar
                      </Button>
                      <Button
                        type="button"
                        styleString="outlined"
                        disabled={
                          currentPage ===
                          Object.keys(pages)[Object.keys(pages).length - 1]
                            ? true
                            : undefined
                        }
                        onClick={() =>
                          setCurrentPage(
                            Object.keys(pages)[
                              Object.keys(pages).findIndex(
                                (page) => page === currentPage
                              ) + 1
                            ]
                          )
                        }
                      >
                        Próxima
                      </Button>
                      {showSendToAnalysis && (
                        <Button
                          styleString="filled"
                          type="submit"
                          value="Submit"
                          disabled={!validData}
                          form={name}
                        >
                          Enviar para análise
                        </Button>
                      )}
                    </div>
                  )}
                  <Form id={name} className={`thin-scrollbar`}>
                    {pageNames.map((page) => (
                      <div
                        key={page}
                        id={page.toLowerCase()}
                        className={`${styles.formPage} ${
                          page === currentPage ? styles.selected : ""
                        }`}
                      >
                        {pages[page].label && (
                          <h3 id={page}>{pages[page].label.toUpperCase()}</h3>
                        )}
                        {form[page].map((question, index) => (
                          <Question
                            key={question.name}
                            value={values[question.name]}
                            editable={editable}
                            context={context}
                            formValues={values}
                            error={accessError(formikProps.errors, question.name)}
                            apiOptions={apiOptions}
                            reloadOptions={reloadOptions}
                            {...question}
                          />
                        ))}
                      </div>
                    ))}
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>

      {document && (
        <div className={styles.preview}>
          <p>preview</p>
        </div>
      )}
    </div>
  );
}
