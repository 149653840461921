import { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { get } from "../../../utils/APIutils";
import { Loading } from "../../Loading";
import { formStatus, isAnyStatus } from "../../../constants/options";
import { StatusTag } from "../../FormPage/StatusLabel";
import { ReactSVG } from "react-svg";

import styles from "./styles.module.scss";

export default function OrgRoadmap({}) {
  const [loading, setLoading] = useState(false);
  const [orgRoadmap, setOrgRoadmap] = useState(null);
  const [hasOrg, setHasOrg] = useState(true);

  const pendingSectionEntries = useMemo(
    () =>
      orgRoadmap !== null &&
      Object.entries(orgRoadmap).filter(
        ([key, value]) => key.includes("status") && isSectionPending(value)
      ),
    [orgRoadmap]
  );

  const loadOrgRoadmap = useCallback(() => {
    setLoading(true);
    get("roadmaps/orgs")
      .then((response) => setOrgRoadmap(response.data))
      .catch((error) => {
        if (error.response.status === 404) {
          setHasOrg(false);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!orgRoadmap) {
      loadOrgRoadmap();
    }
  }, [loadOrgRoadmap, orgRoadmap]);

  if (loading) {
    return <Loading />;
  }

  if (!hasOrg) {
    return (
      <div className={styles.roadmapPanel}>
        <p>
          Você ainda não registrou sua organização. Clique
          <span>
            {" "}
            <Link to="/orgs">aqui</Link>{" "}
          </span>
          para começar.
        </p>
      </div>
    );
  }

  if (orgRoadmap !== null && orgRoadmap.basic_data_status !== null) {
    return (
      <div className={styles.roadmapPanel}>
        {pendingSectionEntries?.length > 0 ? (
          <>
            <h3>{`Organização: ${orgRoadmap.org_name ?? ""}`}</h3>
            <p>As seguintes seções da sua organização precisam de atenção:</p>
            <ul className={styles.pendingSectionsList}>
              {pendingSectionEntries.map(([statusFieldName, status]) => (
                <li key={statusFieldName}>
                  <span className={styles.sectionName}>
                    <Link
                      to={sectionPathsByStatusFieldName(
                        orgRoadmap.id,
                        statusFieldName
                      )}
                    >
                      <ReactSVG src="/icons/launch_24px.svg" />
                      {sectionNamesByStatusFieldName[statusFieldName]}
                    </Link>
                  </span>
                  <StatusTag status={status} />
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p> As informações da sua organização estão em dia </p>
        )}
      </div>
    );
  }

  return <></>;
}

const sectionNamesByStatusFieldName = {
  basic_data_status: "Dados básicos",
  profile_data_status: "Perfil",
  transparency_data_status: "Transparência",
  solidity_data_status: "Solidez",
  management_data_status: "Gestão",
  impact_potential_data_status: "Potencial de impacto",
  documents_status: "Documentos",
};

function sectionPathsByStatusFieldName(orgId, statusFieldName) {
  return {
    basic_data_status: `/orgs/${orgId}`,
    profile_data_status: `/orgs/${orgId}/profile`,
    transparency_data_status: `/orgs/${orgId}/transparency`,
    solidity_data_status: `/orgs/${orgId}/solidity`,
    management_data_status: `/orgs/${orgId}/management`,
    impact_potential_data_status: `/orgs/${orgId}/impact_potential`,
    documents_status: `/orgs/${orgId}/documents`,
  }[statusFieldName];
}

function isSectionStatusPending(status) {
  return isAnyStatus(
    status,
    formStatus.Incompleto,
    formStatus.Desatualizado,
    formStatus.Editável
  );
}

function isSectionPending(status) {
  return status === null || isSectionStatusPending(status);
}
