import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import {
  getFormStatusLabel,
  translateBool,
} from "../../../../constants/options";

Font.register({
  family: "Open Sans",
  src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Open Sans",
  src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
  fontStyle: "normal",
  fontWeight: "semibold",
});
Font.register({
  family: "Open Sans",
  src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
  fontStyle: "normal",
  fontWeight: "bold",
});

const PDFStyles = StyleSheet.create({
  formPage: {
    fontFamily: "Open Sans",
    backgroundColor: "#f6f6f6",
  },
  header: {
    display: "block",
    fontWeight: "bold",
    fontSize: "14pt",
    marginBlockStart: "12pt",
    marginBlockEnd: "12pt",
    marginInlineStart: "0pt",
    marginInlineEnd: "0pt",
  },
  questionWrapper: {
    backgroundColor: "white",
    padding: "9.6pt",
    margin: "9.6pt",
    borderRadius: "1.5pt",
    boxShadow: "0 0 6pt 0 rgb(0 0 0 / 5%)",
    marginBottom: "6pt",
    fontSize: "12pt",
    display: "flex",
  },
  table: {
    fontSize: "10.5pt",
    borderWidth: 2,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tableHeader: {
    fontWeight: "bold",
  },
  tableHeaderText: {},
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  tableCell: {
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    flexWrap: "wrap",
    padding: "6pt",
  },
  question: {
    margin: "0",
    fontWeight: "semibold",
    marginBottom: "9.6pt",
    fontSize: "13.7pt",
  },
  answer: {
    padding: "4.5pt",
    border: "0.8pt solid #ccc",
    borderRadius: "0.4pt",
    borderTopLeftRadius: "4pt",
    borderTopRightRadius: "4pt",
    borderBottomLeftRadius: "4pt",
    borderBottomRightRadius: "4pt",
    fontSize: "10.5pt",
    flexGrow: "1",
  },
  page: {
    backgroundColor: "#f6f6f6",
    margin: "5pt",
  },
  section: {
    backgroundColor: "white",
  },
});

export const PlanningPDF = (planningData) => () =>
  (
    <Document>
      <Page size="A4" style={PDFStyles.formPage}>
        <View style={PDFStyles.page}>
          <Text style={PDFStyles.header}>APRESENTAÇÃO</Text>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>Status</Text>
            <Text style={PDFStyles.answer}>
              {getFormStatusLabel(planningData.form_status)}
            </Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>O que é o projeto?</Text>
            <Text style={PDFStyles.answer}>{planningData.project.what}</Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>Como funciona?</Text>
            <Text style={PDFStyles.answer}>{planningData.project.how}</Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>Por que precisa acontecer?</Text>
            <Text style={PDFStyles.answer}>{planningData.project.why}</Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>
              Pré-requisitos para os atendidos:
            </Text>
            <Text style={PDFStyles.answer}>{planningData.project.prerequisites}</Text>
          </View>
          <Text style={PDFStyles.header} break>
            COLABORADORES
          </Text>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>Lista de colaboradores</Text>
            <View style={PDFStyles.table}>
              <View
                style={[PDFStyles.tableRow, PDFStyles.tableHeader]}
                wrap={false}
              >
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Nome
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Cargo
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Nível de escolaridade
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Relação com o projeto
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Colaborador pago com recursos do projeto?
                </Text>
              </View>
              {planningData.collaborators?.map((field) => (
                <View style={[PDFStyles.tableRow]} wrap={false}>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {field.name}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {field.position}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {field.education_level}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {field.relationship}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {translateBool(
                      field.paid_with_project_resources.toString()
                    )}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <Text style={PDFStyles.header} break>
            REGIÃO
          </Text>
          <View style={PDFStyles.questionWrapper} wrap={false}>
            <Text style={PDFStyles.question}>
              Descreva a região onde está localizado o projeto:
            </Text>
            <Text style={PDFStyles.answer}>{planningData.region}</Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>Qual o IDH da região?</Text>
            <Text style={PDFStyles.answer}>{planningData.region_idh}</Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>
              Nome da comunidade/bairro e da cidade:
            </Text>
            <Text style={PDFStyles.answer}>{planningData.district}</Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>
              Número de habitantes da comunidade/bairro:
            </Text>
            <Text style={PDFStyles.answer}>
              {planningData.district_population}
            </Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>
              IVM da região atendida pelo projeto:
            </Text>
            <Text style={PDFStyles.answer}>{planningData.district_ivm}</Text>
          </View>
          <Text style={PDFStyles.header} break>
            PÚBLICO DO PROJETO
          </Text>
          <View style={PDFStyles.questionWrapper} wrap={false}>
            <Text style={PDFStyles.question}>Perfil dos participantes:</Text>
            <Text style={PDFStyles.answer}>
              {planningData.participants_profile}
            </Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>Quantidade de participantes:</Text>
            <Text style={PDFStyles.answer}>
              {planningData.participants_amount}
            </Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>
              Quantidade inicial de inscritos:
            </Text>
            <Text style={PDFStyles.answer}>
              {planningData.participants_initial_amount}
            </Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>
              Meios de inscrição/seleção do público:
            </Text>
            <Text style={PDFStyles.answer}>
              {planningData.participant_entry_method}
            </Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>
              Meios de contagem de público:
            </Text>
            <Text style={PDFStyles.answer}>
              {planningData.participant_accounting_method}
            </Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>
              Renda média da população dos municípios atendidos:
            </Text>
            <Text style={PDFStyles.answer}>
              {planningData.average_municipality_income}
            </Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>
              Renda média de beneficiados pelo projeto:
            </Text>
            <Text style={PDFStyles.answer}>
              {planningData.average_participant_income}
            </Text>
          </View>
          <Text style={PDFStyles.header} break>
            ATIVIDADES DO PROJETO
          </Text>
          <View style={PDFStyles.questionWrapper} wrap={false}>
            <Text style={PDFStyles.question}>
              Lista de atividades do projeto
            </Text>
            <View style={PDFStyles.table}>
              <View
                style={[PDFStyles.tableRow, PDFStyles.tableHeader]}
                wrap={false}
              >
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Data planejada
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "550pt" },
                  ]}
                >
                  Descrição
                </Text>
              </View>
              {planningData.activities?.map((field) => (
                <View style={[PDFStyles.tableRow]} wrap={false}>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {field.planned_month}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "550pt" }]}>
                    {field.activity_description}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <Text style={PDFStyles.header} break>
            MÉTRICAS
          </Text>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>Métricas de avaliação</Text>
            <View style={PDFStyles.table}>
              <View
                style={[PDFStyles.tableRow, PDFStyles.tableHeader]}
                wrap={false}
              >
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Objetivo
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Indicador
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Resultado quantitativo
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Método de avaliação dos resultados
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Método de verificação dos resultados
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "150pt" },
                  ]}
                >
                  Método alternativo de verificação dos resultados
                </Text>
              </View>
              {planningData.indicators?.map((field) => (
                <View style={[PDFStyles.tableRow]} wrap={false}>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {field.objective}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {field.indicator}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {field.quantitative_result}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {field.result_evaluation}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {field.verification_methods_1}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "150pt" }]}>
                    {field.verification_methods_2}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <Text style={PDFStyles.header} break>
            INVESTIMENTOS
          </Text>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>Investimentos do projeto</Text>
            <View style={PDFStyles.table}>
              <View
                style={[PDFStyles.tableRow, PDFStyles.tableHeader]}
                wrap={false}
              >
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "155pt" },
                  ]}
                  wrap={false}
                >
                  Tipo de investimento
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "180pt" },
                  ]}
                  wrap={false}
                >
                  Descrição do item
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "70pt" },
                  ]}
                  wrap={false}
                >
                  Preço unitário
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "70pt" },
                  ]}
                  wrap={false}
                >
                  Unidades
                </Text>
                <Text
                  style={[
                    PDFStyles.tableHeaderText,
                    PDFStyles.tableCell,
                    { width: "90pt" },
                  ]}
                  wrap={false}
                >
                  Preço total
                </Text>
              </View>
              {planningData.investments?.map((field) => (
                <View style={[PDFStyles.tableRow]} wrap={false}>
                  <Text style={[PDFStyles.tableCell, { width: "155pt" }]}>
                    {field.investment_type}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "180pt" }]}>
                    {field.item_description}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "70pt" }]}>
                    {field.unit_price}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "70pt" }]}>
                    {field.amount}
                  </Text>
                  <Text style={[PDFStyles.tableCell, { width: "90pt" }]}>
                    {field.total_price}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question}>Total dos investimentos</Text>
            <Text style={PDFStyles.answer}>{planningData.total_investment}</Text>
          </View>
          <Text style={PDFStyles.header}>LEI DE INCENTIVO</Text>
          <View style={PDFStyles.questionWrapper} wrap={false}>
            <Text style={PDFStyles.question} wrap={false}>
              Descrição da contrapartida
            </Text>
            <Text style={PDFStyles.answer} wrap={false}>
              {planningData.consideration_description}
            </Text>
          </View>
          <View style={PDFStyles.questionWrapper}>
            <Text style={PDFStyles.question} wrap={false}>
              Quantidade prevista
            </Text>
            <Text style={PDFStyles.answer} wrap={false}>
              {planningData.consideration_amount}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
