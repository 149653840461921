import { useEffect, useMemo } from "react";

function deepFilterEntries(data, test, keyString = "") {
  let filteredEntries = [];
  let entries = [];

  if (data instanceof Array) {
    entries = data.map((value, key) => [key, value]);
  } else if (data instanceof Object) {
    entries = Object.entries(data);
  }

  entries.forEach(([key, value]) => {
    const nextKeyString = (keyString ? [keyString, key] : [key]).join("__");
    if (test(key, value)) {
      filteredEntries = filteredEntries.concat([[nextKeyString, value]]);
    } else if (value instanceof Object || value instanceof Array) {
      filteredEntries = filteredEntries.concat(
        deepFilterEntries(value, test, nextKeyString)
      );
    }
  });

  return filteredEntries;
}

export function flattenAndFilterEntries(data, test) {
  const filteredEntries = deepFilterEntries(data, test);
  return Object.fromEntries(filteredEntries);
}

export function filterFiles(data) {
  return flattenAndFilterEntries(data, (key, value) => value instanceof File);
}

export function fileToValue(fileObject) {
  return {
    file_name: fileObject.name,
    content: fileObject,
  };
}

const imageFileExtensions = ["jpg", "jfif", "png", "bmp", "svg"];

export function hasImageExtension(fileName) {
  const fileExtension = fileName.split(".").slice(-1)[0];
  return !!imageFileExtensions.find((ext) => ext === fileExtension);
}

export function hasPdfExtension(fileName) {
  const fileExtension = fileName.split(".").slice(-1)[0];
  return fileExtension === "pdf";
}

export function useFileContentUrl(fileContent) {
  const fileSource = useMemo(() => {
    if (typeof fileContent === "string") {
      return fileContent;
    }

    if (fileContent instanceof File) {
      const objectUrl = URL.createObjectURL(fileContent);
      return objectUrl;
    }

    return undefined;
  }, [fileContent]);

  useEffect(() => {
    return () => {
      if (fileContent instanceof File) {
        URL.revokeObjectURL(fileSource);
      }
    };
  }, [fileContent, fileSource]);

  return fileSource;
}
