import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import { infoLevels } from "../../../constants";
import { formStatus, isAnyStatus } from "../../../constants/options";
import {
  dataForm,
  dataFormPages,
  impactPotentialForm,
  impactPotentialFormPages,
  managementForm,
  managementFormPages,
  profileForm,
  profileFormPages,
  solidityForm,
  solidityFormPages,
  transparencyForm,
  transparencyFormPages,
  documentsForm,
  documentsFormPages,
} from "../../../constants/orgsForm";
import { get, post, put, patch } from "../../../utils/APIutils";
import { filterFormFieldsByUserGroup } from "../../../utils/useForm";
import { isUserInGroups, isUserPhi } from "../../../utils/permissionUtils";
import { ConditionsEnforcer } from "../../Auth/ConditionsEnforcer";
import { FormPage } from "../../FormPage";
import { Toast, useToast } from "../../Toast";

import styles from "./styles.module.scss";
import UserGroups from "../../../constants/userGroups";
import { ReactSVG } from "react-svg";

export const sections = {
  profile: {
    title: "Perfil",
    name: "profile",
    form: profileForm,
    pages: profileFormPages,
    source: "profile",
  },
  management: {
    title: "Gestão",
    name: "management",
    form: managementForm,
    pages: managementFormPages,
    source: "management",
  },
  transparency: {
    title: "Transparência",
    name: "transparency",
    form: transparencyForm,
    pages: transparencyFormPages,
    source: "transparency",
  },
  solidity: {
    title: "Solidez",
    name: "solidity",
    form: solidityForm,
    pages: solidityFormPages,
    source: "solidity",
  },
  impact_potential: {
    title: "Potencial de impacto",
    name: "impact_potential",
    form: impactPotentialForm,
    pages: impactPotentialFormPages,
    source: "impact-potential",
  },
  documents: {
    title: "Documentos",
    name: "documents",
    form: documentsForm,
    pages: documentsFormPages,
    source: "documents",
  },
};

export default function OrgDetail({ orgId, userData }) {
  const [orgDetail, setOrgDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useToast();
  const location = useLocation();
  const lastSlug = location.pathname.split("/").pop();

  const userHasOrg = Boolean(
    !!userData && userData.groups[0] !== UserGroups.noOrg
  );

  const loadOrgDetail = useCallback(() => {
    setLoading(true);
    get(`orgs/${orgId}`)
      .then((response) => response.data)
      .then((data) => setOrgDetail(data))
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response?.status),
        });
      })
      .finally(() => setLoading(false));
  }, [orgId, setToast]);

  useEffect(() => {
    if (orgDetail === null && userHasOrg) {
      loadOrgDetail();
    }
  }, [loadOrgDetail, orgDetail, userHasOrg]);

  return (
    <div className={styles.wrapper}>
      {orgDetail?.org_validated ? (
        <div className={styles.statusWrapper}>
          <ReactSVG src="/icons/check_circle_24px.svg" />
          <div className={styles.validated}>Organização validada!</div>
        </div>
      ) : (
        <div className={styles.statusWrapper}>
          <ReactSVG src="/icons/cancel_circle_24px.svg" />
          <div className={styles.notValidated}>
            Organização pendente de validação
          </div>
        </div>
      )}
      {orgId && orgDetail && (
        <div className={styles.tabs}>
          <Link tabIndex={-1} to={`/orgs/${orgId}`}>
            <button className={lastSlug == orgId ? styles.selected : ""}>
              Dados da organização
            </button>
          </Link>
          {Object.entries(sections).map(([sectionKey, sectionData]) => (
            <Link
              tabIndex={-1}
              key={sectionKey}
              to={`/orgs/${orgId}/${sectionKey}`}
            >
              <button
                className={lastSlug === sectionData.name ? styles.selected : ""}
              >
                {sectionData.title}
              </button>
            </Link>
          ))}
        </div>
      )}
      <div className={styles.formPageWrapper}>
        {orgId && orgDetail ? (
          <Switch>
            <Route
              path={`/orgs/${orgId}/:sectionKey`}
              render={(props) => (
                <OrgSection
                  key={props.match.params.sectionKey}
                  orgId={orgId}
                  sectionKey={props.match.params.sectionKey}
                  userData={userData}
                  editable={true}
                />
              )}
            />
            <Route path={`/orgs/${orgId}`}>
              <ConditionsEnforcer
                conditions={[
                  (isUserInGroups(userData, [4, 5]),
                  userData?.org && String(userData.org) === orgId) ||
                    isUserInGroups(userData, [1, 2, 3, 7]),
                ]}
                fallbackTo={
                  userData?.org ? `/orgs/${userData.org}` : "/orgs/new"
                }
              >
                <OrgData orgId={orgId} userData={userData} />
              </ConditionsEnforcer>
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path={`/orgs/new`}>
              <ConditionsEnforcer
                conditions={[isUserInGroups(userData, [1, 2, 3, 6, 7])]}
                fallbackTo={
                  userData?.org ? `/orgs/${userData.org}` : "/dashboard"
                }
              >
                <OrgData orgId={null} userData={userData} />
              </ConditionsEnforcer>
            </Route>
          </Switch>
        )}
      </div>

      <Toast {...toast} />

      <Toast show={loading} loading={loading} />
    </div>
  );
}

function OrgData({ orgId, userData }) {
  const [filteredForm, setFilteredForm] = useState(() =>
    filterFormFieldsByUserGroup(userData, dataForm)
  );
  const [orgData, setOrgData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useToast();
  const [reload, setReload] = useState(false);

  const loadOrgData = () => {
    setLoading(true);
    get(`orgs/${orgId}`)
      .then((response) => response.data)
      .then((data) => {
        setOrgData(data);
      })
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response?.status),
        });
        if (error.response?.status === 404) setOrgData(undefined);
      })
      .finally(() => setLoading(false));
  };

  const submitForAnalysis = (orgId) => {
    return patch(`orgs/${orgId}/status`, {
      status: formStatus["Análise pendente"],
    });
  };

  const handleSaveNew = (formData) => {
    return post(`orgs`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then((response) => {
      if (response.status < 300) {
        return response.data.id;
      } else {
        throw new Error(response);
      }
    });
  };

  const handleSaveUpdate = (formData) => {
    return put(`orgs/${orgId}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then(() => {
      setToast({
        show: true,
        infoLevel: infoLevels.SUCCESS,
        children: "Alteração feita com sucesso",
      });
      loadOrgData();
      return orgId;
    });
  };

  const handleSave = (formData) => {
    setLoading(true);
    return (
      orgId === null ? handleSaveNew(formData) : handleSaveUpdate(formData)
    )
      .then((responseOrgId) => {
        if (orgId === null) {
          window.location.assign(`/${responseOrgId}`);
          window.location.reload();
        } else {
          loadOrgData();
        }
      })
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response?.status),
        });
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (formData) => {
    setLoading(true);
    return (
      orgId === null ? handleSaveNew(formData) : handleSaveUpdate(formData)
    )
      .then((responseOrgId) => submitForAnalysis(responseOrgId))
      .then((response) => {
        window.location.assign(`/${response.data.id}`);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response?.status),
        });
      })
      .finally(() => setLoading(false));
  };

  useState(() => {
    if (orgData === null && orgId !== null) {
      loadOrgData();
    }
  }, [orgId]);

  useEffect(() => {
    setFilteredForm(filterFormFieldsByUserGroup(userData, dataForm));
  }, [orgId, userData]);

  const editable = useMemo(() => {
    return Boolean(
      !orgId ||
        (orgData !== null &&
          (isUserPhi(userData) ||
            isAnyStatus(
              orgData.form_status,
              formStatus["Desatualizado"],
              formStatus["Editável"],
              formStatus["Incompleto"]
            )))
    );
  }, [orgId, orgData, userData]);

  useEffect(() => {
    if (reload || (orgId && orgData === null)) {
      loadOrgData();
      setReload(false);
    }
  }, [reload]);

  return (
    <div>
      {(!orgId || (orgId && orgData)) && (
        <FormPage
          name="orgDataForm"
          form={filteredForm}
          pages={dataFormPages}
          initialState={orgData}
          editable={editable}
          reload={reload}
          setReload={setReload}
          onSubmit={handleSubmit}
          onSave={handleSave}
          context={{ projectId: 0 }}
          userData={userData}
        />
      )}

      <Toast {...toast} />

      <Toast show={loading} loading={loading} />
    </div>
  );
}

const contentTypeBySection = {
  transparency: "application/json",
  management: "application/json",
  profile: "application/json",
  solidity: "application/json",
  impact_potential: "application/json",
  documents: "multipart/form-data",
};

function OrgSection({ orgId, sectionKey, userData }) {
  const [filteredForm, setFilteredForm] = useState(() =>
    filterFormFieldsByUserGroup(userData, sections[sectionKey].form)
  );
  const [orgData, setOrgData] = useState(null);
  const [orgSectionEntityExists, setOrgSectionEntityExists] =
    useState(undefined);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useToast();
  const source = sections[sectionKey].source;

  const loadOrgData = () => {
    setLoading(true);
    get(`orgs/${orgId}/${source}`)
      .then((response) => response.data)
      .then((data) => {
        setOrgData(data);
        setOrgSectionEntityExists(true);
      })
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children:
            error.response?.status === 404
              ? "A seção ainda não foi preenchida"
              : JSON.stringify(error.response?.status),
        });
        if (error.response?.status === 404) setOrgSectionEntityExists(false);
      })
      .finally(() => setLoading(false));
  };

  const submitForAnalysis = (orgId) => {
    return patch(`orgs/${orgId}/${source}`, {}).then(() => loadOrgData());
  };

  const handleSaveNew = (formData) => {
    return post(`orgs/${orgId}/${source}`, formData, {
      headers: {
        "Content-Type": contentTypeBySection[sectionKey],
      },
    }).then((response) => {
      if (response.status < 300) {
        setToast({
          show: true,
          infoLevel: infoLevels.SUCCESS,
          children: "Seção preenchida com sucesso",
        });
        return orgId;
      } else {
        throw new Error(response);
      }
    });
  };

  const handleSaveUpdate = (formData) => {
    return put(`orgs/${orgId}/${source}`, formData, {
      headers: {
        "Content-Type": contentTypeBySection[sectionKey],
      },
    }).then(() => {
      setToast({
        show: true,
        infoLevel: infoLevels.SUCCESS,
        children: "Alteração feita com sucesso",
      });
      return orgId;
    });
  };

  const handleSave = (formData) => {
    setLoading(true);
    return (
      orgData === null ? handleSaveNew(formData) : handleSaveUpdate(formData)
    )
      .then(() => loadOrgData())
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response?.status),
        });
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (formData) => {
    setLoading(true);
    return (
      orgData === null ? handleSaveNew(formData) : handleSaveUpdate(formData)
    )
      .then((orgId) => submitForAnalysis(orgId))
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response?.status),
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (orgId && orgData === null) {
      loadOrgData();
    }
  }, [orgId]);

  useEffect(() => {
    setFilteredForm(
      filterFormFieldsByUserGroup(userData, sections[sectionKey].form)
    );
  }, [orgId, userData]);

  const editable = useMemo(() => {
    return Boolean(
      !orgId ||
        orgSectionEntityExists === false ||
        isUserPhi(userData) ||
        (orgData &&
          isAnyStatus(
            orgData?.form_status,
            formStatus["Desatualizado"],
            formStatus["Editável"],
            formStatus["Incompleto"]
          ))
    );
  }, [orgId, orgSectionEntityExists, userData, orgData]);

  return (
    <div>
      {sectionKey === "management" && isUserInGroups(userData, [1, 2, 3, 7]) && (
        <div className={styles.score}>
          {" "}
          Score de Gestão: {orgData?.management_score}
        </div>
      )}
      {sectionKey === "transparency" && isUserInGroups(userData, [1, 2, 3, 7]) && (
        <div className={styles.score}>
          {" "}
          Score de Transparência: {orgData?.transparency_score}
        </div>
      )}
      {sectionKey === "solidity" && isUserInGroups(userData, [1, 2, 3, 7]) && (
        <div className={styles.score}>
          {" "}
          Score de Solidez: {orgData?.solidity_score}
        </div>
      )}
      {sectionKey === "impact_potential" &&
        isUserInGroups(userData, [1, 2, 3, 7]) && (
          <div className={styles.score}>
            {" "}
            Score de Potencial de impacto: {orgData?.impact_score}
          </div>
        )}
      {orgId && (
        <FormPage
          name="orgSectionForm"
          initialState={orgData}
          onSave={handleSave}
          onSubmit={handleSubmit}
          pages={sections[sectionKey].pages}
          form={filteredForm}
          editable={editable}
          userData={userData}
        />
      )}

      <Toast {...toast} />

      <Toast show={loading} loading={loading} />
    </div>
  );
}
