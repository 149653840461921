import { Redirect } from "react-router-dom";

export default function ConditionsEnforcer({
  conditions,
  fallbackTo,
  children,
}){
  const allowed = conditions ? conditions.every((condition) => condition) : true;
  return allowed ? (
    <>
      {children}
    </>
  ) : fallbackTo ? (
    <Redirect
      to={fallbackTo}
    />
  ) : (
    null
  )
}