import { useMemo } from "react";
import FinanceTable from "./FinanceTable";

export default function PrefilledFinanceTable(props) {
  const {
    prefilledItems,
    value,
    setValue,
    getValueKey,
    getPrefillKey,
    ...rest
  } = props;

  const valueAndPrefill = useMemo(() => {
    if (value !== null && prefilledItems) {
      const valueKeys = value.map(getValueKey);
      return [
        ...value,
        ...prefilledItems.filter(
          (prefillItem) =>
            !valueKeys.find((key) => key === getPrefillKey(prefillItem))
        ),
      ];
    }
  }, [value, prefilledItems]);

  return (
    <FinanceTable
      value={valueAndPrefill}
      setValue={setValue}
      insertable={false}
      minItems={prefilledItems.length}
      {...rest}
    />
  );
}
