import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { get } from '../../../utils/APIutils';
import { getInitialFormValues } from '../../../utils/useForm';
import { FilterForm } from '../../Filtering/FilterForm';
import { PaginationControls } from '../../Pagination';
import { Toast } from '../../Toast';
import { ReactSVG } from "react-svg";

import styles from './styles.module.scss';

const filtersForm = [
  {
    name: "org_name__icontains",
    title: "Nome Fantasia",
    fieldType: "input",
    inputType: "search",
  },
  {
    name: "legal_name__icontains",
    title: "Razão Social",
    fieldType: "input",
    inputType: "search",
  },
  {
    name: "phi_score__gte",
    title: "IQO Maior/Igual que",
    fieldType: "input",
    inputType: "search",
  },
  {
    name: "phi_score__lte",
    title: "IQO Menor/Igual que",
    fieldType: "input",
    inputType: "search",
  }
];

const initialFilters = getInitialFormValues({DATA: filtersForm});

export default function OrgsList({}){
  const [orgs, setOrgs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters)

  const loadOrgs = () => {
    const notBlankFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => !!value)
    );
    setLoading(true);
    get("orgs", {params: {page, ...notBlankFilters}})
    .then((response) => response.data)
    .then((data) => {
      setOrgs(data.items);
      setPageCount(data.pageCount);
    })
    .catch((error) => console.log(error))
    .finally(() => setLoading(false));
  }

  useEffect(() => {
    if(orgs === null){
      loadOrgs();
    }
  }, [])

  useEffect(() => {
    if(orgs !== null){
      loadOrgs();
    }
  }, [page, filters])

  return (
    <>
      <div className={styles.listControls}>
        <PaginationControls
          currentPage={page}
          totalPages={pageCount}
          setPage={setPage}
        />
      
        <FilterForm
          form={filtersForm}
          filters={filters}
          setFilters={(values) => setFilters(values)}
        />
      </div>

      <div className={styles.wrapper}>
        <section className={styles.orgListSection}>
          <Toast show={loading} loading={loading}/>
          <table>
            <thead>
              <tr>
                <th>Nome Fantasia</th>
                <th>Razão Social</th>
                <th>Site</th>
                <th>CNPJ</th>
                <th className={styles.centerCol}><div>Validada</div></th>
                <th>IQO</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orgs?.map((org) =>
                <tr key={org.org_name}>
                  <td>
                    {org.org_name ?? "--"}
                  </td>
                  <td>
                    {org.legal_name ?? "--"}
                  </td>
                  <td>
                    {org.site_url ?? "--"}
                  </td>
                  <td>
                    {org.cnpj ?? "--"}
                  </td>
                  <td className={styles.centerCol}>
                    {org.org_validated ? 
                    <ReactSVG
                      className={styles.validatedIcon}
                      src="/icons/check_circle_24px.svg"
                    />
                    : 
                    <ReactSVG
                      className={styles.validatedIcon}
                      src="/icons/cancel_circle_24px.svg"
                    />}
                  </td>
                  <td>
                    {org.phi_score?.toFixed(2) ?? "--"}
                  </td>
                  <td>
                    <div className={styles.orgOptions}>
                      <Link to={`/orgs/${org.id}`}>
                        <button>
                          Detalhes
                        </button>
                      </Link>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>

      </div>
    </>
  )
}