import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Input } from '../Input';
import styles from './styles.module.scss';

export default function Checkbox({
  name,
  value,
  setValue,
  label,
  options,
  editable,
  alt,
  context
}){
  const [altActive, setAltActive] = useState(!!value[alt?.value]);
  const [altValue, _setAltValue] = useState("");
  const [internalValue, _setInternalValue] = useState(null);

  const internalSetValue = (val) => {
    _setInternalValue(val);
    setValue({...(value ?? {}), [alt?.value]: ""})
    setAltActive(!altActive);
  };
  const internalSetAltValue = (val) => {
    _setAltValue(val);
    if (altActive) setValue({...(value ?? {}), [alt?.value]: val});
  };

  useEffect(() => {
    if (altActive) {
      setValue({...(value ?? {}), [alt?.value]: altValue || value[alt?.value]});
      _setAltValue(value[alt?.value]);
    }
  }, [altActive]);

  useEffect(() => {
    if (!!value[alt?.value]) {
      setAltActive(true);
    }
  }, [value, alt])

  return (
    <div className={`inputContainer ${styles.inputContainer}`}>
      {label &&
        <div className={styles.label}>
          <label>
            {label}
          </label>
        </div>
      }
      <ul>
        {options?.map((option) => {
          const isAltOption = option?.value === alt?.value
          const checked = isAltOption ? altActive : !!(value?.[option.value]);
          return (
            <li key={option.value}>
              <input
                tabIndex={editable ? undefined : -1}
                id={`${name} - ${option.value}`}
                name={name}
                type="checkbox"
                value={altActive ? internalValue : option.value}
                checked={checked ? true : undefined}
                onChange={(evt) => {
                  if (editable !== false) {
                    if (isAltOption){
                      internalSetValue(evt.target.value);
                    }
                    else {
                      setValue({...(value ?? {}), [option.value]: !(value?.[option.value])});
                    }
                  }
                }}
                readOnly={editable === false ? true : undefined}
              />
              <label
                htmlFor={`${name} - ${option.value}`}
                className={editable !== false ? (checked ? styles.selected : "") : styles.inactive}
              >
                <ReactSVG
                  src={checked ? "/icons/check_box_24px.svg" : "/icons/check_box_outline_blank_24px.svg"}
                />
                <span>
                  {option.label}
                </span>
              </label>
            </li>
          )
        })}
      </ul>
      {altActive &&
        <Input
          {...alt?.field}
          value={altValue}
          setValue={internalSetAltValue}
          editable={editable}
        />
      }
    </div>
  )
}