import { useEffect, useMemo, useState } from "react";
import { filterFormFieldsByUserGroup } from "../../../../utils/useForm";
import {
  projectPlanningForm,
  projectPlanningPages,
} from "../../../../constants/projectPlanningForm";
import { FormPage } from "../../../FormPage";
import { Redirect } from "react-router";
import { ReactSVG } from "react-svg";
import { get, patch, post, put } from "../../../../utils/APIutils";

import styles from "./styles.module.scss";
import { Toast, useToast } from "../../../Toast";
import { infoLevels } from "../../../../constants";
import { formStatus, isAnyStatus } from "../../../../constants/options";
import { isUserPhi } from "../../../../utils/permissionUtils";

import { PDFDownloadLink } from "@react-pdf/renderer";
import { PlanningPDF } from "./ProjectPlanningPDF";

export default function ProjectPlanningForm({ projectId, userData }) {
  const [filteredForm, setFilteredForm] = useState(() =>
    filterFormFieldsByUserGroup(userData, projectPlanningForm)
  );
  const [planningData, setPlanningData] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [toast, setToast] = useToast();

  const loadPlanningData = () => {
    if (projectId) {
      setLoading(true);
      get(`projects/${projectId}/planning`)
        .then((response) => {
          if (response.status === 401 || response.status === 403) {
            setRedirect(true);
          }
          return response.data;
        })
        .then((responseData) => setPlanningData(responseData))
        .catch((error) => {
          console.log(error);
          setToast({
            show: true,
            infoLevel: infoLevels.ERROR,
            children: JSON.stringify(error.response.status),
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const submitForAnalysis = () => {
    return patch(`projects/${projectId}/planning`, {});
  };

  const handleSave = (formData) => {
    setLoading(true);
    return put(`projects/${projectId}/planning`, formData)
      .then((response) => {
        setToast({
          show: true,
          infoLevel: infoLevels.SUCCESS,
          children: "Alteração feita com sucesso",
        });
      })
      .then(() => loadPlanningData())
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response.status),
        });
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (formData) => {
    setLoading(true);
    return put(`projects/${projectId}/planning`, formData)
      .then((response) => {
        setToast({
          show: true,
          infoLevel: infoLevels.SUCCESS,
          children: "Alteração feita com sucesso",
        });
      })
      .then(() => submitForAnalysis())
      .then(() => loadPlanningData())
      .catch((error) => {
        console.log(error);
        setToast({
          show: true,
          infoLevel: infoLevels.ERROR,
          children: JSON.stringify(error.response.status),
        });
      })
      .finally(() => setLoading(false));
  };

  const HydratedPlanningPdf = PlanningPDF(planningData);

  useEffect(() => {
    if (reload || (projectId && planningData === null)) {
      loadPlanningData();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    setFilteredForm(filterFormFieldsByUserGroup(userData, projectPlanningForm));
  }, [userData]);

  const editable = useMemo(() => {
    return Boolean(
      projectId &&
        (planningData?.form_status === null ||
          isUserPhi(userData) ||
          isAnyStatus(
            planningData?.form_status,
            formStatus["Desatualizado"],
            formStatus["Editável"],
            formStatus["Incompleto"]
          ))
    );
  }, [projectId, planningData, userData]);

  return (
    <div className={styles.formPageWrapper} id="planningFormDiv">
      {planningData !== null && (
        <button className={styles.downloadButton}>
          <span>
            <PDFDownloadLink
              className={styles.downloadButtonText}
              document={<HydratedPlanningPdf />}
              fileName={`${planningData?.project?.name}_planejamento.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Carregando PDF..." : "Download como PDF"
              }
            </PDFDownloadLink>
          </span>
          <ReactSVG src="/icons/download_24px.svg" />
        </button>
      )}
      {planningData !== null && (
        <FormPage
          name="projectPlanningForm"
          form={filteredForm}
          initialState={planningData}
          pages={projectPlanningPages}
          editable={editable !== false}
          reload={reload}
          setReload={setReload}
          onSubmit={handleSubmit}
          onSave={handleSave}
          userData={userData}
        />
      )}

      {redirect && <Redirect to="/projects/" />}

      <Toast {...toast} />

      <Toast show={loading} loading={loading} />
    </div>
  );
}
