const userAgreement = {
  description: [
    "O Instituto Phi é uma organização sem fins lucrativos que faz a ponte entre doadores e projetos sociais de qualidade. Na prática, os possíveis doadores O Instituto Phi é uma organização sem fins lucrativos que faz a ponte entre doadores e projetos sociais de qualidade. Na prática, os possíveis doadores escolhem que causas gostariam de apoiar e apresentamos projetos dentro do perfil selecionado.",
    "O cadastro no sistema de gerenciamento do Phi incluirá sua organização entre aquelas que podem ser apresentadas a possíveis investidores. É importante que ele seja feito com calma, de maneira clara e objetiva.",
    "Além de conhecer um pouco mais sobre o seu trabalho, esse cadastro representa um sistema que irá avaliar a sua organização com base em quatro pilares: transparência, potencial de impacto, qualidade de gestão e solidez. Ao final do preenchimento, após validação, o sistema irá gerar uma folha de apresentação, que você pode utilizar para buscar investidores de maneira ainda mais profissional e efetiva.",
  ],
  benefits: [
    "Grandes doadores poderão conhecer a sua instituição.",
    "Seu projeto canhará uma folha de apresentação para que você possa buscar investidores de maneira ainda mais profissional e efetiva.",
    "As informações deste cadastro são compartilhadas com o Projeto #Colabora, site que produz e divulga conteúdo jornalístico sobre projetos sociais e hospeda um mapa das ONGs que dá visibilidade às instituições. Preencher o cadastro corretamente e atualizá-lo com frequência é fundamental para que estas informações, quando publicadas, retratem a realidade.",
  ],
  dataUse:
    "Permito que os dados inseridos no sistema sejam usados em materiais de comunicação com investidores e relatórios dos projetos.",
  imageUse:
    "Permito que as imagens inseridas no sistema sejam usadas em materiais de comunicação com investidores e relatórios dos projetos.",
};

export default userAgreement;
