import { useState, useEffect } from "react"
import { get } from "../../../utils/APIutils";
import { Toast } from '../../Toast';

import styles from './styles.module.scss';

function currencyFormat(num) {
    return num.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
}

function formatDate(dateString){
    const [year, month, day] = dateString.split("-").map(item => Number(item));
    return `${day}/${month}/${year}`;
  }

export default function ProjectFinance({projectId}){
    const [loading, setLoading] = useState(false);
    const [financeData, setFinanceData] = useState(null);

    const loadFinanceData = () => {
       setLoading(true);
        get(`projects/${projectId}/finances`)
       .then((response) => response.data)
       .then((data) => setFinanceData(data))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadFinanceData();
      }, [])

    return(
        <div>
            <div className={styles.wrapper}>
                <section className={styles.projectFinanceSection}>
                <Toast show={loading} loading={loading}/>
                    <table>
                        <thead>
                            <tr>
                                <th colSpan="2">Financeiro Geral</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Saldo Total Planejado</th>
                                <td>{financeData && currencyFormat(financeData.total_expected_budget)}</td>
                            </tr>
                            <tr>
                                <th>Saldo Repassado</th>
                                <td>{financeData && currencyFormat(financeData.transfered_budget)}</td>
                            </tr>
                            <tr>
                                <th>Total Gasto</th>
                                <td>{financeData && currencyFormat(financeData.spent_budget)}</td>
                            </tr>
                            <tr>
                                <th>Saldo em conta</th>
                                <td>{financeData && currencyFormat(financeData.balance)}</td>
                            </tr>
                            <tr>
                                <th>Contrapartidas</th>
                                <td>{financeData && currencyFormat(financeData.counterpart)}</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>
            <div className={styles.wrapper}>
                <section className={styles.projectTransferSection}>
                    <table>
                        <thead>
                            <tr>
                                <th colSpan="3">Repasses Pagos</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Data de Solicitação</th>
                                <th>Valor</th>
                                <th>Status do Recibo</th>
                            </tr>
                            {financeData?.transfers?.map((transfer) => (
                                <tr key={transfer.id}>
                                    <td>{formatDate(transfer.solicitation_date)}</td>
                                    <td>{currencyFormat(transfer.value)}</td>
                                    <td>{transfer.receipt_status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </div>
        </div>            
    )
}